import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: false,
    isAuthNurse: false,
    isLoadingNurse: true,
  },
  reducers: {
    login: (state) => {
      state.isAuth = true;
    },
    logout: (state) => {
      state.isAuth = false;
    },
    loginNurse: (state, { payload: isMedic }) => {
      state.isAuthNurse = isMedic;
      state.isLoadingNurse = false;
    },
    logoutNurse: (state) => {
      state.isAuthNurse = false;
    },
  },
});

export const { login, logout, loginNurse, logoutNurse } = authSlice.actions;

export const authSelector = (state: RootState) => state.auth.isAuth;
export const isLoadingNurseSelector = (state: RootState) => state.auth.isLoadingNurse;
export const authNurseSelector = (state: RootState) => state.auth.isAuthNurse;
