import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Result } from "antd";

import { TitleContext } from "@/app/providers/ui/with-title";

export const NotFoundPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("404");
  }, []);

  return (
    <Result
      status="error"
      title="404"
      subTitle="Извините, запрошенная Вами страница не существует"
      extra={
        <Link to="/">
          <Button type="primary">Назад</Button>
        </Link>
      }
    />
  );
};
