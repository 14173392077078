import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { TerminalEntity } from "@medregister/domain";
import { Button } from "antd";

import { useDeleteTerminalMutation } from "@/entities/terminals/api";
import { TerminalRO } from "@/entities/terminals/interfaces";
import { ModalMode, Modals } from "@/shared/types/modals";
import { ButtonsVertical } from "@/shared/ui/css";
import { openModalWithProps } from "@/shared/ui/modals/storage";

interface ITerminalHandlerProps {
  terminalCn: TerminalRO["terminalCn"];
}

export const TerminalHandler: FC<ITerminalHandlerProps> = ({ terminalCn }) => {
  const dispatch = useDispatch();
  const [deleteTerminal] = useDeleteTerminalMutation();

  const handleEdit = (terminalCn: TerminalEntity["terminalCn"]) => {
    dispatch(
      openModalWithProps({
        modalName: Modals.TerminalsDialog,
        props: { modalMode: ModalMode.EDIT, terminalCn },
      })
    );
  };

  return (
    <ButtonsVertical>
      <Button onClick={() => handleEdit(terminalCn)}>Изменить</Button>
      <Button onClick={() => deleteTerminal(terminalCn)} type="primary">
        Удалить
      </Button>
    </ButtonsVertical>
  );
};
