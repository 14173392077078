import React from "react";

import { Errors } from "@/shared/constants/errors";

import { Loader } from "../loader";

import { IViewControlProps } from "./interface";

export const WithViewControl = ({ error, children, isLoading, isEmpty, emptyText }: IViewControlProps) => {
  if (error) {
    if (error?.status === Errors.ServerNotAvailable) {
      throw new Error(JSON.stringify({ status: 500, message: "Server not available" }));
    } else {
      throw new Error(JSON.stringify({ status: error?.status, message: error?.data?.message }));
    }
  }

  if (isLoading) {
    return <Loader position="center" />;
  }
  if (isEmpty && emptyText) {
    return <>{emptyText()}</>;
  }
  return <>{children}</>;
};
