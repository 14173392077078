import React, { FC, useMemo } from "react";
import { useNavigate } from "react-router";
import { Button, Result, Table } from "antd";

import { routePaths } from "@/app/routes/paths";
import { useGetExamsQuery } from "@/entities/exams/api";
import { is } from "@/shared/types/types-guards";
import { WithViewControl } from "@/shared/ui/error-control";

import { journalsColumns } from "./constants";

export const JournalsTable: FC = () => {
  const navigate = useNavigate();
  const { data: examsByNurse, error } = useGetExamsQuery();

  const journals = useMemo(() => examsByNurse?.filter((exam) => !!exam.conclusion), [examsByNurse]);

  return (
    <WithViewControl
      isEmpty={is.empty(journals)}
      error={error}
      emptyText={() => (
        <Result
          title="Журналы не были найдены"
          extra={
            <Button onClick={() => location.reload()} key="console">
              Перезагрузить
            </Button>
          }
        />
      )}
    >
      <Table
        columns={journalsColumns}
        dataSource={journals}
        rowKey="id"
        pagination={{ pageSize: 5 }}
        scroll={{ x: "max-content" }}
        onRow={(record) => {
          return {
            onClick: () => navigate(`${routePaths.exams}/${record.id}`),
          };
        }}
      />
    </WithViewControl>
  );
};
