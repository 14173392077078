import { VenueEntity } from "@medregister/domain";
import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryPlatform } from "@/shared/lib";

import { VenueRO, VenueSearchArgs } from "./interfaces";

export const venuesApi = createApi({
  reducerPath: "venuesApi",
  baseQuery: baseQueryPlatform,
  tagTypes: ["Venue"],
  endpoints: (builder) => ({
    getVenues: builder.query<VenueRO[], void>({
      query: () => `/venues`,
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: "Venue" as const, id }))] : [{ type: "Venue", id: "VENUES" }],
    }),
    getVenue: builder.query<VenueRO, VenueEntity["id"]>({
      query: (id) => `/venues/${id}`,
    }),
    searchVenues: builder.mutation<VenueRO[], VenueSearchArgs>({
      query: (body) => ({
        url: `/venues`,
        method: "POST",
        body,
      }),
    }),
    createVenue: builder.mutation<VenueRO[], VenueRO>({
      query: (body) => ({
        url: `/venues/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Venue"],
    }),
    deleteVenue: builder.mutation<VenueRO[], VenueRO["id"]>({
      query: (id) => ({
        url: `/venues/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Venue"],
    }),
    updateVenue: builder.mutation<VenueRO[], VenueRO>({
      query: ({ id, ...body }) => ({
        url: `/venues/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Venue"],
    }),
  }),
});

export const {
  useGetVenuesQuery,
  useGetVenueQuery,
  useLazyGetVenueQuery,
  useSearchVenuesMutation,
  useCreateVenueMutation,
  useDeleteVenueMutation,
  useUpdateVenueMutation,
} = venuesApi;
