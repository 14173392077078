import { Card, Table } from "antd";
import styled from "styled-components";

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 16px;
`;

export const StatisticItemTitle = styled.h1`
  font-size: 40px;
`;

export const PieItem = styled.div`
  height: 500px;
  width: 500px;

  @media screen and (max-width: 824px) {
    width: 350px;
    height: 350px;
  }

  @media screen and (max-width: 440px) {
    max-width: 100%;
  }
`;

export const StatisticItem = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 171px;
  justify-content: center;
  padding: 0px 40px;
  text-align: center;
  width: 381px;

  &:nth-child(4) {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  &:nth-child(1) {
    margin: 0;
  }

  @media screen and (max-width: 440px) {
    width: 100%;
  }
`;

export const ColumnWrap = styled.div`
  margin: 0 auto;
  max-width: 90vw;
  overflow-x: auto;
`;

export const StatisticWrap = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 824px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 440px) {
    width: 100%;
  }
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1320px) {
    min-width: 210px;

    &:nth-child(1) {
      max-width: 216px;
    }
  }
`;

export const StylizedTable = styled(Table)`
  margin: 30px 0;
`;

export const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 50px;
  padding: 0 100px;

  @media screen and (max-width: 1320px) {
    align-items: left;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
`;

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 100%;
`;
