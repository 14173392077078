import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  SigninRedirectArgs,
  SignoutRedirectArgs,
  SilentRenewErrorCallback,
  User,
  UserLoadedCallback,
  UserManager,
} from "oidc-client-ts";

import { jwtStorage } from "@/shared/lib";

import { AuthContextProps, AuthProviderProps, ExtendedUser, Location } from "./interface";

export const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps);

export const hasCodeInUrl = (location: Location): boolean => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace("#", "?"));
  return (
    searchParams.has("code") ??
    searchParams.has("id_token") ??
    searchParams.has("session_state") ??
    hashParams.has("code") ??
    hashParams.has("id_token") ??
    hashParams.has("session_state")
  );
};

export const initUserManager = (props: AuthProviderProps): UserManager => {
  if (props.userManager) {
    return props.userManager;
  }
  const {
    authority,
    clientId,
    clientSecret,
    redirectUri,
    silentRedirectUri,
    postLogoutRedirectUri,
    responseType,
    scope,
    automaticSilentRenew,
    loadUserInfo,
    popupWindowFeatures,
    popupRedirectUri,
    popupWindowTarget,
    extraQueryParams,
    metadata,
  } = props;
  return new UserManager({
    authority: authority ?? "",
    client_id: clientId ?? "",
    client_secret: clientSecret,
    redirect_uri: redirectUri ?? "",
    silent_redirect_uri: silentRedirectUri ?? redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri ?? redirectUri,
    response_type: responseType ?? "code",
    scope: scope ?? "openid",
    loadUserInfo: loadUserInfo ?? true,
    popupWindowFeatures: popupWindowFeatures,
    popup_redirect_uri: popupRedirectUri,
    popupWindowTarget: popupWindowTarget,
    automaticSilentRenew,
    extraQueryParams,
    metadata: metadata,
  });
};

export const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({
  children,
  autoSignIn = true,
  autoSignInArgs,
  autoSignOut = true,
  autoSignOutArgs,
  onBeforeSignIn,
  onSignIn,
  onSignOut,
  location = window.location,
  onSignInError,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<any>(null);
  const [userManager] = useState<UserManager>(() => initUserManager(props));
  const isMountedRef = useRef<boolean>(false);
  const navigate = useNavigate();
  const signOutHooks = useCallback(async (): Promise<void> => {
    setUserData(null);
    if (onSignOut) {
      await onSignOut();
    }
  }, [onSignOut]);

  const signInPopupHooks = useCallback(async (): Promise<void> => {
    const userFromPopup: User = await userManager.signinPopup();
    const extendedUser = userFromPopup as ExtendedUser;
    setUserData(extendedUser);
    if (onSignIn) {
      await onSignIn(extendedUser);
    }
    await userManager.signinPopupCallback();
  }, [userManager, onSignIn]);

  useEffect(() => {
    let isMounted = true;
    isMountedRef.current = true;
    setIsLoading(true);
    void (async () => {
      if (!userManager) {
        return;
      }
      const user = await userManager.getUser();
      const extendedUser = user as ExtendedUser;
      if (isMounted && (!user || user.expired)) {
        if (hasCodeInUrl(location)) {
          try {
            const user = await userManager.signinCallback();
            if (user) {
              setUserData(user);
              if (onSignIn) {
                await onSignIn(extendedUser);
              }
            }
          } catch (error) {
            if (onSignInError) {
              onSignInError(error as Error);
            } else {
              throw error;
            }
          }
        } else if (autoSignIn) {
          const state = onBeforeSignIn ? onBeforeSignIn() : undefined;
          await userManager.signinRedirect({ ...autoSignInArgs, state });
        }
      } else if (isMountedRef.current) {
        setUserData(extendedUser);
      }
      setIsLoading(false);
    })();
    return () => {
      isMounted = false;
      isMountedRef.current = false;
    };
  }, [location, userManager, autoSignIn, onBeforeSignIn, onSignIn, onSignInError]);

  useEffect(() => {
    const updateUserData: UserLoadedCallback = (user: User): void => {
      if (isMountedRef.current) {
        const extendedUser = user as ExtendedUser;
        setUserData(extendedUser);
      }
    };
    const onSilentRenewError: SilentRenewErrorCallback = async (): Promise<void> => {
      if (autoSignOut) {
        await signOutHooks();
        await userManager.signoutRedirect(autoSignOutArgs);
      }
    };
    userManager.events.addUserLoaded(updateUserData);
    userManager.events.addSilentRenewError(onSilentRenewError);
    return () => {
      userManager.events.removeUserLoaded(updateUserData);
      userManager.events.removeSilentRenewError(onSilentRenewError);
    };
  }, [userManager]);

  const value = useMemo<AuthContextProps>(() => {
    return {
      signIn: async (args?: SigninRedirectArgs): Promise<void> => {
        await userManager.signinRedirect(args);
      },
      signInPopup: async (): Promise<void> => {
        await signInPopupHooks();
      },
      signOut: async (): Promise<void> => {
        await userManager.removeUser();
        await signOutHooks();
      },
      signOutRedirect: async (args?: SignoutRedirectArgs): Promise<void> => {
        await userManager.signoutRedirect(args);
        await signOutHooks();
      },
      userManager,
      userData,
      isLoading,
    };
  }, [userManager, isLoading, userData, signInPopupHooks, signOutHooks]);

  useEffect(() => {
    if (!jwtStorage.getJwt() && userData?.access_token) {
      jwtStorage.updateJwt(userData?.access_token);
      localStorage.setItem("refresh_token", JSON.stringify(userData?.refresh_token));
      navigate("/");
    }
  }, [userData]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
