import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { CompanyEntity, CONCLUSION, EXAM_TYPE, STATUS, SubdivisionEntity, VenueEntity } from "@medregister/domain";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import { TitleContext } from "@/app/providers/ui/with-title";
import { useGetCompaniesQuery } from "@/entities/companies/api";
import { useGetExamsQuery } from "@/entities/exams/api";
import { useGetSubdivisionsQuery } from "@/entities/subdivisions/api";
import { useGetVenuesQuery } from "@/entities/venues/api";
import { is } from "@/shared/types/types-guards";
import { Loader, Message } from "@/shared/ui";
import { WithViewControl } from "@/shared/ui/error-control";

import { CustomPie } from "../../shared/ui/pie";

import { defaultDatesRange, EXAM_TYPE_RU, rangePresets } from "./constants";
import { IDatesRange, OptionType } from "./interfaces";
import { InputWrap, PieItem, Root, StatisticItem, StatisticItemTitle, StatisticWrap, Title, Wrapper } from "./styles";

dayjs.extend(isBetween);

const examTypeOptions: OptionType[] = Object.entries(EXAM_TYPE_RU).reduce((acc: OptionType[], item) => {
  acc.push({ value: item[0], label: item[1] });
  return acc;
}, []);

export const DashboardWidgets: FC = () => {
  const { setTitle } = useContext(TitleContext);
  const { data: companies } = useGetCompaniesQuery();
  const { data: subdivisions } = useGetSubdivisionsQuery();
  const { data: venues } = useGetVenuesQuery();
  const { data: examsByNurse, isLoading, error } = useGetExamsQuery();

  const [datesRange, setDatesRange] = useState<IDatesRange>(defaultDatesRange);
  const [examType, setExamType] = useState<EXAM_TYPE | null>(null);
  const [company, setCompany] = useState<CompanyEntity["id"][]>([]);
  const [subdivision, setSubdivision] = useState<SubdivisionEntity["id"][]>([]);
  const [venue, setVenue] = useState<VenueEntity["id"][]>([]);

  const companiesOptions = useMemo(
    () =>
      companies?.map((c) => ({
        value: c.id,
        label: `${c.prefix} ${c.legalName}`,
      })),
    [companies]
  );
  const subdivisionsOptions = useMemo(
    () => subdivisions?.map((c) => ({ value: c.id, label: c.title })),
    [subdivisions]
  );
  const venuesOptions = useMemo(() => venues?.map((c) => ({ value: c.id, label: c.title })), [venues]);

  const examsByNurseSigned = examsByNurse
    ?.filter(
      (exam) =>
        (exam.status === STATUS.SIGNED &&
          datesRange.isDefined &&
          dayjs(exam.createdAt).isBetween(datesRange.from, datesRange.to)) ||
        (exam.status === STATUS.SIGNED && !datesRange.isDefined)
    )
    .filter((exam) => !examType?.includes(exam.examType.examTypeCode))
    .filter((exam) =>
      company.length ? company.includes(exam.nurse.venueStaff.roleMember.subdivisions[0]?.company.id) : true
    )
    .filter((exam) =>
      subdivision.length ? subdivision.includes(exam.nurse.venueStaff.roleMember.subdivisions[0]?.id) : true
    )
    .filter((exam) => (venue.length ? venue.includes(exam.nurse.venueStaff.venueId) : true));
  const examsPassed = examsByNurseSigned?.filter((exam) => exam.conclusion === CONCLUSION.PASSED);
  const examsFailed = examsByNurseSigned?.filter((exam) => exam.conclusion === CONCLUSION.FAILED);

  const onRangeChange = (dates) => {
    if (dates) {
      setDatesRange({
        from: dates[0],
        to: dates[1],
        isDefined: true,
      });
    } else {
      setDatesRange(defaultDatesRange);
    }
  };

  useEffect(() => {
    setTitle("Сводка");
  }, []);

  return (
    <WithViewControl
      isLoading={isLoading}
      error={error}
      isEmpty={is.empty(examsByNurse)}
      emptyText={() => <Message text="Осмотры не найдены" />}
    >
      <Root>
        <Wrapper>
          <InputWrap>
            <Title>За период</Title>
            <DatePicker.RangePicker
              presets={rangePresets}
              showTime
              format="YYYY/MM/DD HH:mm:ss"
              onChange={onRangeChange}
            />
          </InputWrap>
          <InputWrap>
            <Title>Компания</Title>
            <Select
              placeholder="Выберите компанию..."
              options={companiesOptions}
              mode="multiple"
              onChange={(value) => (value.length ? setCompany(value) : setCompany([]))}
            />
          </InputWrap>
          <InputWrap>
            <Title>Подразделение</Title>
            <Select
              placeholder="Выберите подразделение..."
              options={subdivisionsOptions}
              mode="multiple"
              onChange={(value) => (value.length ? setSubdivision(value) : setSubdivision([]))}
            />
          </InputWrap>
          <InputWrap>
            <Title>Точка осмотра</Title>
            <Select
              placeholder="Выберите точку осмотра..."
              options={venuesOptions}
              mode="multiple"
              onChange={(value) => (value.length ? setVenue(value) : setVenue([]))}
            />
          </InputWrap>
          <InputWrap>
            <Title>Тип осмотра</Title>
            <Select
              placeholder="Выберите тип осмотра..."
              options={examTypeOptions}
              mode="multiple"
              onChange={(value) => (value.length ? setExamType(value) : setExamType(null))}
            />
          </InputWrap>
        </Wrapper>
        <StatisticWrap>
          <StatisticItem>
            <StatisticItemTitle>{!isLoading ? examsByNurseSigned?.length : <Loader />}</StatisticItemTitle>
            <p>Всего проведено</p>
          </StatisticItem>
          <StatisticItem>
            <StatisticItemTitle>{!isLoading ? examsPassed?.length : <Loader />}</StatisticItemTitle>
            <p>Прошло успешно</p>
          </StatisticItem>
          <StatisticItem border="1px solid #000" padding="15px 30px" width="210px">
            <StatisticItemTitle>{!isLoading ? examsFailed?.length : <Loader />}</StatisticItemTitle>
            <p>Отклонено</p>
          </StatisticItem>
        </StatisticWrap>
        <PieItem>
          <CustomPie examsPassedLength={examsPassed?.length} examsFailedLength={examsFailed?.length} />
        </PieItem>
      </Root>
    </WithViewControl>
  );
};
