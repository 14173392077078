import React, { FC, useContext, useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";

import { TitleContext } from "@/app/providers/ui/with-title";
import { ErrorBoundaryFallback } from "@/shared/ui/error-boundary";
import { TerminalsTable } from "@/widgets/terminals/table";

export const Terminals: FC = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Терминалы");
  }, []);

  return <TerminalsTable />;
};

export const TerminalsPage = withErrorBoundary(Terminals, {
  FallbackComponent: ErrorBoundaryFallback,
});
