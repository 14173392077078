import dayjs from "dayjs";

import { DateFormats } from "@/shared/types/globals";

import { Title5 } from "../types/title5-type";
import { Title6 } from "../types/title6-type";

interface gT6Dto {
  title5: Title5;
  ИдФайл: string;
  signature: string;
  ourMedData: any;
  firstName: string;
  secondName: string;
  lastName: string;
}

export const generateTitle6 = (data: gT6Dto): Title6 => {
  const { title5, ИдФайл, signature, ourMedData, firstName, lastName, secondName } = data;

  const title6: Title6 = {
    _declaration: { _attributes: { version: "1.0", encoding: "windows-1251" } },
    Файл: {
      _attributes: {
        ИдФайл: ИдФайл,
        ВерсПрог: "Medregister 1.0",
        ВерсФорм: "5.01",
      },
      Документ: {
        _attributes: {
          ВрИнфМО: dayjs().locale("ru").format(DateFormats.Time),
          ДатИнфМО: dayjs().locale("ru").format(DateFormats.UserFriendly),
          КНД: "1110385",
        },
        ИдИнфЗаезд: {
          _attributes: {
            ИдФайлИнфЗаезд: title5.Файл._attributes.ИдФайл,
            ДатФайлИнфЗаезд: title5.Файл.Документ._attributes.ДатИнфЗаезд,
            ВрФайлИнфЗаезд: title5.Файл.Документ._attributes.ВрИнфЗаезд,
            ЭП: signature,
          },
        },
        СодИнфМО: {
          _attributes: {
            ВидМО: "2",
            УИД_ПЛ: title5.Файл.Документ.СодИнфЗаезд._attributes.УИД_ПЛ,
          },
          СвМедРаб: {
            ФИО: {
              _attributes: {
                Фамилия: ourMedData.Фамилия,
                Имя: ourMedData.Имя,
                Отчество: ourMedData.Отчество,
              },
            },
            ЛицензМО: {
              _attributes: {
                ДатВыд: ourMedData.ДатВыд,
                Ном: ourMedData.Ном,
                Сер: ourMedData.Сер,
                Срок: ourMedData.Срок,
              },
            },
          },
          СвМОПосл: {
            _attributes: {
              ДатВрПрМО: ourMedData.ДатВрПрМО,
              НалКоорТочВрПрМО: "1",
              ОтметМОПосл: "Прошел послерейсовый медицинский осмотр",
            },
            СвВодит: {
              ФИО: {
                _attributes: {
                  Фамилия: lastName,
                  Имя: firstName,
                  Отчество: secondName,
                },
              },
            },
          },
        },
        ПодпИнфМО: {
          _attributes: {
            СпосПодтПолном: "3",
            ТипПодпис: "1",
          },
          ФИО: {
            _attributes: {
              Фамилия: ourMedData.Фамилия,
              Имя: ourMedData.Имя,
              Отчество: ourMedData.Отчество,
            },
          },
          СвДоверЭл: {
            НомДовер: "441d9a57-7fed-4882-b6f2-104b9197ad4f",
            ДатаДовер: "2024-09-26",
            ИдСистХран: "https://m4d.nalog.gov.ru/emchd/check-status?guid=441d9a57-7fed-4882-b6f2-104b9197ad4f",
          },
        },
      },
    },
  };

  return title6;
};
