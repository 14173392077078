import { Key } from "react";

import { IAnyObject } from "./globals";

class IsEmpty {
  isEmpty = (value?: unknown): value is Array<never> | undefined | null | "" => {
    if (typeof value !== "number" && !value) return true;

    if (typeof value === "object") {
      return Array.isArray(value)
        ? this.isEmptyArray(value)
        : // @ts-ignore
          this.isEmptyObject(value as unknown as Record<Key, unknown>);
    }

    return false;
  };

  private isEmptyArray = (value: unknown[]): value is Array<never> => value.length === 0;

  // @ts-ignore
  private isEmptyObject = (value: Record<Key, unknown>): value is Record<string, never> =>
    Object.keys(value).length === 0;
}

export const is = {
  array: (value: unknown): value is Array<unknown> => Array.isArray(value),
  boolean: (value: unknown): value is boolean => typeof value === "boolean",
  empty: new IsEmpty().isEmpty,
  equal: (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2),
  false: (value: unknown): value is false => value === false,
  falsy: (value: unknown): value is false | "" | undefined | null | 0 => Boolean(value) === false,
  nan: (value: unknown): value is number => isNaN(value as number),
  nil: (value: unknown): value is null | undefined => is.undefined(value) || is.null(value),
  null: (value: unknown): value is null => value === null,
  number: (value: unknown): value is number => typeof value === "number",
  object: (value: unknown): value is IAnyObject => typeof value === "object",
  primitive: (value: unknown): value is number | string => is.string(value) || is.number(value),
  string: (value: unknown): value is string => typeof value === "string",
  true: (value: unknown): value is true => value === true,
  truthy: (value: unknown): value is true => Boolean(value),
  undefined: (value: unknown): value is undefined => value === undefined,
};
