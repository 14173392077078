export const routePaths = {
  main: "/",
  dashboard: "/dashboard",
  exams: "/exams",
  monitoringEpd: "/monitoring-epd",
  examsId: "/exams/:id",
  companies: "/companies",
  patients: "/patients",
  files: "/files",
  journals: "/journals",
  terminals: "/terminals",
  subdivisions: "/subdivisions",
  users: "/users",
  createUser: "/create-user",
  createSubdivision: "/create-subdivision",
  editSubdivision: "/edit-subdivision/:id",
  groups: "/groups",
  astral1: "/astral1",
  notfound: "/notfound",
  forbidden: "/forbidden",
  shift: "shift",
  all: "*",
};
