import { Select } from "antd";
import styled from "styled-components";

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Left = styled.div`
  div {
    margin-right: 10px;
  }
`;

export const Right = styled.div`
  button {
    margin-left: 10px;
  }
`;

export const StyledSelect = styled(Select)`
  min-width: 200px;
`;
