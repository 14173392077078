import React, { FC, useContext, useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import styled from "styled-components";

import { TitleContext } from "@/app/providers/ui/with-title";
import { useSearchPatientsMutation } from "@/entities/patients/api";
import { Modals } from "@/shared/types/modals";
import { CreateButton, ErrorBoundaryFallback, Search } from "@/shared/ui";
import { openModalWithProps } from "@/shared/ui/modals/storage";
import { PatientsTable } from "@/widgets/patients/server-table";

export const Patients: FC = () => {
  const dispatch = useDispatch();
  const [searchPatients, { data: filteredPatients, isLoading }] = useSearchPatientsMutation();
  const [filteredOffset, setFilteredOffset] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const { setTitle } = useContext(TitleContext);

  useEffect(
    function getFilteredPatients() {
      if (!!searchValue) {
        searchPatients({ name: searchValue, offset: filteredOffset });
      }
    },
    [filteredOffset]
  );

  useEffect(() => {
    setTitle("Пациенты");
  }, []);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!!e.target.value) {
      searchPatients({ name: e.target.value, offset: filteredOffset });
    }
  };

  return (
    <>
      <Search text="Введите имя пациента" onSearch={handleSearch} />
      <Block>
        <CreateButton modal={Modals.PatientsDialog} text={"пациента"} />
        <Button onClick={() => dispatch(openModalWithProps({ modalName: Modals.PatientsUploadDialog }))}>
          Загрузить пациетов
        </Button>
      </Block>
      <PatientsTable
        filteredPatients={!!searchValue ? filteredPatients?.data : undefined}
        filteredPatientsCount={filteredPatients?.count}
        setFilteredOffset={setFilteredOffset}
        isFetchingFiltered={isLoading}
      />
    </>
  );
};

export const PatientsPage = withErrorBoundary(Patients, {
  FallbackComponent: ErrorBoundaryFallback,
});

export const Block = styled.div`
  margin-bottom: 20px;
  button {
    margin-right: 20px;
  }
`;
