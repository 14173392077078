import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router";
import { Button } from "antd";

import { TitleContext } from "@/app/providers/ui/with-title";
import { useSearchSubdivisionsMutation } from "@/entities/subdivisions/api";
import { SubdivisionFiltersUI } from "@/features/subdivisions/filters";
import { ErrorBoundaryFallback, Search, WithViewControl } from "@/shared/ui";
import { PanelWrapper } from "@/shared/ui/css/global";
import { SubdivisionsTable } from "@/widgets/subdivisions/table";

const Subdivisions: FC = () => {
  const [searchSubdivisions, { data: filteredSubdivisions, error }] = useSearchSubdivisionsMutation();
  const [filters, setFilters] = useState({ venueId: "", companyId: "" });
  const { setTitle } = useContext(TitleContext);

  const navigate = useNavigate();

  const handleSearch = useCallback(
    (e) => {
      searchSubdivisions({ title: e.target.value, ...filters });
    },
    [filters]
  );

  useEffect(() => {
    setTitle("Подразделения");
  }, []);

  return (
    <WithViewControl error={error}>
      <Search text="Введите название подразделения" onSearch={handleSearch} />
      <PanelWrapper>
        <Button onClick={() => navigate("/create-subdivision")}>Создать подразделение</Button>
        <SubdivisionFiltersUI updateFilters={setFilters} filters={filters} />
      </PanelWrapper>
      <SubdivisionsTable filteredSubdivisions={filteredSubdivisions} />
    </WithViewControl>
  );
};

export const SubdivisionsPage = withErrorBoundary(Subdivisions, {
  FallbackComponent: ErrorBoundaryFallback,
});
