import React, { createContext, ReactNode, useEffect, useState } from "react";

type TitleContextProps = {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};

const TitleContext = createContext<TitleContextProps>({
  title: "Медрегистр",
  setTitle: () => {},
});

const TitleProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState<string>("Медрегистр");

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  return <TitleContext.Provider value={{ title, setTitle }}>{children}</TitleContext.Provider>;
};

const withTitle = (Component: React.ComponentType) => {
  return () => (
    <TitleProvider>
      <Component />
    </TitleProvider>
  );
};

export { TitleContext, withTitle };
