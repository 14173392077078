import React, { FC, useEffect, useState } from "react";
import { Table } from "antd";

import { useLazyGetTerminalsQuery } from "@/entities/terminals/api";
import { TerminalFilters } from "@/features/terminals/filters";
import { Modals } from "@/shared/types/modals";
import { is } from "@/shared/types/types-guards";
import { CreateButton, Message, Search } from "@/shared/ui";
import { PanelWrapper } from "@/shared/ui/css";
import { WithViewControl } from "@/shared/ui/error-control";

import { terminalColumns } from "./constants";

export const TerminalsTable: FC = () => {
  const [searchTerminals, { data: terminals, error: searchTerminalError, isLoading }] = useLazyGetTerminalsQuery();
  const [filters, setFilters] = useState({});

  const handleSearch = (e) => {
    searchTerminals({ terminalCn: e.target.value, ...filters });
  };

  useEffect(() => {
    searchTerminals({ ...filters });
  }, [filters]);

  return (
    <>
      <Search text="Введите название терминала..." onSearch={(e) => handleSearch(e)} />
      <PanelWrapper>
        <CreateButton modal={Modals.TerminalsDialog} text={"терминал"} />
        <TerminalFilters updateFilters={setFilters} />
      </PanelWrapper>
      <WithViewControl
        isLoading={isLoading}
        isEmpty={is.empty(terminals)}
        error={searchTerminalError}
        emptyText={() => <Message text="Терминалы не найдены" />}
      >
        <Table columns={terminalColumns} dataSource={terminals} rowKey="terminalCn" scroll={{ x: "max-content" }} />
      </WithViewControl>
    </>
  );
};
