import { CompanyEntity } from "@medregister/domain";
import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryPlatform } from "@/shared/lib";

interface IGetCompaniesParams {
  limit?: number;
  offset?: number;
  value?: string;
  nested?: boolean;
}

export const companiesApi = createApi({
  reducerPath: "companiesApi",
  baseQuery: baseQueryPlatform,
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getCompanies: builder.query<CompanyEntity[], void>({
      query: () => "/companies",
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: "Company" as const, id }))]
          : [{ type: "Company", id: "COMPANIES" }],
    }),
    getCompany: builder.query<CompanyEntity, string>({
      query: (id) => `/companies/${id}`,
    }),
    searchCompanies: builder.mutation<CompanyEntity[], IGetCompaniesParams | void>({
      query: (params: IGetCompaniesParams) => ({
        url: "/companies",
        method: "GET",
        params,
      }),
    }),
    createCompany: builder.mutation<CompanyEntity[], CompanyEntity>({
      query: (body) => ({
        url: "/companies/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Company"],
    }),
    deleteCompany: builder.mutation<CompanyEntity[], CompanyEntity["id"]>({
      query: (id) => ({
        url: `/companies/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Company"],
    }),
    updateCompany: builder.mutation<CompanyEntity[], CompanyEntity>({
      query: ({ id, ...body }) => ({
        url: `/companies/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Company"],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useSearchCompaniesMutation,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
} = companiesApi;
