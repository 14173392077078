import React, { FC } from "react";

import { CompanySelector } from "@/entities/companies/ui/multiple-selector";
import { VenuesSelector } from "@/entities/venues/ui/selector";

import { Wrapper } from "./styles";

interface ISubdivisionsFiltersIUProps {
  filters: any;
  updateFilters: (val: any) => void;
}

export const SubdivisionFiltersUI: FC<ISubdivisionsFiltersIUProps> = ({ updateFilters }) => {
  const handleCompanyId = (companyId: string) => {
    updateFilters((prev) => ({
      ...prev,
      companyId,
    }));
  };

  const handleVenueId = (venueId: string) => {
    updateFilters((prev) => ({
      ...prev,
      venueId,
    }));
  };

  return (
    <Wrapper>
      <CompanySelector update={handleCompanyId} />
      <VenuesSelector update={handleVenueId} />
    </Wrapper>
  );
};
