export const getFullNameExam = (exam) => {
  return `${exam.Worker.firstName.trim().toLowerCase()}${exam.Worker.lastName.trim().toLowerCase()}${
    exam.Worker.patronymic ? exam.Worker.patronymic.trim().toLowerCase() : ""
  }`;
};

export const getFullNameWorker = (person) => {
  return `${person.firstName.trim().toLowerCase()}${person.lastName.trim().toLowerCase()}${
    person.secondName ? person.secondName.trim().toLowerCase() : ""
  }`;
};
