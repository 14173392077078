import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";

import { ModalMode } from "@/shared/types/modals";
import { Modals } from "@/shared/types/modals";

import { openModalWithProps } from "../modals/storage";

export interface ICreateButtonProps {
  modal: Modals;
  text: string;
}

export const CreateButton: FC<ICreateButtonProps> = ({ modal, text }) => {
  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(
      openModalWithProps({
        modalName: modal,
        props: { modalMode: ModalMode.CREATE },
      })
    );
  };

  return <Button onClick={handleCreate}>Создать {text}</Button>;
};
