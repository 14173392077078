import React, { useEffect, useState } from "react";
import { Empty } from "antd";

import { TreeArrow } from "..";

import { calculateChildrenLength, findElementById } from "./helpers";
import { ITree, MenuItem } from "./interface";
import { Body, Item, List, Spin, Title } from "./styles";

export const Tree: React.FC<ITree> = ({ items, fetchChildren, selectedItem, setSelectedItem }) => {
  const [expandedItems, setExpandedItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (selectedItem) {
      setExpandedItems((prev) => [...prev, selectedItem]);
    }
  }, [items]);

  const toggleItem = (toggledItem: MenuItem) => {
    setSelectedItem((prev) => {
      const isOpened = !!expandedItems.find((item) => item.key === toggledItem.key);
      if (isOpened) {
        return null;
      }
      if (prev && prev.key === toggledItem.key) {
        return null;
      } else {
        return toggledItem;
      }
    });
    if (expandedItems.some((item) => item.key === toggledItem.key)) {
      let indexOfClickedItem;
      expandedItems.forEach((item, index) => {
        if (item.key === toggledItem.key) {
          indexOfClickedItem = index;
        }
      });
      setExpandedItems(
        expandedItems.filter((item, index) => {
          return (
            item.key !== toggledItem.key &&
            ((toggledItem.lvl !== item.lvl &&
              (index < indexOfClickedItem || !findElementById(toggledItem.children, item.key))) ||
              toggledItem.lvl === item.lvl)
          );
        })
      );
    } else {
      if (toggledItem.children) {
        setExpandedItems([...expandedItems, toggledItem]);
      } else {
        if (toggledItem.haveSubGroups) {
          fetchChildren({ parentId: toggledItem.key }).then((data) => {
            if (!data) {
              setExpandedItems(expandedItems.filter((item) => item.key !== toggledItem.key));
            }
          });
          setExpandedItems([...expandedItems, toggledItem]);
        }
      }
    }
  };

  const renderTree = (item) => {
    return (
      <Item key={item.key}>
        <Title
          selected={selectedItem?.key === item.key}
          onClick={() => toggleItem(item)}
          active={expandedItems.some((elem) => elem.key === item.key) && item.children && item.children.length}
        >
          {item.label}
          {(item.children || item.haveSubGroups) && (
            <TreeArrow isActive={expandedItems.some((elem) => elem.key === item.key)} />
          )}
        </Title>
        {item.children ? (
          <List
            active={expandedItems.some((elem) => elem.key === item.key)}
            style={{
              maxHeight: expandedItems.some((elem) => elem.key === item.key)
                ? `${calculateChildrenLength(item) * 60}px`
                : "0",
            }}
          >
            {item.children.map((child) => renderTree(child))}
          </List>
        ) : item.haveSubGroups ? (
          <List
            active={expandedItems.some((elem) => elem.key === item.key)}
            style={{
              maxHeight: expandedItems.some((elem) => elem.key === item.key)
                ? `${item.children ? calculateChildrenLength(item) * 60 : 100}px`
                : "0",
            }}
          >
            <Spin />
          </List>
        ) : null}
      </Item>
    );
  };

  return (
    <Body>
      {items.length ? (
        items.map((item) => renderTree(item))
      ) : (
        <Empty description={<span>Не найдено групп</span>}></Empty>
      )}
    </Body>
  );
};
