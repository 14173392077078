import { LogoutOutlined, SettingOutlined, UserOutlined, WarningFilled } from "@ant-design/icons";
import { Layout, Menu, Skeleton } from "antd";
import styled from "styled-components";

import { Colors } from "@/shared/constants/colors";

const { Header: AntdHeader } = Layout;

export const StylizedHeader = styled(AntdHeader)`
  p {
    color: #fff;
    margin: 0 20px;
  }
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  button {
    margin-right: 10px;

    &:last-child {
      margin: 0;
    }
  }
`;

export const HeaderItems = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-left: 60px;
`;

export const StylizedMenu = styled(Menu)`
  border-bottom: 0;
  width: 70%;

  @media screen and (max-width: 340px) {
    width: auto;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  background: #2b2b2b;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 20px;
  position: fixed;
  width: 100%;
  z-index: 1;
`;

export const StyledSettingOutlined = styled(SettingOutlined)`
  font-size: 20px;
`;

export const StyledLogoutOutlined = styled(LogoutOutlined)`
  color: #fff;
  cursor: pointer;
  margin-left: 30px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const StyledUserOutlined = styled(UserOutlined)`
  color: #fff;
  margin-left: 30px;
  margin-right: 10px;

  @media screen and (max-width: 638px) {
    display: none;
  }

  svg {
    height: 23px;
    width: 23px;
  }
`;

export const StyledName = styled.p`
  color: #fff;

  @media screen and (max-width: 638px) {
    display: none;
  }
`;

export const SwitchWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 20px;

  @media screen and (max-width: 480px) {
    margin-right: 20px;
  }
`;

export const SwitchTitle = styled.p`
  color: #fff;
  margin-right: 15px;

  @media screen and (max-width: 480px) {
    margin-right: 8px;
  }
`;

export const StylizedSkeleton = styled(Skeleton)`
  display: flex;
  max-height: 50px;
  overflow: hidden;
  padding: 20px;
  padding: 16px;
  width: 89%;
`;

export const WarningWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  margin: 0 8px;
`;

export const StylizedWarningFilled = styled(WarningFilled)`
  path {
    fill: ${Colors.secondaryDarken};
  }
  font-size: 20px;
`;

export const Logo = styled.img`
  margin-left: 20px;
  margin-right: 20px;
  max-width: 328px;

  @media screen and (max-width: 842px) {
    display: none;
  }
`;
