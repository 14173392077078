import { useContext } from "react";

import { AuthContext } from "@/app/providers/with-auth/auth-context";

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw Error("AuthProvider is not used");
  }
  return context;
};
