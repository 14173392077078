import { CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import styled from "styled-components";

import { ButtonSpace, ModalUI } from "@/shared/styles/common";

export const Wrapper = styled.div`
  ${ModalUI}

  flex-direction: column;
  position: relative;
  text-align: center;
  width: 600px;

  ${ButtonSpace}
`;
export const StylizedForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const FormButton = styled(Form.Item)`
  display: flex;
  justify-content: end;
  margin-bottom: 50px;
  margin-top: auto;
`;

export const Header = styled.div`
  text-align: left;
`;

export const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const Line = styled.div`
  align-items: center;
  display: flex;
  gap: 15px;
`;
