import React, { FC, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, message } from "antd";

import { TitleContext } from "@/app/providers/ui/with-title";
import { CertificateSelector } from "@/shared/ui/certificate/ui/selector";
import { certStorage } from "@/shared/ui/certificate/ui/selector/utils";
import { setShift } from "@/shared/ui/settings/model";

import { Buttons, Title, Wrapper } from "./styles";

export const ShiftPage: FC = () => {
  const dispatch = useDispatch();
  const { setTitle } = useContext(TitleContext);

  const handleOpenShift = () => {
    const isSelectedSert = certStorage.getCert() !== null;
    if (isSelectedSert) {
      dispatch(setShift(true));
      window.history.back();
    } else {
      message.error("Не выбран сертификат");
    }
  };

  useEffect(() => {
    setTitle("Открытие смены медицинского работника");
  }, []);

  return (
    <Wrapper>
      <Title>
        Для проведения медицинских осмотров вам необходимо выбрать личную электронную подпись, которой будут подписаны
        акты проведённых осмотров
      </Title>
      <CertificateSelector />
      <Buttons>
        <Button onClick={() => window.history.back()} danger type="primary">
          Отмена
        </Button>
        <Button onClick={handleOpenShift}>Открыть</Button>
      </Buttons>
    </Wrapper>
  );
};
