import { createGlobalStyle } from "styled-components";

import { Colors } from "@/shared/constants/colors";

export const GlobalStyle = createGlobalStyle`
  body {
    --secondaryDarken: ${Colors.secondaryDarken};
    --bgBodyDarken: ${Colors.bgBodyDarken};
    --bgContainerDarken: ${Colors.bgContainerDarken};
    --borderDarken: ${Colors.borderDarken};
    --placeholderDarken: ${Colors.placeholderDarken};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',
    sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  }
`;
