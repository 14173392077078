import React, { FC } from "react";
import { CompanyEntity, VenueEntity } from "@medregister/domain";

import { CompanySelector } from "@/entities/companies/ui/multiple-selector";
import { VenuesSelector } from "@/entities/venues/ui/selector";

import { Wrapper } from "./styles";

interface ITerminalsFiltersProps {
  updateFilters: (id: any) => void;
}

export const TerminalFilters: FC<ITerminalsFiltersProps> = ({ updateFilters }) => {
  const handleCompanyId = (companyId: CompanyEntity["id"]) => {
    updateFilters((prev) => ({
      ...prev,
      companyId,
    }));
  };

  const handleVenueId = (venueId: VenueEntity["id"]) => {
    updateFilters((prev) => ({
      ...prev,
      venueId,
    }));
  };

  return (
    <Wrapper>
      <CompanySelector update={handleCompanyId} />
      <VenuesSelector update={handleVenueId} />
    </Wrapper>
  );
};
