import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, message } from "antd";

import { IdmContext } from "@/app/providers/with-idm/idm-context";
import { IRenameGroupDialogModalProps, Modals } from "@/shared/types/modals";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { ButtonsHorizontal } from "@/shared/ui/css/global";
import { closeModal } from "@/shared/ui/modals/storage";

import { Header, Line, StyledCloseOutlined, Text, Wrapper } from "./styles";

export const RenameGroupDialog: FC<IRenameGroupDialogModalProps> = ({
  // TODO: edit group не работает
  // TODO: https://www.notion.so/antaresofficial/278532866fe14d8f9d6f9f94f9343054?v=1c844200728c4f70b1be7bd3dff39e46&p=d4ee72c92a0f4c9eab5b63c7b0585558&pm=s
  groupId,
  name,
}) => {
  const dispatch = useDispatch();
  const [inputText, setInputText] = useState(name);
  const { useRenameGroup } = useContext(IdmContext);
  const [renameGroup, { isLoading, error, isFinised }] = useRenameGroup();

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (isFinised) {
      message.success("Группа переименованна");
      dispatch(closeModal(Modals.RenameGroupDialog));
    }
  }, [isFinised]);

  const handleRename = async () => await renameGroup({ groupId: groupId, name: inputText });

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.RenameGroupDialog))}>
      <Wrapper className="widget">
        <StyledCloseOutlined onClick={() => dispatch(closeModal(Modals.RenameGroupDialog))} />
        <Header>Переименовать группу</Header>
        <Line>
          <Text>Новое имя:</Text>
          <Input value={inputText} onChange={(e) => setInputText(e.target.value)} placeholder={inputText} />
        </Line>
        <ButtonsHorizontal>
          <Button type="primary" onClick={handleRename} loading={isLoading}>
            Переименовать
          </Button>
          <Button onClick={() => dispatch(closeModal(Modals.RenameGroupDialog))}>Отмена</Button>
        </ButtonsHorizontal>
      </Wrapper>
    </BoxWithCloseOnClickOutside>
  );
};
