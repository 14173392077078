import { useCallback, useEffect, useRef } from "react";

import { GlobalKeyBoardListeners } from "../types/globals";

export const useWindowKeyboardListener = (type: GlobalKeyBoardListeners, callback: (event: KeyboardEvent) => void) => {
  const callbackRef = useRef(callback);

  const addKeyboardListener = useCallback(() => window.addEventListener("keydown", callbackRef.current), []);
  const removeKeyboardListener = useCallback(() => window.removeEventListener("keydown", callbackRef.current), []);

  useEffect(() => {
    removeKeyboardListener();
    callbackRef.current = callback;
    addKeyboardListener();

    return () => removeKeyboardListener();
  }, [callback]);
};
