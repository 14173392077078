import { createApi } from "@reduxjs/toolkit/query/react";

import { Document } from "../../types/types";
import {
  CounteragentList,
  DiadocDocumentEntity,
  DocumentList,
  GetDocflowEventsRequest,
  GetDocflowEventsResponse,
  Message,
  MessageToPost,
  OrganizationList,
} from "../../types/types";

import { baseDiadocQueryWithReauth as baseQuery } from "./base-diadoc.query";

export const diadocApi = createApi({
  reducerPath: "diadocApi",
  baseQuery,
  endpoints: (builder) => ({
    getDocuments: builder.query<DocumentList, { filter: string }>({
      query: ({ filter }) => ({
        url: `/V3/GetDocuments?boxId=${process.env.REACT_APP_DIADOC_BOX_ID}&filterCategory=Any.${filter}NotRevoked`,
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      }),
    }),
    getDocument: builder.query<Document, DiadocDocumentEntity>({
      query: ({ messageId, entityId }) => ({
        url: `/V3/GetDocument?boxId=${process.env.REACT_APP_DIADOC_BOX_ID}&messageId=${messageId}&entityId=${entityId}`,
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      }),
    }),
    getDocflowEvents: builder.mutation<GetDocflowEventsResponse, GetDocflowEventsRequest>({
      query: (body) => ({
        url: `/V3/GetDocflowEvents?boxId=${process.env.REACT_APP_DIADOC_BOX_ID}`,
        headers: {
          Accept: "application/json",
        },
        method: "POST",
        body,
      }),
    }),
    getDocTypes: builder.query<any, void>({
      query: () => ({
        url: `/V2/GetDocumentTypes?boxId=${process.env.REACT_APP_DIADOC_BOX_ID}`,
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      }),
    }),
    getOrganizations: builder.query<OrganizationList, void>({
      query: () => ({
        url: `/GetMyOrganizations?boxId=${process.env.REACT_APP_DIADOC_BOX_ID}&autoRegister=false`,
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      }),
    }),
    getCounteragents: builder.query<CounteragentList, { myOrgId: string }>({
      query: ({ myOrgId }) => ({
        url: `/V2/GetCounteragents`,
        headers: {
          Accept: "application/json",
        },
        method: "GET",
        params: { myOrgId },
      }),
    }),
    sendDocument: builder.mutation<Message, MessageToPost>({
      query: (body) => ({
        url: "/V3/PostMessage",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useLazyGetDocumentQuery,
  useGetDocflowEventsMutation,
  useSendDocumentMutation,
  useLazyGetOrganizationsQuery,
  useLazyGetCounteragentsQuery,
} = diadocApi;
