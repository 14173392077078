import AForm from "antd/es/form";
import AntdForm from "antd/es/form/Form";
import styled from "styled-components";
const { Item } = AForm;

export const Form = styled(AntdForm)`
  max-width: 600px;
  padding-top: 20px;
`;

export const FormTitle = styled.p`
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
`;

export const FormLine = styled.div`
  display: flex;
  gap: 10px;

  .ant-form-item {
    width: 100%;
  }
`;

export const NewItem = styled(Item)``;
