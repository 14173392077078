import { Layout, Skeleton, Tabs } from "antd";
import styled from "styled-components";
const { Content: AntdContent } = Layout;

export const MainBlock = styled(AntdContent)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh !important;
`;

export const Content = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 59px;
  overflow: hidden;
  padding: 20px;
  transition: all 0.2s;

  @media screen and (max-width: 824px) {
    margin-left: 20px;
  }
`;

export const StylizedSkeleton = styled(Skeleton)`
  padding: 20px;
`;

export const StylizedTabs = styled(Tabs)`
  background: transparent;
  margin-bottom: -1px;
  margin-left: 59px;
  margin-right: 20px;
  transition: all 0.2s;
  user-select: none;
`;
