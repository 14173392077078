import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { companiesApi } from "@/entities/companies/api";
import { examsApi } from "@/entities/exams/api";
import { patientsApi } from "@/entities/patients/api";
import { patientsUploadSlice } from "@/entities/patients/storage";
import { authSlice } from "@/entities/session/api";
import { subdivisionsApi } from "@/entities/subdivisions/api";
import { terminalsApi } from "@/entities/terminals/api";
import { userApi } from "@/entities/user/api";
import { venuesApi } from "@/entities/venues/api";
import { astralApi } from "@/process/docs-module/astral/api/astral-api";
import { diadocApi } from "@/process/docs-module/diadoc/api/diadoc-api";
import { toastMiddleware } from "@/process/toast";
import { titlesAstralApi } from "@/shared/api/titles-astral";
import { modalsSlice } from "@/shared/ui/modals/storage";
import { settingsSlice } from "@/shared/ui/settings/model";

export const store = configureStore({
  reducer: {
    [patientsApi.reducerPath]: patientsApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [titlesAstralApi.reducerPath]: titlesAstralApi.reducer,
    [diadocApi.reducerPath]: diadocApi.reducer,
    [astralApi.reducerPath]: astralApi.reducer,
    [venuesApi.reducerPath]: venuesApi.reducer,
    [subdivisionsApi.reducerPath]: subdivisionsApi.reducer,
    [terminalsApi.reducerPath]: terminalsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [examsApi.reducerPath]: examsApi.reducer,
    auth: authSlice.reducer,
    modals: modalsSlice.reducer,
    patientsUpload: patientsUploadSlice.reducer,
    settings: settingsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      patientsApi.middleware,
      companiesApi.middleware,
      titlesAstralApi.middleware,
      astralApi.middleware,
      examsApi.middleware,
      venuesApi.middleware,
      subdivisionsApi.middleware,
      terminalsApi.middleware,
      userApi.middleware,
      toastMiddleware,
    ]),
});

setupListeners(store.dispatch);
