export enum TabKeyEnum {
  Groups = "groups",
  Users = "users",
}

enum TabLabel {
  Groups = "Дочерние группы",
  Users = "Пользователи",
}

type Tab = {
  key: TabKeyEnum;
  label: TabLabel;
};

export const tabs: Tab[] = [
  {
    key: TabKeyEnum.Groups,
    label: TabLabel.Groups,
  },
  {
    key: TabKeyEnum.Users,
    label: TabLabel.Users,
  },
];
