import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Table } from "antd";

import { AuthContext } from "@/app/providers/with-auth/auth-context";
import { Group } from "@/app/providers/with-idm/types";
import { Modals } from "@/shared/types/modals";
import { Message, Search } from "@/shared/ui";
import { WithViewControl } from "@/shared/ui/error-control";
import { openModalWithProps } from "@/shared/ui/modals/storage";

import { groupsColumns } from "./constants";
import { Header } from "./styles";

export const GroupsTable: FC<{
  group: Group | undefined;
  subGroups: Group[];
  selectedSubGroups: Group[];
  setSelectedSubGroups: React.Dispatch<React.SetStateAction<Group[]>>;
}> = ({ group, subGroups, selectedSubGroups, setSelectedSubGroups }) => {
  const authContext = useContext(AuthContext);
  const isAdmin = authContext?.userData?.profile.policy?.includes("admin");
  const [searchText, setSearchText] = useState("");
  const [selectedTableGroups, setSelectedTableGroups] = useState<Group[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedTableGroups([]);
  }, [group]);

  useEffect(() => {
    setSelectedSubGroups(selectedTableGroups);
  }, [selectedTableGroups]);

  const handleSearch = (event) => setSearchText(event.target.value);

  const handleDelete = async () =>
    dispatch(
      openModalWithProps({
        modalName: Modals.DeleteGroupsDialog,
        props: {
          groupsId: selectedTableGroups.map((group) => group.id),
          parentId: group?.id,
        },
      })
    );

  const filteredSubGroups = useMemo(() => {
    return subGroups?.filter((group) => group.name?.includes(searchText));
  }, [subGroups, searchText]);

  return (
    <WithViewControl emptyText={() => <Message text="Подгруппы не найдены" />}>
      <Search text="Введите название группы..." onSearch={handleSearch} />
      <Header>
        <Button
          onClick={() =>
            dispatch(
              openModalWithProps({
                modalName: Modals.AddGroupDialog,
                props: { groupId: group?.id },
              })
            )
          }
          disabled={!isAdmin || !group}
        >
          Создать группу
        </Button>
        <Button disabled={!selectedTableGroups.length} onClick={handleDelete}>
          Удалить группу
        </Button>
        <Button
          disabled={selectedSubGroups.length !== 1}
          onClick={() =>
            dispatch(
              openModalWithProps({
                modalName: Modals.RenameGroupDialog,
                props: {
                  groupId: selectedSubGroups[0].id,
                  name: selectedSubGroups[0].name,
                },
              })
            )
          }
        >
          Переименовать группу
        </Button>
      </Header>
      <Table
        columns={groupsColumns({ selectedTableGroups, setSelectedTableGroups })}
        dataSource={filteredSubGroups}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        scroll={{ x: "max-content" }}
      />
    </WithViewControl>
  );
};
