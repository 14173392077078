import React from "react";
import { Pie } from "@ant-design/plots";

interface ICustomPie {
  examsPassedLength: number | undefined;
  examsFailedLength: number | undefined;
}

export const CustomPie: React.FC<ICustomPie> = ({ examsPassedLength, examsFailedLength }) => {
  const data = [
    {
      type: "Прошло успешно",
      value: examsPassedLength ? examsPassedLength : 0,
    },
    {
      type: "Отклонено",
      value: examsFailedLength ? examsFailedLength : 0,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    color: ["#1677ff", "#af2a11"],
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return <Pie {...config} />;
};
