import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { PersonEntity } from "@medregister/domain";
import { Card, Table } from "antd";

import { patientsUploadSelector } from "@/entities/patients/storage";
import { UploadButtons } from "@/features/patients/upload";
import { Modals } from "@/shared/types/modals";
import { is } from "@/shared/types/types-guards";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { WithViewControl } from "@/shared/ui/error-control";
import { closeModal } from "@/shared/ui/modals/storage";

import { personUploadColumns } from "./constants";

interface IPatientsTableProps {
  uploadPatients?: PersonEntity[];
}

export const PatientsUploadTable: FC<IPatientsTableProps> = ({ uploadPatients }) => {
  return (
    <WithViewControl isEmpty={is.empty(uploadPatients)}>
      <Table
        columns={personUploadColumns}
        dataSource={uploadPatients}
        rowKey="id"
        pagination={{ pageSize: 5 }}
        scroll={{ x: "max-content" }}
      />
    </WithViewControl>
  );
};

export const UploadPatients = () => {
  const dispatch = useDispatch();

  const patients = useSelector(patientsUploadSelector);

  const handleClose = () => {
    dispatch(closeModal(Modals.PatientsUploadDialog));
  };

  return (
    <BoxWithCloseOnClickOutside close={handleClose}>
      <Card
        title="Загрузить пациентов"
        style={{ width: "70vw", minWidth: "320px", height: "80vh" }}
        extra={<CloseOutlined onClick={handleClose} />}
      >
        <UploadButtons />
        <PatientsUploadTable uploadPatients={patients} />
      </Card>
    </BoxWithCloseOnClickOutside>
  );
};
