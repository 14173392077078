import styled, { css, keyframes, ThemedStyledProps } from "styled-components";

import { JustifyContent } from "./types";

export const flexAlignCenter = css<{ justify?: string }>`
  align-items: center;
  display: flex;
  justify-content: ${({ justify = "flex-start" }) => justify};
`;

export const FlexContainer = styled.div<{
  justify?: JustifyContent;
  width?: string;
}>`
  display: flex;
  ${({ width }) => width};
`;

export const FlexAlignCenterContainer = styled.div<{
  justify?: JustifyContent;
  width?: string;
}>`
  ${flexAlignCenter};
  width: ${({ width }) => width};
`;

export const PositionRelativeContainer = styled.div<{
  justify?: JustifyContent;
  width?: string;
}>`
  position: relative;
`;

export const flexAlignCenterJustifyCenter = css`
  ${flexAlignCenter};
  justify-content: center;
`;

export const EmptyContainerWithTextCentered = styled(FlexAlignCenterContainer)`
  font-size: 24px;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const SpinnerAnimation = keyframes`
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
`;

export const ModalUI = css`
  border-radius: 8px;
  padding: 25px 40px;
`;

export const ButtonSpace = css`
  display: flex;
  gap: 10px 20px;
`;

const DEFAULT_LOADER_SIZE = 20;
const getLoaderSize = ({
  size = DEFAULT_LOADER_SIZE,
  loaderSize,
}: ThemedStyledProps<{ isLoading?: boolean; size?: number; loaderSize?: number }, any>) => (loaderSize || size) + "px";
const getLoaderPosition = ({
  size = DEFAULT_LOADER_SIZE,
  loaderSize,
}: ThemedStyledProps<{ isLoading?: boolean; size?: number; loaderSize?: number }, any>) =>
  `calc(50% - ${(loaderSize || size) / 2}px)`;

export const withInsideLoaderStyle = css<{
  isLoading?: boolean;
  size?: number;
  loaderSize?: number;
}>`
  position: relative;

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    `};

  &::before {
    animation: ${SpinnerAnimation} 0.6s ease-in 0s infinite normal;
    background-color: transparent;
    border: 4px solid rgba(6, 169, 218, 0.8);
    border-radius: 100%;
    border-top-color: transparent;
    content: "";
    filter: invert(1);
    height: ${getLoaderSize};
    left: ${getLoaderPosition};
    position: absolute;
    top: ${getLoaderPosition};
    width: ${getLoaderSize};
    z-index: 1;

    ${({ isLoading }) =>
      !isLoading &&
      css`
        display: none;
      `};
  }
`;
