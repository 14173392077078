import React from "react";
import { Result } from "antd";
import styled from "styled-components";

export const ServerStopped = () => (
  <WrapRoot>
    <InnerBlock>
      <Result status="500" title="500" subTitle="Сервер временно не доступен" />
    </InnerBlock>
  </WrapRoot>
);

export const WrapRoot = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const InnerBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
