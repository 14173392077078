import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { CompanyEntity } from "@medregister/domain";
import { Button } from "antd";

import { useDeleteCompanyMutation, useGetCompanyQuery } from "@/entities/companies/api";
import { ModalMode, Modals } from "@/shared/types/modals";
import { ButtonsVertical } from "@/shared/ui/css";
import { openModalWithProps } from "@/shared/ui/modals/storage";

import { Item, Root } from "./styles";

interface ICompanyItemProps {
  companyId: CompanyEntity["id"];
}

export const CompanyItem: FC<ICompanyItemProps> = ({ companyId }) => {
  const dispatch = useDispatch();
  const { data: company } = useGetCompanyQuery(companyId);
  const [deleteCompany] = useDeleteCompanyMutation();

  if (!company) {
    return null;
  }

  const handleEdit = () => {
    dispatch(
      openModalWithProps({
        modalName: Modals.CompaniesDialog,
        props: { id: company.id, modalMode: ModalMode.EDIT },
      })
    );
  };

  return (
    <Root>
      <Item>
        <h3>{company.legalName}</h3>
        <p>Префикс: {company.prefix}</p>
        <p>ИНН: {company.inn}</p>
        <p>КПП: {company.kpp}</p>
      </Item>
      <ButtonsVertical>
        <Button onClick={handleEdit}>Изменить</Button>
        <Button onClick={() => deleteCompany(company.id)} type="primary">
          Удалить
        </Button>
      </ButtonsVertical>
    </Root>
  );
};
