import React, { FC, useEffect, useMemo, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Input } from "antd";
import Tree, { DataNode } from "antd/es/tree";

import { useSearchCompaniesMutation } from "@/entities/companies/api";
import { CompanyItem } from "@/entities/companies/ui/item";
import { useForceSetState } from "@/shared/hooks";
import { Modals } from "@/shared/types/modals";
import { CreateButton, Message } from "@/shared/ui";
import { PanelWrapper } from "@/shared/ui/css";
import { WithViewControl } from "@/shared/ui/error-control";

const { Search: AntdSearch } = Input;

export const CompaniesTree: FC = () => {
  const [searchCompanies, { data: companies, error, isLoading }] = useSearchCompaniesMutation({
    fixedCacheKey: "filteredCompanies",
  });
  const [companyName, setCompanyName] = useState();

  const { keyForRerender, reloadComponent } = useForceSetState();

  const Company = (companyId) => ({
    key: `${companyId}${keyForRerender}`,
    title: <CompanyItem companyId={companyId} />,
  });

  const formatDataForTree = (data): DataNode[] =>
    data?.map(({ companies, id }) => ({
      ...Company(id),
      children: companies ? companies.map((company) => Company(company.id)) : null,
    }));

  const handleSearch = (e) => {
    setCompanyName(e.target.value);
    searchCompanies({ nested: true, value: companyName });
  };

  const companiesTreeNodes = useMemo(() => formatDataForTree(companies), [companies]);

  useEffect(() => {
    searchCompanies({ value: companyName, nested: true });
  }, [companyName]);

  useEffect(() => {
    reloadComponent();
  }, [companies]);

  return (
    <WithViewControl error={error} isLoading={isLoading}>
      <AntdSearch
        placeholder="Введите название филиала"
        enterButton="Поиск"
        size="large"
        autoFocus
        value={companyName}
        suffix
        allowClear
        onChange={(e) => handleSearch(e)}
      />
      <PanelWrapper>
        <CreateButton modal={Modals.CompaniesDialog} text={"компанию"} />
      </PanelWrapper>
      {companies?.length ? (
        <Tree showLine switcherIcon={<DownOutlined />} treeData={companiesTreeNodes} />
      ) : (
        <Message text="Компании не найдены" />
      )}
    </WithViewControl>
  );
};
