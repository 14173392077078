import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Button, Table } from "antd";

import { UserData } from "@/app/providers/with-idm/types";
import { Modals } from "@/shared/types/modals";
import { Message } from "@/shared/ui";
import { WithViewControl } from "@/shared/ui/error-control";
import { openModalWithProps } from "@/shared/ui/modals/storage";

import { usersColumns } from "./constants";
import { Header } from "./styles";

export const UsersTable: FC<{
  users: UserData[];
  loading?: boolean;
  selectedUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  withGroups?: boolean;
  withControl?: boolean;
  groupId?: string;
}> = ({ users, loading, selectedUsers, setSelectedUsers, withGroups, groupId, withControl }) => {
  const dispatch = useDispatch();

  return (
    <WithViewControl emptyText={() => <Message text="Пользователи не найдены" />}>
      {withControl ? (
        <Header>
          <Button
            onClick={() =>
              dispatch(
                openModalWithProps({
                  modalName: Modals.AddUsersToGroupDialog,
                  props: { groupId: groupId },
                })
              )
            }
          >
            Добавить пользователя
          </Button>
          <Button
            onClick={() =>
              dispatch(
                openModalWithProps({
                  modalName: Modals.ExcludeUsersFromGroupDialog,
                  props: { groupId: groupId, users: selectedUsers },
                })
              )
            }
            disabled={!selectedUsers.length}
          >
            Исключить пользователя
          </Button>
        </Header>
      ) : null}
      <Table
        columns={usersColumns({ selectedUsers, setSelectedUsers, withGroups })}
        dataSource={users}
        rowKey={(record) => record.user.id}
        pagination={{ pageSize: 10 }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </WithViewControl>
  );
};
