import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import { TabsContext } from "@/app/providers/ui/withTabs";
import { loginNurse } from "@/entities/session/api";
import { useLazyGetUserRolesQuery } from "@/entities/user/api";
import { useAuth } from "@/shared/hooks/use-auth";
import { jwtStorage } from "@/shared/lib";

import { Footer } from "./ui/footer";
import { Header } from "./ui/header";
import { Sidebar } from "./ui/sidebar";
import { Content, MainBlock, StylizedSkeleton, StylizedTabs } from "./styles";

export const MainLayout = () => {
  const auth = useAuth();
  const jwt = jwtStorage.getJwt();

  const { onChange, onEdit, tabs, activeKey } = useContext(TabsContext);

  const dispatch = useDispatch();

  const [getUserRoles, { data: roles }] = useLazyGetUserRolesQuery();

  useEffect(() => {
    if (roles) {
      const isMedic = Boolean(roles?.realmMappings?.find((r) => r.name === "MEDIC"));

      dispatch(loginNurse(isMedic));
    }
  }, [roles, auth]);

  useEffect(() => {
    if (auth.userData?.profile.sub && jwt) {
      getUserRoles(auth.userData?.profile?.sub);
    }
  }, [auth, jwt]);

  return (
    <Layout>
      <Sidebar />
      <MainBlock>
        <Header />
        <StylizedTabs
          hideAdd
          onChange={onChange}
          activeKey={activeKey}
          type="editable-card"
          onEdit={onEdit}
          items={tabs}
          style={{
            opacity: tabs.length && window.location.pathname !== "/" ? 1 : 0,
          }}
        />
        <Content style={window.location.pathname === "/" ? { border: "none" } : {}} className="content">
          {auth && auth.userData ? <Outlet /> : <StylizedSkeleton />}
        </Content>
        <Footer />
      </MainBlock>
    </Layout>
  );
};
