import React from "react";
import { CONCLUSION, INDICATOR } from "@medregister/domain";
import { Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";

import { ExamRO } from "@/entities/exams/interfaces";
import { DateFormats } from "@/shared/types/globals";

export const journalsColumns: ColumnsType<ExamRO> = [
  {
    title: "№ осмотра",
    dataIndex: "id",
    key: "id",
    render: (value) => <p>{value.slice(-4)}</p>,
  },
  {
    title: "Дата",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value) => <p>{dayjs(value).locale("ru").format(DateFormats.Default)}</p>,
  },
  {
    title: "Ф.И.О.",
    dataIndex: "nurse",
    key: "nurse",
    render: (value) => (
      <p>{`${value?.venueStaff?.roleMember?.user?.person?.lastName} ${
        value?.venueStaff?.roleMember?.user?.person?.firstName
      } ${value?.venueStaff?.roleMember?.user?.person?.middleName || ""}`}</p>
    ),
  },
  {
    title: "Дата рождения",
    dataIndex: "nurse",
    key: "nurse",
    render: (value) => <p>{`${value?.venueStaff?.roleMember?.user?.person?.dateOfBirth}`}</p>,
  },
  {
    title: "Организация",
    dataIndex: "nurse",
    key: "nurse",
    render: (value) => (
      <p>{`${value?.venueStaff?.roleMember?.subdivisions[0]?.company?.legalName || "Не указана"} `}</p>
    ),
  },
  {
    title: "АД (мм рт. ст.)",
    dataIndex: "examValues",
    key: "examValues",
    render: (value) => {
      return (
        <p>
          {value.length
            ? value.find((e) => e.reference.indicator.indicatorCode === INDICATOR.SYS)?.value
            : "Не указано"}
        </p>
      );
    },
  },
  {
    title: "Пульс (уд./мин)",
    dataIndex: "examValues",
    key: "examValues",
    render: (value) => {
      return (
        <p>
          {value.length
            ? value.find((e) => e.reference.indicator.indicatorCode === INDICATOR.PULSE)?.value
            : "Не указано"}
        </p>
      );
    },
  },
  {
    title: "Температура тела (°C)",
    dataIndex: "examValues",
    key: "examValues",
    render: (value) => {
      return (
        <p>
          {value.length
            ? value.find((e) => e.reference.indicator.indicatorCode === INDICATOR.TEMPERATURE)?.value
            : "Не указано"}
        </p>
      );
    },
  },
  {
    title: "Проба на алкоголь (%)",
    dataIndex: "examValues",
    key: "examValues",
    render: (value) => {
      return (
        <p>
          {value.length
            ? value.find((e) => e.reference.indicator.indicatorCode === INDICATOR.ALCOHOL_FAST)?.value
            : "Не указано"}
        </p>
      );
    },
  },
  {
    title: "Код водителя",
    dataIndex: "employee",
    key: "employee",
    render: (value) => <p>{value.personnelNumber}</p>,
  },
  {
    title: "Допуск",
    dataIndex: "conclusion",
    key: "conclusion",
    render: (value) => {
      if (value === CONCLUSION.PASSED) {
        return <Tag color="green">Допущен</Tag>;
      } else if (value === CONCLUSION.FAILED) {
        return <Tag color="volcano">Не допущен</Tag>;
      }
    },
  },
];
