/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_CODE } from "@medregister/domain";

import { activityStorage } from "@/shared/lib";
import { modeSelector, setMode, setShift, shiftSelector } from "@/shared/ui/settings/model";

import { AstralEvents } from "./astral-events";

const getMode = () => activityStorage.getMode();
const getShift = () => activityStorage.getShift();

export const MQTT = () => {
  const dispatch = useDispatch();

  const shift = useSelector(shiftSelector);
  const mode = useSelector(modeSelector);

  useEffect(function storageValue() {
    dispatch(setShift(getShift()));
    dispatch(setMode(getMode()));
  }, []);

  useEffect(() => {
    const initialValue = { shift: getShift(), mode: getMode() };

    if (initialValue.mode !== getMode) {
      activityStorage.updateMode(mode);
    }
    if (initialValue.shift !== shift) {
      activityStorage.updateShift(shift);
    }
  }, [shift, mode]);

  return <AstralEvents />;
};
