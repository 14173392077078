import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const titlesAstralApi = createApi({
  reducerPath: "titlesAstralApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_TITLES_STORAGE }),
  tagTypes: ["AstralTitles"],
  endpoints: (builder) => ({
    getTitles1: builder.query<any[], void>({
      query: () => "/titles1",
    }),
    deleteTitles1: builder.mutation<any[], string[]>({
      query: (eventId) => ({
        url: "/titles1",
        method: "DELETE",
        body: eventId,
      }),
    }),
    putTitles1: builder.mutation<any[], string[]>({
      query: (eventId) => ({
        url: "/titles1",
        method: "PUT",
        body: eventId,
      }),
    }),
    getTitles5: builder.query<any[], void>({
      query: () => "/titles5",
    }),
    deleteTitles5: builder.mutation<any[], string[]>({
      query: (eventId) => ({
        url: "/titles5",
        method: "DELETE",
        body: eventId,
      }),
    }),
    addTitles1: builder.mutation<any[], any>({
      query: (body) => ({
        url: "/titles1",
        method: "POST",
        body,
      }),
    }),
    addTitles5: builder.mutation<any[], any>({
      query: (body) => ({
        url: "/titles5",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetTitles1Query,
  useDeleteTitles1Mutation,
  usePutTitles1Mutation,
  useDeleteTitles5Mutation,
  useGetTitles5Query,
  useAddTitles1Mutation,
  useAddTitles5Mutation,
} = titlesAstralApi;
