import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Certificate, getUserCertificates } from "crypto-pro";

import { StylizedSelect } from "./styles";
import { certStorage } from "./utils";

const { Option } = Select;

export const CertificateSelector = () => {
  const [certificates, setCertificates] = useState<Certificate[]>([]);

  const selectCertificate = (value) => {
    const certificate = certificates.find(({ thumbprint }) => thumbprint === value);
    certStorage.updateCert(certificate);
  };

  useEffect(() => {
    (async () => {
      setCertificates(await getUserCertificates());
    })();
  });

  if (!certificates.length) {
    return <p>Сертификаты не были найдены</p>;
  }

  return (
    <StylizedSelect
      defaultValue={certStorage.getCert()?.thumbprint || ""}
      placeholder="Выберите сертификат..."
      id="certificate"
      allowClear
      onChange={selectCertificate}
    >
      {certificates.map(({ name, thumbprint }) => (
        <Option key={thumbprint} value={thumbprint}>
          {name}
        </Option>
      ))}
    </StylizedSelect>
  );
};
