import React from "react";
import { GENDER, PersonEntity } from "@medregister/domain";
import { ColumnsType } from "antd/es/table";

import { PatientHandler } from "../../../features/patients/item-handle";

export const personColumns: ColumnsType<PersonEntity> = [
  {
    title: "ФИО",
    render: (_, person) => <p>{`${person.lastName} ${person.firstName} ${person.middleName || ""}`}</p>,
  },
  {
    title: "Пол",
    dataIndex: "gender",
    key: "gender",
    render: (gender: PersonEntity["gender"]) => (gender === GENDER.FEMALE ? <p>Женский</p> : <p>Мужской</p>),
  },
  {
    title: "Дата рождения",
    dataIndex: "dateOfBirth",
    key: "dateOfBirth",
  },
  {
    title: "Страховой номер",
    dataIndex: "insuranceNumber",
    key: "insuranceNumber",
    render: (insuranceNumber: PersonEntity["insuranceNumber"]) => (
      <p>{`${insuranceNumber ? insuranceNumber : "Не указано"}`}</p>
    ),
  },
  {
    title: "ИНН",
    dataIndex: "itn",
    key: "itn",
    render: (itn: PersonEntity["itn"]) => <p>{`${itn ? itn : "Не указано"}`}</p>,
  },
  {
    title: "Действие",
    key: "action",
    width: 160,
    render: (_, value) => <PatientHandler patientId={value.id} />,
  },
];
