import { TerminalEntity } from "@medregister/domain";
import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryPlatform } from "@/shared/lib";

import { TerminalRO } from "./interfaces";

interface IGetTerminalsParams {
  limit?: number;
  offset?: number;
  companyId?: string;
  venueId?: string;
  terminalCn?: string;
}

export const terminalsApi = createApi({
  reducerPath: "terminalsApi",
  baseQuery: baseQueryPlatform,
  tagTypes: ["Terminal"],
  endpoints: (builder) => ({
    getTerminals: builder.query<TerminalRO[], IGetTerminalsParams | void>({
      query: (params: IGetTerminalsParams) => ({ url: `/terminals`, params }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ terminalCn }) => ({
                type: "Terminal" as const,
                terminalCn,
              })),
            ]
          : [{ type: "Terminal", id: "TERMINALS" }],
    }),
    getTerminal: builder.query<TerminalRO, TerminalEntity["terminalCn"]>({
      query: (terminalCn) => `/terminals/${terminalCn}`,
      providesTags: ["Terminal"],
    }),
    createTerminal: builder.mutation<TerminalRO, TerminalRO>({
      query: (body) => ({
        url: `/terminals/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Terminal"],
    }),
    deleteTerminal: builder.mutation<TerminalRO, TerminalRO["terminalCn"]>({
      query: (terminalCn) => ({
        url: `/terminals/${terminalCn}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Terminal"],
    }),
    updateTerminal: builder.mutation<TerminalRO, TerminalRO>({
      query: (body) => ({
        url: `/terminals/${body.terminalCn}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Terminal"],
    }),
  }),
});

export const {
  useLazyGetTerminalsQuery,
  useGetTerminalQuery,
  useCreateTerminalMutation,
  useDeleteTerminalMutation,
  useUpdateTerminalMutation,
} = terminalsApi;
