import React, { FC } from "react";
import { Form, Input } from "antd";

import { useCreateCompanyMutation, useGetCompanyQuery, useUpdateCompanyMutation } from "@/entities/companies/api";
import { CompaniesSelector } from "@/entities/companies/ui/selector";
import { FieldsRules } from "@/shared/constants";
import { IModalProps } from "@/shared/types/modals";
import { EntityDialog } from "@/shared/ui";
import { StylizedItem } from "@/shared/ui/css/global";

import { HorizontalBlock } from "./styles";

export const CompanyDialog: FC<IModalProps> = ({ id, modalMode }) => {
  const { data: company, isLoading } = useGetCompanyQuery(id);

  const [form] = Form.useForm();

  const [createCompany] = useCreateCompanyMutation();
  const [updateCompany] = useUpdateCompanyMutation();

  return (
    <EntityDialog
      create={createCompany}
      update={updateCompany}
      data={company}
      initialValues={{ parentCompanyId: company?.parentCompanyId }}
      modalMode={modalMode}
      title="компанию"
      isLoading={isLoading}
    >
      <HorizontalBlock>
        <div>
          <StylizedItem name="legalName" rules={FieldsRules.default}>
            <Input placeholder="Полное название" />
          </StylizedItem>

          <StylizedItem name="parentCompanyId" rules={FieldsRules.default}>
            <CompaniesSelector updateCompanyId={(parentCompanyId) => form.setFieldsValue({ parentCompanyId })} />
          </StylizedItem>

          <StylizedItem name="shortName" rules={FieldsRules.default}>
            <Input placeholder="Сокращенное название" />
          </StylizedItem>
        </div>
        <div>
          <StylizedItem name="prefix" rules={FieldsRules.default}>
            <Input placeholder="Префикс" />
          </StylizedItem>

          <StylizedItem name="inn" rules={FieldsRules.default}>
            <Input minLength={12} maxLength={16} placeholder="ИНН" />
          </StylizedItem>

          <StylizedItem name="kpp" rules={FieldsRules.default}>
            <Input minLength={9} maxLength={9} placeholder="КПП" />
          </StylizedItem>
        </div>
      </HorizontalBlock>
    </EntityDialog>
  );
};
