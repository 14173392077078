import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import { MQTT } from "@/process/mqtt";
import { ErrorBoundaryFallback } from "@/shared/ui";
import { ModalsContainer } from "@/shared/ui/modals/ui/container";

import { GlobalStyle } from "./styles/global-styles";
import { withProviders } from "./providers";
import { Routing } from "./routes";
import { store } from "./store";

import "react-toastify/dist/ReactToastify.css";

const App = () => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
    <GlobalStyle />
    <Provider store={store}>
      <Routing />
      <ModalsContainer />
      <ToastContainer />
      <MQTT />
    </Provider>
  </ErrorBoundary>
);

export default withProviders(App);
