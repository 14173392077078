import { Divider } from "antd";
import styled from "styled-components";

export const Item = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin: 12px 10px;
`;

export const ItemTitle = styled.p`
  font-size: 15px;
`;

export const Root = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 60px auto;
  width: 100%;
`;

export const StyledDivider = styled(Divider)`
  margin: 14px 0;
`;
