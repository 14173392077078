import React, { FC, useEffect, useState } from "react";
import { VenueEntity } from "@medregister/domain";
import { Input } from "antd";

import { useCreateVenueMutation, useLazyGetVenueQuery, useUpdateVenueMutation } from "@/entities/venues/api";
import { FieldsRules } from "@/shared/constants";
import { IModalProps, ModalMode } from "@/shared/types/modals";
import { EntityDialog } from "@/shared/ui";
import { StylizedItem } from "@/shared/ui/css/global";

export const VenueDialog: FC<IModalProps> = ({ id, modalMode }) => {
  const [triggerGetVenue, { isLoading }] = useLazyGetVenueQuery();

  const [createVenue] = useCreateVenueMutation();
  const [updateVenue] = useUpdateVenueMutation();

  const [venue, setVenue] = useState<VenueEntity>();

  useEffect(() => {
    (async () => {
      if (modalMode === ModalMode.EDIT) {
        const { data } = await triggerGetVenue(id);
        setVenue(data);
      }
    })();
  }, []);

  return (
    <EntityDialog
      create={createVenue}
      update={updateVenue}
      data={venue}
      isLoading={isLoading}
      modalMode={modalMode}
      title="точку"
    >
      <StylizedItem name="title" rules={FieldsRules.default}>
        <Input placeholder="Название" />
      </StylizedItem>
      <StylizedItem name="addressStr" rules={FieldsRules.default}>
        <Input placeholder="Адрес точки" />
      </StylizedItem>
    </EntityDialog>
  );
};
