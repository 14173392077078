import React, { FC, useContext, useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";

import { TitleContext } from "@/app/providers/ui/with-title";
import { ErrorBoundaryFallback } from "@/shared/ui/error-boundary";
import { WithViewControl } from "@/shared/ui/error-control";
import { JournalsTable } from "@/widgets/journals/table";

const Journals: FC = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Журналы");
  }, []);

  return (
    <WithViewControl>
      <JournalsTable />
    </WithViewControl>
  );
};

export const JournalsPage = withErrorBoundary(Journals, {
  FallbackComponent: ErrorBoundaryFallback,
});
