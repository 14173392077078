import React, { FC } from "react";
import { withErrorBoundary } from "react-error-boundary";

import { ErrorBoundaryFallback } from "@/shared/ui/error-boundary";
import { WithViewControl } from "@/shared/ui/error-control";
import { ExamsTable } from "@/widgets/exams/table";

const MonitoringEpd: FC = () => (
  <WithViewControl>
    <ExamsTable />
  </WithViewControl>
);

export const MonitoringEpdPage = withErrorBoundary(MonitoringEpd, {
  FallbackComponent: ErrorBoundaryFallback,
});
