import React from "react";
import { useNavigate } from "react-router";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";

const { Paragraph, Text } = Typography;

export const UnAuth = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="error"
      title="Ошибка авторизации"
      subTitle="Попробуйте войти в систему ещё раз."
      extra={[
        <Button type="primary" key="dashboard" onClick={() => navigate("/dashboard")}>
          Сводка
        </Button>,
        <Button key="restart" onClick={() => location.reload()}>
          Перезагрузить
        </Button>,
      ]}
    >
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            Возможные причины возникнования ошибки:
          </Text>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Сессия была удалена или устарела
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Был изменен токен
        </Paragraph>
      </div>
    </Result>
  );
};
