import React from "react";
import { Checkbox, TableProps } from "antd";
import dayjs from "dayjs";

import { MinioFile } from "@/app/providers/with-minio/types";
import { DateFormats } from "@/shared/types/globals";

interface ColumnsProps {
  selectFiles: string[];
  setSelectFiles: React.Dispatch<React.SetStateAction<string[]>>;
  fileNames: string[];
}

export const genFilesColumns: (props: ColumnsProps) => TableProps<MinioFile>["columns"] = ({
  selectFiles,
  setSelectFiles,
  fileNames,
}) => [
  {
    title: fileNames.length ? (
      <Checkbox
        checked={selectFiles.length === fileNames.length}
        onChange={() =>
          selectFiles.length === fileNames.length
            ? setSelectFiles([])
            : setSelectFiles((prev) => [...prev, ...fileNames.filter((item) => !selectFiles.includes(item))])
        }
      />
    ) : null,
    dataIndex: "name",
    key: "name",
    render: (value) => (
      <Checkbox
        checked={selectFiles.includes(value)}
        onChange={() =>
          selectFiles.includes(value)
            ? setSelectFiles((prev) => prev.filter((item) => item !== value))
            : setSelectFiles((prev) => [...prev, value])
        }
      />
    ),
  },
  {
    title: "Дата создания",
    dataIndex: "lastModified",
    key: "lastModified",
    render: (value) => <p>{dayjs(value).locale("ru").format(DateFormats.DefaultTime)}</p>,
  },
  {
    title: "Время создания",
    dataIndex: "lastModified",
    key: "lastModified",
    render: (value) => <p>{dayjs(value).locale("ru").format(DateFormats.Default)}</p>,
  },
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
  },
];
