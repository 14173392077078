import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { CONCLUSION } from "@medregister/domain";
import { Button } from "antd";

import { routePaths } from "@/app/routes/paths";
import { useConclusionExamMutation } from "@/entities/exams/api";
import { IExamDialogModalProps, Modals } from "@/shared/types/modals";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { ButtonsHorizontal } from "@/shared/ui/css";
import { closeModal } from "@/shared/ui/modals/storage";

import { Content, Header, Wrapper } from "./styles";

export const ExamsDialog: FC<IExamDialogModalProps> = ({ exam }) => {
  const dispatch = useDispatch();
  const person = exam.nurse.venueStaff.roleMember.user.person;

  const [updateConclusionExam] = useConclusionExamMutation();

  const handleConclusion = (conclusion) => {
    updateConclusionExam({ conclusion, examId: exam.id });
  };

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.ExamDialog))}>
      <Wrapper className="widget">
        <Header>
          <h3>Осмотр</h3>
          <CloseOutlined onClick={() => dispatch(closeModal(Modals.ExamDialog))} />
        </Header>
        <Content>
          <h3>
            {person.firstName} {person.lastName}{" "}
          </h3>
        </Content>
        <ButtonsHorizontal>
          <Button onClick={() => handleConclusion(CONCLUSION.PASSED)}>Допустить</Button>
          <Button
            onClick={() =>
              updateConclusionExam({
                conclusion: CONCLUSION.FAILED,
                examId: exam.id,
              })
            }
            danger
            type="primary"
          >
            Не допустить
          </Button>
          <Link to={`${routePaths.exams}/${exam.id}`}>
            <Button onClick={() => dispatch(closeModal(Modals.ExamDialog))}>Открыть</Button>
          </Link>
        </ButtonsHorizontal>
      </Wrapper>
    </BoxWithCloseOnClickOutside>
  );
};
