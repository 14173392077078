import React from "react";
import { ColumnsType } from "antd/es/table";

import { SubdivisionRO } from "@/entities/subdivisions/interfaces";
import { SubdivisionHandler } from "@/features/subdivisions/item-handle";

export const subdivisionColumns: ColumnsType<SubdivisionRO> = [
  {
    title: "Название подразделения",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Название компании",
    dataIndex: "company",
    key: "company",
    render: (value: SubdivisionRO["company"]) => <p>{value?.legalName || "Не определена"}</p>,
  },
  {
    title: "Адрес подразделения",
    dataIndex: "venueTitle",
    key: "venueTitle",
    render: (_, value) => <p>{value?.venues?.map((ven) => ven.title)}</p>,
  },
  {
    title: "Кол-во точек",
    dataIndex: "venues",
    key: "venues",
    render: (_, value) => <p>{value?.venues?.length}</p>,
  },
  {
    title: "",
    key: "actions",
    width: 160,
    render: (_, value) => <SubdivisionHandler subdivisionId={value.id} />,
  },
];
