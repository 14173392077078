import React, { lazy, ReactNode, Suspense } from "react";

import { useTheme } from "@/shared/hooks/use-theme";

const DarkTheme = lazy(() => import("@/shared/theme/view/DarkTheme"));
const LightTheme = lazy(() => import("@/shared/theme/view/LightTheme"));

export const withTheme = (component: () => ReactNode) => () => {
  const [darkMode] = useTheme();

  return (
    <Suspense>{darkMode ? <DarkTheme>{component()}</DarkTheme> : <LightTheme>{component()}</LightTheme>}</Suspense>
  );
};
