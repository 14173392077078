import { Layout } from "antd";
import styled from "styled-components";

const { Footer: AntdFooter } = Layout;

export const StylizedFooter = styled(AntdFooter)`
  align-items: center;
  background: #313131 !important;
  display: flex;
  justify-content: center;
  padding: auto 35px !important;
`;
