import { Form } from "antd";
import styled from "styled-components";

export const StylizedItem = styled(Form.Item)`
  display: flex;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 480px) {
    width: 280px;
  }
`;

export const ButtonsVertical = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: 160px;

    &:last-child {
      margin-top: 5px;
    }
  }
`;

export const ButtonsHorizontal = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

export const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;
