import React, { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, FormInstance } from "antd";

import { ModalMode } from "@/shared/types/modals";
import { is } from "@/shared/types/types-guards";

import { BoxWithCloseOnClickOutside } from "../box-with-close-on-click-outside";
import { Loader } from "../loader";
import { closeActiveModal } from "../modals/storage";

import { IDialogProps } from "./interfaces";
import { FormButton, StylizedButton, StylizedForm } from "./styles";

enum ModalWindowUI {
  create = "Создать",
  edit = "Изменить",
}

export const EntityDialog: FC<IDialogProps> = ({
  title,
  modalMode,
  create,
  form,
  update,
  children,
  data,
  initialValues,
  isLoading,
}) => {
  const formRef = React.createRef<FormInstance>();

  const dispatch = useDispatch();

  const isEditMode = useMemo(() => modalMode === ModalMode.EDIT, [modalMode]);

  const handleClose = () => {
    dispatch(closeActiveModal());
  };

  const onFinish = (values) => {
    if (isEditMode) {
      update(values);
    } else {
      create(values);
    }
    handleClose();
  };

  useEffect(
    function loadPrevData() {
      if (data) {
        // @ts-ignore
        if (data?.dateOfBirth) {
          // @ts-ignore
          formRef.current!.setFieldsValue({
            ...data,
            // dateOfBirth: dayjs(data?.dateOfBirth),
          });
        } else {
          formRef.current!.setFieldsValue(data);
        }
      }
    },
    [modalMode, data]
  );

  if (!is.truthy((data && isEditMode) || isLoading || !isEditMode)) {
    return <Loader />;
  }

  return (
    <BoxWithCloseOnClickOutside close={handleClose}>
      <Card
        title={`${ModalWindowUI[modalMode]} ${title}`}
        style={{ width: "70vw", minWidth: "320px", height: "80vh" }}
        extra={<CloseOutlined onClick={handleClose} />}
      >
        <StylizedForm
          initialValues={initialValues}
          name="basic"
          form={form}
          ref={formRef}
          onFinish={onFinish}
          autoComplete="off"
        >
          {children}
          <FormButton>
            <Button onClick={handleClose}>Отмена</Button>
            <StylizedButton type="primary" htmlType="submit">
              Сохранить
            </StylizedButton>
          </FormButton>
        </StylizedForm>
      </Card>
    </BoxWithCloseOnClickOutside>
  );
};
