import { useEffect, useState } from "react";
import { message } from "antd";
import { createSignature } from "crypto-pro";
import iconv from "iconv-lite";
import parser from "xml-js";

import {
  useDeleteTitles1Mutation,
  useDeleteTitles5Mutation,
  useGetTitles1Query,
  useGetTitles5Query,
} from "@/shared/api/titles-astral";

import { generateTitle6 } from "../generators/generate-title6";
import { arrayBufferToBase64, generateTitle6Filename } from "../libs";
import { ISendT6AstralParams } from "../types/types";

import { usePostEventsConfirmMutation, usePostEventsMutation, useSendDocumentMutation } from "./api/astral-api";
import { useGetTitle1Astral } from "./get-title1-hook";
import { useGetTitle5Astral } from "./get-title5-hook";

export const useLazySendTitle6 = () => {
  const [sendDocument, { data: sendDoc, isLoading }] = useSendDocumentMutation();
  const [postEvents] = usePostEventsMutation();
  const { setTitle1 } = useGetTitle1Astral();
  const { setTitle5 } = useGetTitle5Astral();
  const [postEventsConfirm] = usePostEventsConfirmMutation();
  const [deleteTitles5] = useDeleteTitles5Mutation();
  const [deleteTitles1] = useDeleteTitles1Mutation();

  const { data: titles1Astral } = useGetTitles1Query();
  const { data: titles5Astral } = useGetTitles5Query();

  const [error, setError] = useState<Error>();
  const [isSuccess, setIsSuccess] = useState(false);

  const [params, setParams] = useState<ISendT6AstralParams>();

  const sendTitle6 = async (params: ISendT6AstralParams, thumbprint) => {
    try {
      const title6FileName = generateTitle6Filename(params.fileName);

      const title6 = generateTitle6({
        title5: params.title5,
        ИдФайл: title6FileName,
        signature: params.t1Signa,
        ourMedData: params.medData,
        firstName: params.firstName,
        secondName: params.secondName,
        lastName: params.lastName,
      });

      setParams(params);

      const title6Xml = parser.js2xml(title6, { compact: true });
      const byteArrayWin1251 = iconv.encode(title6Xml, "win1251");
      const blobT6 = new Blob([byteArrayWin1251], {
        type: "application/xml;charset=windows-1251",
      });
      console.log((window.webkitURL || window.URL).createObjectURL(blobT6));
      const base64 = arrayBufferToBase64(byteArrayWin1251);

      const signature = await createSignature(thumbprint, base64);

      const data = await fetch(`data:application/octet-stream;base64,${signature}`);
      sendDocument({
        file: blobT6,
        signature: await data.blob(),
        fileName: title6FileName,
      });
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      }
    }
  };

  useEffect(() => {
    if (sendDoc && params && titles1Astral && titles5Astral) {
      setIsSuccess(sendDoc?.status === 200);
      message.success("Отправлен Т6");

      const docId = sendDoc.files.filter((file) => file.mime === "application/xml;charset=windows-1251")[0].id;
      const signaId = sendDoc.files.filter((file) => file.mime === "application/octet-stream")[0].id;

      postEvents({
        docId: docId,
        signaId: signaId,
        eventId: params.t5EventId,
        ИдПолучателя: params.ИдПолучателя,
        ИдОтправителя: params.ИдОтправителя,
        ТипОтправителя: params.ТипОтправителя,
        Tn: "Т6",
      }).then(() => {
        const newSavedT1 = titles1Astral.filter((item) => {
          return item.УИД_ПЛ !== params.title5.Файл.Документ.СодИнфЗаезд._attributes.УИД_ПЛ;
        });
        setTitle1(newSavedT1);

        const newSavedT5 = titles5Astral.filter((item) => {
          return item.УИД_ПЛ !== params.title5.Файл.Документ.СодИнфЗаезд._attributes.УИД_ПЛ;
        });
        setTitle5(newSavedT5);

        const savedExamsT5 = JSON.parse(localStorage.getItem("examsT5") || "");
        const newSavedExamsT5 = savedExamsT5.filter((exam) => {
          const fullNameExamT5 = `${exam.Worker.firstName.trim().toLowerCase()}${exam.Worker.lastName
            .trim()
            .toLowerCase()}${exam.Worker.patronymic.trim().toLowerCase()}`;

          const fullNameT5 = `${params.firstName.trim().toLowerCase()}${params.lastName
            .trim()
            .toLowerCase()}${params.secondName.trim().toLowerCase()}`;

          return fullNameT5 !== fullNameExamT5;
        });
        localStorage.setItem("examsT5", JSON.stringify(newSavedExamsT5));

        postEventsConfirm({ eventsId: [params?.t1EventId] });
        deleteTitles5([params?.t5EventId]);
        deleteTitles1([params?.t1EventId]);
      });
    }
  }, [sendDoc, titles1Astral, titles5Astral]);

  return { sendTitle6, error, isLoading, isSuccess };
};
