import React from "react";
import { Checkbox } from "antd";
import { ColumnsType } from "antd/es/table";

import { Group } from "@/app/providers/with-idm/types";

interface ColumnsProps {
  selectedTableGroups: Group[];
  setSelectedTableGroups: React.Dispatch<React.SetStateAction<Group[]>>;
}

export const groupsColumns: (props: ColumnsProps) => ColumnsType<Group> = ({
  selectedTableGroups,
  setSelectedTableGroups,
}) => [
  {
    dataIndex: "id",
    key: "id",
    render: (value, group) => {
      return (
        <Checkbox
          checked={selectedTableGroups.filter((group) => group.id === value).length > 0}
          onChange={() =>
            selectedTableGroups.filter((group) => group.id === value).length > 0
              ? setSelectedTableGroups((prev) => prev.filter((item) => item.id !== value))
              : setSelectedTableGroups((prev) => [...prev, group])
          }
        />
      );
    },
  },
  {
    title: "Имя группы",
    dataIndex: "name",
    key: "name",
    render: (value) => <p>{value}</p>,
  },
];
