import React, { FC, useMemo } from "react";
import { CompanyEntity } from "@medregister/domain";

import { useGetCompaniesQuery } from "@/entities/companies/api";

import { StylizedSelect } from "./styles";

interface ICompaniesSelectorProps {
  update: (value: string) => void;
  value?: CompanyEntity["id"];
}

export const CompanySelector: FC<ICompaniesSelectorProps> = ({ update, value }) => {
  const { data: companies } = useGetCompaniesQuery();

  const companiesOptions = useMemo(
    () =>
      companies?.map((c) => ({
        value: c.id,
        label: `${c.prefix} ${c.legalName}`,
      })),
    [companies]
  );

  if (!companiesOptions || !companiesOptions.length) {
    return null;
  }

  return (
    <StylizedSelect
      allowClear
      placeholder="Выберите компанию"
      onChange={update}
      value={value}
      options={companiesOptions}
    />
  );
};
