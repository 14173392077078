import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { ArrowRightOutlined, WarningFilled } from "@ant-design/icons";
import { Button, List, message, Select } from "antd";

import { MinioContext } from "@/app/providers/with-minio/minio-context";
import { IMoveFilesDialogModalProps, Modals } from "@/shared/types/modals";
import { Loader } from "@/shared/ui";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { ButtonsHorizontal, CenteringWrapper } from "@/shared/ui/css/global";
import { closeModal } from "@/shared/ui/modals/storage";

import { Header, StyledCloseOutlined, Wrapper } from "./styles";

export const MoveFilesDialog: FC<IMoveFilesDialogModalProps> = ({ sourceBucket, fileNames, refetchTrigger }) => {
  const [destinationBucket, setDestinationBucket] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { useMinio } = useContext(MinioContext);

  const [, getBuckets, , , , moveFiles, { buckets, isLoadingBuckets, bucketsError }] = useMinio();
  const dispatch = useDispatch();

  const options =
    buckets?.filter((item) => item.name != sourceBucket).map((e) => ({ value: e.name, label: e.name })) || [];

  const moveHandler = async () => {
    if (destinationBucket) {
      setIsLoading(true);
      const result = await moveFiles({
        sourceBucket: sourceBucket,
        destinationBucket: destinationBucket,
        fileNames: fileNames,
      });
      result
        ? message.success(fileNames.length == 1 ? "Файл перемещён" : "Файлы перемещены")
        : message.error(`Ошибка при перемещении ${fileNames.length == 1 ? "файла" : "файлов"}`);
      refetchTrigger();
      dispatch(closeModal(Modals.MoveFilesDialog));
    }
  };

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.MoveFilesDialog))}>
      <Wrapper className="widget">
        <StyledCloseOutlined onClick={() => dispatch(closeModal(Modals.MoveFilesDialog))} />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Header>
              <Select options={undefined} value={sourceBucket} disabled />
              <ArrowRightOutlined />
              <Select
                placeholder="Выбрать корзину"
                options={options}
                dropdownRender={(value) =>
                  !isLoadingBuckets ? (
                    value
                  ) : (
                    <CenteringWrapper>
                      <Loader />
                    </CenteringWrapper>
                  )
                }
                onClick={() => !buckets.length && getBuckets()}
                onChange={(value) => setDestinationBucket(value)}
              />
              {bucketsError && (
                <>
                  <WarningFilled /> {bucketsError}
                </>
              )}
            </Header>
            <List size="small" dataSource={fileNames} renderItem={(item) => <List.Item>{item}</List.Item>} />
            <ButtonsHorizontal>
              <Button onClick={moveHandler} disabled={!destinationBucket}>
                Переместить
              </Button>
              <Button onClick={() => dispatch(closeModal(Modals.MoveFilesDialog))}>Отмена</Button>
            </ButtonsHorizontal>
          </>
        )}
      </Wrapper>
    </BoxWithCloseOnClickOutside>
  );
};
