import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { Mutex } from "async-mutex";

import { jwtStorage } from "./jwt-storage";

const prepareHeaders = (headers) => {
  const token = jwtStorage.getJwt();
  headers.set("Authorization", "Bearer " + token);
  return headers;
};
const mutex = new Mutex();

const refreshToken = async () => {
  const token = JSON.parse(String(localStorage.getItem("refresh_token")));

  const details = {
    grant_type: "refresh_token",
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    refresh_token: token,
    client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET,
  };

  let formBody: any = [];
  for (const property in details) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  const data = await fetch(String(process.env.REACT_APP_KEYCLOAK_GET_TOKEN_URL), {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formBody,
  });

  const dataJson = await data.json();
  jwtStorage.updateJwt(dataJson["access_token"]);
  localStorage.setItem("refresh_token", JSON.stringify(String(dataJson["refresh_token"])));
};

export const baseQueryPlatform: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const bq = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PLATFORM_HOST,
    prepareHeaders,
  });
  let result = await bq(args, api, extraOptions);
  const jwt = jwtStorage.getJwt();

  if (!jwt || result.error) {
    const release = await mutex.acquire();

    try {
      await refreshToken();
      result = await bq(args, api, extraOptions);
    } finally {
      release();
    }
  } else {
    await mutex.waitForUnlock();
    result = await bq(args, api, extraOptions);
  }

  if (result.data) {
    // @ts-ignore
    return { ...result, data: result.data.data };
  }
  return result;
};

export const baseQueryKeycloak: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const bq = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_KEYCLOAK_AUTH_ADMIN_CI_URL,
    prepareHeaders,
  });
  let result = await bq(args, api, extraOptions);
  const jwt = jwtStorage.getJwt();

  if (!jwt || result.error) {
    const release = await mutex.acquire();

    try {
      await refreshToken();
      result = await bq(args, api, extraOptions);
    } finally {
      release();
    }
  } else {
    await mutex.waitForUnlock();
    result = await bq(args, api, extraOptions);
  }

  return result;
};
