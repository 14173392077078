import React, { FC, useEffect, useMemo } from "react";
import { ReactNode } from "react";
import ReactDOM from "react-dom";

import { createPortal } from "@/shared/lib/create-portal";

import { useCloseOnEscape } from "./hooks";
import { Content, Wrap } from "./styles";

export interface IBoxClickOutside {
  close: () => void;
  children: ReactNode;
}

export const BoxWithCloseOnClickOutside: FC<IBoxClickOutside> = ({ close, children }) => {
  const { portalNode, removeFromDOM } = useMemo(() => createPortal({ zIndex: 5 }), []);

  useEffect(function onUnmount() {
    return () => removeFromDOM();
  }, []);

  useCloseOnEscape();

  return ReactDOM.createPortal(
    <Wrap onClick={close}>
      <Content onClick={(e) => e.stopPropagation()}>{children}</Content>
    </Wrap>,
    portalNode
  );
};
