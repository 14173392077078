import styled from "styled-components";

export const VerticalBlock = styled.div`
  &:nth-child(1) {
    margin-left: 0;
  }
`;
export const HorizontalBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
`;
