import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Input, message, Select } from "antd";
import AntdForm, { FormInstance } from "antd/es/form";

import { AuthContext } from "@/app/providers/with-auth/auth-context";
import { IdmContext } from "@/app/providers/with-idm/idm-context";
import { routePaths } from "@/app/routes/paths";
import { Modals } from "@/shared/types/modals";
import { openModalWithProps } from "@/shared/ui/modals/storage";

import { Buttons, Form, FormLine, FormTitle, NewItem } from "./styles";

const { Item } = AntdForm;
const { Option } = Select;

export const CreateUserForm: React.FC = () => {
  const authContext = useContext(AuthContext);
  const isAdmin = authContext?.userData?.profile.policy.includes("admin");
  const [selectedGroups, setSelectedGroups] = useState<{ id: string; path: string }[]>();
  const [selectedGroupsNames, setSelectedGroupsNames] = useState<string[]>();
  const { useAddUser } = useContext(IdmContext);
  const [addUser, { isLoading, error, isFinised }] = useAddUser();
  const dispatch = useDispatch();
  const formRef = React.createRef<FormInstance>();
  const navigate = useNavigate();

  useEffect(() => {
    error && message.error(error);
  }, [error]);

  useEffect(() => {
    if (isFinised && !error) {
      navigate(routePaths.users);
    }
  }, [isFinised]);

  useEffect(() => {
    !isAdmin && navigate(routePaths.users);
  }, []);

  useEffect(() => {
    setSelectedGroupsNames(selectedGroups?.map((group) => group.path));
  }, [selectedGroups]);

  useEffect(() => {
    if (selectedGroupsNames) {
      formRef.current?.setFieldValue("groups", selectedGroupsNames);
    }
  }, [selectedGroupsNames]);

  const handleAddGroups = () => {
    dispatch(
      openModalWithProps({
        modalName: Modals.AddGroupsDialog,
        props: { selectedGroups, setSelectedGroups },
      })
    );
  };

  const onFinish = (values: any) => addUser(values);

  return (
    <Form name="createUserForm" onFinish={onFinish} ref={formRef}>
      <Item
        label="Логин"
        name="login"
        rules={[
          { required: true, message: "Введите логин!" },
          {
            max: 256,
            message: "Логин должен содержать не более 256 символов!",
          },
        ]}
      >
        <Input />
      </Item>
      <FormTitle>Аттрибуты пользователя</FormTitle>
      <Item
        label="Фамилия пользователя"
        name="lastName"
        rules={[
          {
            pattern: /^[\u0400-\u04FF-]*$/,
            message: "Фамилия должна содержать только кириллицу и символ -",
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Имя пользователя"
        name="firstName"
        rules={[
          {
            pattern: /^[\u0400-\u04FF-]*$/,
            message: "Имя должно содержать только кириллицу и символ -",
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Роль пользователя"
        name="roleUser"
        rules={[{ required: true, message: "Выберите роль пользователя!" }]}
      >
        <Select>
          <Option value="admin">Администратор</Option>
          <Option value="curator">Куратор</Option>
          <Option value="manager">Менеджер</Option>
          <Option value="medic">Медик</Option>
          <Option value="terminal">Терминал</Option>
        </Select>
      </Item>
      <FormLine>
        <NewItem label="Группы" name="groups" rules={[{ required: true, message: "Выберите группу!" }]}>
          {
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Выберите группу"
              dropdownStyle={{ display: "none" }}
            ></Select>
          }
        </NewItem>
        <Button type="primary" onClick={() => handleAddGroups()}>
          Выбрать группу
        </Button>
      </FormLine>
      <Buttons>
        <Button onClick={() => navigate(routePaths.users)}>Отмена</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Добавить
        </Button>
      </Buttons>
    </Form>
  );
};
