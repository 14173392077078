import React, { FC } from "react";
import { Spin } from "antd";

import { CenteredBlock } from "./styles";

export interface ILoaderProps {
  position?: "default" | "center";
  size?: "small" | "large";
}

export const Loader: FC<ILoaderProps> = ({ size = "large", position = "center" }) => {
  return {
    default: <Spin />,
    center: (
      <CenteredBlock>
        <Spin size={size} />
      </CenteredBlock>
    ),
  }[position];
};
