import { v4 as uuid } from "uuid";

export const createPortal = ({ id = uuid(), element = "div", zIndex = 1 } = {}): {
  portalNode: HTMLElement;
  removeFromDOM(): void;
} => {
  const root = document.createElement(element);

  root.id = id;

  root.style.zIndex = zIndex.toString();

  const removeFromDOM = () => document.body.removeChild(root);

  document.body.appendChild(root);

  return { portalNode: root, removeFromDOM };
};
