import React, { FC, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, message } from "antd";

import { IdmContext } from "@/app/providers/with-idm/idm-context";
import { IDeleteGroupsDialogModalProps, Modals } from "@/shared/types/modals";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { ButtonsHorizontal } from "@/shared/ui/css/global";
import { closeModal } from "@/shared/ui/modals/storage";

import { Header, StyledCloseOutlined, Wrapper } from "./styles";

export const DeleteGroupsDialog: FC<IDeleteGroupsDialogModalProps> = ({ groupsId, parentId }) => {
  const dispatch = useDispatch();
  const { useDeleteGroups, useGetGroups } = useContext(IdmContext);
  const [deleteGroup, { isLoading, error, isFinised }] = useDeleteGroups();
  const [fetchChildren] = useGetGroups();

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (isFinised) {
      if (groupsId.length > 1) {
        message.success("Группы удалены");
      } else {
        message.success("Группа удалена");
      }
      dispatch(closeModal(Modals.DeleteGroupsDialog));
      fetchChildren({ parentId: parentId });
    }
  }, [isFinised]);

  const handleRename = async () => await deleteGroup({ groupsId: groupsId });

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.DeleteGroupsDialog))}>
      <Wrapper className="widget">
        <StyledCloseOutlined onClick={() => dispatch(closeModal(Modals.DeleteGroupsDialog))} />
        <Header>Вы уверены?</Header>
        <ButtonsHorizontal>
          <Button type="primary" onClick={handleRename} loading={isLoading}>
            Да
          </Button>
          <Button onClick={() => dispatch(closeModal(Modals.DeleteGroupsDialog))}>Нет</Button>
        </ButtonsHorizontal>
      </Wrapper>
    </BoxWithCloseOnClickOutside>
  );
};
