import React, { ReactNode } from "react";

import { ProtectedRouteNurse } from "@/entities/session/protected-route-nurse";
import { CompaniesPage } from "@/pages/companies";
import { DashboardPage } from "@/pages/dashboard";
import { ForbiddenPage } from "@/pages/errors/forbidden";
import { NotFoundPage } from "@/pages/errors/not-found";
import { Exam } from "@/pages/exam";
import { MonitoringEpdPage } from "@/pages/exams";
import { FilesPage } from "@/pages/files";
import { GroupsPage } from "@/pages/groups";
import { JournalsPage } from "@/pages/journals";
import { PatientsPage } from "@/pages/patients";
import { ShiftPage } from "@/pages/shift";
import { SubdivisionsPage } from "@/pages/subdivisions";
import { CreateSubdivisonPage } from "@/pages/subdivisions/create-subdivision";
import { EditSubdivisionPage } from "@/pages/subdivisions/edit-subdivision";
import { TerminalsPage } from "@/pages/terminals";
import { UsersPage } from "@/pages/users";
import { CreateUserPage } from "@/pages/users/create-user";
import { MainPage } from "@/pages/welcome";

import { routePaths } from "./paths";

interface Route {
  path: string;
  element: ReactNode;
}

export const routes: Route[] = [
  {
    path: routePaths.main,
    element: <MainPage />,
  },
  {
    path: routePaths.patients,
    element: <PatientsPage />,
  },
  {
    path: routePaths.companies,
    element: <CompaniesPage />,
  },
  {
    path: routePaths.terminals,
    element: <TerminalsPage />,
  },
  {
    path: routePaths.files,
    element: <FilesPage />,
  },
  {
    path: routePaths.users,
    element: <UsersPage />,
  },
  {
    path: routePaths.createUser,
    element: <CreateUserPage />,
  },
  {
    path: routePaths.groups,
    element: <GroupsPage />,
  },
  {
    path: routePaths.dashboard,
    element: <DashboardPage />,
  },
  {
    path: routePaths.subdivisions,
    element: <SubdivisionsPage />,
  },
  {
    path: routePaths.createSubdivision,
    element: <CreateSubdivisonPage />,
  },
  {
    path: routePaths.editSubdivision,
    element: <EditSubdivisionPage />,
  },
  {
    path: routePaths.monitoringEpd,
    element: (
      <ProtectedRouteNurse>
        <MonitoringEpdPage />
      </ProtectedRouteNurse>
    ),
  },
  {
    path: routePaths.examsId,
    element: (
      <ProtectedRouteNurse>
        <Exam />
      </ProtectedRouteNurse>
    ),
  },
  {
    path: routePaths.journals,
    element: <JournalsPage />,
  },
  {
    path: routePaths.shift,
    element: (
      <ProtectedRouteNurse>
        <ShiftPage />
      </ProtectedRouteNurse>
    ),
  },
  {
    path: routePaths.all,
    element: <NotFoundPage />,
  },
  {
    path: routePaths.notfound,
    element: <NotFoundPage />,
  },
  {
    path: routePaths.forbidden,
    element: <ForbiddenPage />,
  },
];
