import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryKeycloak } from "@/shared/lib";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryKeycloak,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUserRoles: builder.query<any, string>({
      query: (sub) => `/users/${sub}/role-mappings`,
    }),
  }),
});

export const { useLazyGetUserRolesQuery } = userApi;
