import styled from "styled-components";

export const Wrap = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
`;

export const Content = styled.div`
  @media screen and (max-width: 1240px) {
    height: auto;
    max-width: 94%;
  }
  @media screen and (max-width: 800px) {
    .antd-card {
      max-width: 100%;
      width: auto !important;
    }
  }
`;
