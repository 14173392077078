import React from "react";

export const TreeArrow: React.FC<{ isActive: boolean }> = ({ isActive }) => (
  <svg
    style={{
      transform: isActive ? "rotate(-90deg)" : "rotate(0)",
      transition: ".2s",
    }}
    width="6"
    height="10"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.391198 5.14951L5.73567 0.337677C5.98766 0.118546 6.32517 -0.00265177 6.6755 4.28371e-05C7.02583 0.00278556 7.36094 0.1293 7.60867 0.352328C7.85638 0.575368 7.9969 0.877083 7.99995 1.1925C8.003 1.50791 7.86833 1.81178 7.62494 2.03866L3.22511 6L7.62494 9.96133C7.86833 10.1882 8.003 10.4921 7.99995 10.8075C7.9969 11.1229 7.8564 11.4246 7.60867 11.6477C7.36094 11.8707 7.02583 11.9972 6.6755 12C6.32517 12.0027 5.98766 11.8814 5.73567 11.6623L0.391198 6.85049C0.140715 6.6249 2.28512e-07 6.31898 2.14569e-07 6C2.00626e-07 5.68102 0.140715 5.3751 0.391198 5.14951Z"
      fill="white"
    />
  </svg>
);
