import React, { FC, useContext, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { Button, Form, FormInstance, Input, Select } from "antd";

import { TitleContext } from "@/app/providers/ui/with-title";
import { TabsContext } from "@/app/providers/ui/withTabs";
import { routePaths } from "@/app/routes/paths";
import { CompanySelector } from "@/entities/companies/ui/multiple-selector";
import { useGetSubdivisionQuery, useUpdateSubdivisionMutation } from "@/entities/subdivisions/api";
import { useGetVenuesQuery } from "@/entities/venues/api";
import { FieldsRules } from "@/shared/constants";
import { Loader } from "@/shared/ui";
import { StylizedItem } from "@/shared/ui/css/global";
import { StylizedForm } from "@/shared/ui/dialog/styles";

export const EditSubdivisionPage: FC = () => {
  const params = useParams();

  const { onCancelTab } = useContext(TabsContext);
  const { data: subdivision, isLoading } = useGetSubdivisionQuery(String(params?.id), {
    refetchOnMountOrArgChange: true,
  });
  const { data: venues } = useGetVenuesQuery();

  const venuesOptions = useMemo(
    () =>
      venues?.map((c) => ({
        value: c.id,
        label: `${c.title} ${c.addressStr}`,
      })),
    [venues]
  );

  const initialVenues = useMemo(
    () =>
      subdivision?.venues?.map((c) => ({
        value: c.id,
        label: `${c.title} ${c.addressStr}`,
      })),
    [subdivision]
  );

  if (!venuesOptions || !venuesOptions.length) {
    return null;
  }

  const navigate = useNavigate();
  const { setTitle } = useContext(TitleContext);

  const [form] = Form.useForm();

  const [updateSubdivision] = useUpdateSubdivisionMutation();

  const formRef = React.createRef<FormInstance>();

  const handleClose = () => {
    onCancelTab();
    navigate(routePaths.subdivisions);
  };

  useEffect(
    function loadPrevData() {
      if (subdivision) {
        formRef.current!.setFieldsValue(subdivision);
      }
    },
    [subdivision]
  );

  const onFinish = (values) => {
    updateSubdivision({ ...values, id: subdivision?.id });
    onCancelTab();
    navigate(routePaths.subdivisions);
  };

  useEffect(() => {
    if (subdivision) {
      setTitle(`Изменить подразделение ${subdivision?.title}`);
    }
  }, [subdivision]);

  if (isLoading) return <Loader />;

  return (
    <StylizedForm
      initialValues={{
        venueIds: initialVenues,
        companyId: subdivision?.companyId,
      }}
      name="edit"
      form={form}
      ref={formRef}
      onFinish={onFinish}
      autoComplete="off"
    >
      <StylizedItem label="Введите название подразделения: " name="title" rules={FieldsRules.default}>
        <Input placeholder="Название" style={{ width: 300 }} />
      </StylizedItem>
      <StylizedItem label="Выберить компанию подразделения: " name="companyId" rules={FieldsRules.default}>
        <CompanySelector update={(companyId) => form.setFieldsValue({ companyId })} />
      </StylizedItem>
      <StylizedItem label="Выберить адрес подразделения: " name="venueIds" rules={FieldsRules.default}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: 300 }}
          placeholder="Выберите адреса"
          onChange={(venueIds) => form.setFieldsValue({ venueIds })}
          options={venuesOptions}
        />
      </StylizedItem>
      <StylizedItem>
        <Button onClick={handleClose}>Отмена</Button>
        <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
          Сохранить
        </Button>
      </StylizedItem>
    </StylizedForm>
  );
};
