import React, { FC } from "react";
import { withErrorBoundary } from "react-error-boundary";

import { ErrorBoundaryFallback } from "@/shared/ui/error-boundary";
import { WithViewControl } from "@/shared/ui/error-control";
import { DashboardWidgets } from "@/widgets/dashboard";

const Dashboard: FC = () => {
  return (
    <WithViewControl>
      <DashboardWidgets />
    </WithViewControl>
  );
};

export const DashboardPage = withErrorBoundary(Dashboard, {
  FallbackComponent: ErrorBoundaryFallback,
});
