import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryPlatform } from "@/shared/lib";

import { SubdivisionRO, SubdivisionSearchArgs } from "./interfaces";

export const subdivisionsApi = createApi({
  reducerPath: "subdivisionsApi",
  baseQuery: baseQueryPlatform,
  tagTypes: ["Subdivision"],
  endpoints: (builder) => ({
    getSubdivisions: builder.query<SubdivisionRO[], void>({
      query: () => `/subdivisions`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: "Subdivision" as const, id }))]
          : [{ type: "Subdivision", id: "SUBDIVISIONS" }],
    }),
    getSubdivision: builder.query<SubdivisionRO, SubdivisionRO["id"]>({
      query: (id) => `/subdivisions/${id}`,
    }),
    searchSubdivisions: builder.mutation<SubdivisionRO[], SubdivisionSearchArgs>({
      query: (body) => ({
        url: `/subdivisions`,
        method: "POST",
        body,
      }),
    }),
    createSubdivision: builder.mutation<SubdivisionRO[], SubdivisionRO>({
      query: (body) => ({
        url: `/subdivisions/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Subdivision"],
    }),
    deleteSubdivision: builder.mutation<SubdivisionRO[], SubdivisionRO["id"]>({
      query: (id) => ({
        url: `/subdivisions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subdivision"],
    }),
    updateSubdivision: builder.mutation<SubdivisionRO[], SubdivisionRO>({
      query: ({ id, ...body }) => ({
        url: `/subdivisions/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Subdivision"],
    }),
  }),
});

export const {
  useGetSubdivisionsQuery,
  useGetSubdivisionQuery,
  useSearchSubdivisionsMutation,
  useCreateSubdivisionMutation,
  useDeleteSubdivisionMutation,
  useUpdateSubdivisionMutation,
} = subdivisionsApi;
