import React, { FC, useContext, useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";

import { TitleContext } from "@/app/providers/ui/with-title";
import { ErrorBoundaryFallback } from "@/shared/ui/error-boundary";
import { WithViewControl } from "@/shared/ui/error-control";
import { UsersDashboard } from "@/widgets/users/dashboard";

const Users: FC = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Пользователи");
  }, []);

  return (
    <WithViewControl>
      <UsersDashboard />
    </WithViewControl>
  );
};

export const UsersPage = withErrorBoundary(Users, {
  FallbackComponent: ErrorBoundaryFallback,
});
