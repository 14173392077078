import React from "react";
import {
  FireOutlined,
  FolderOpenOutlined,
  FundProjectionScreenOutlined,
  GoldOutlined,
  IdcardOutlined,
  MedicineBoxOutlined,
  ReadOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";

import { routePaths } from "@/app/routes/paths";

type MenuItem = Required<MenuProps>["items"][number];

export const items: MenuItem[] = [
  {
    label: "Сводка",
    key: routePaths.dashboard,
    icon: <FundProjectionScreenOutlined />,
  },
  { label: "Мониторинг ЕПД", key: routePaths.monitoringEpd, icon: <FireOutlined /> },
  // { label: "Организации", key: routePaths.companies },
  {
    label: "Подразделения",
    key: routePaths.subdivisions,
    icon: <GoldOutlined />,
  },
  {
    label: "Терминалы",
    key: routePaths.terminals,
    icon: <MedicineBoxOutlined />,
  },
  { label: "Файлы", key: routePaths.files, icon: <FolderOpenOutlined /> },
  { label: "Пациенты", key: routePaths.patients, icon: <IdcardOutlined /> },
  { label: "Журналы", key: routePaths.journals, icon: <ReadOutlined /> },
  { label: "Пользователи", key: routePaths.users, icon: <UserOutlined /> },
  { label: "Группы", key: routePaths.groups, icon: <TeamOutlined /> },
  // { label: "Астрал 1", key: routePaths.astral1 },
];
