import styled from "styled-components";

import { ButtonSpace, ModalUI } from "@/shared/styles/common";

export const Wrapper = styled.div`
  ${ModalUI}

  flex-direction: column;
  position: relative;
  text-align: center;
  width: 700px;

  ${ButtonSpace}
`;
