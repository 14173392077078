import { createApi } from "@reduxjs/toolkit/query/react";

import { baseAstralQuery } from "./base-astral.query";

type Event = {
  id: string; // string [a-f0-9]{32} – идентификатор события,
  code: string; // – код публикуемого события,
  producer: string; // – идентификатор отправителя события,
  consumer: string[]; // – список сервисов для обработки события,
  created: string; // rfc3339 – дата формирования события,
  data: {
    Документ: {
      Наименование: string;
      ИдДокумента: string;
      Файл: string;
      Подписи: string;
      ТипДокумента: string;
    };
    ИдПолучателя: string;
    ИдОтправителя: string;
    ТипОтправителя: string;
  }; // – параметры для обработки события в сервисе
};

interface eventsResponse {
  status: number; // {100-600} – статус обработки запроса,
  type: string; // uri – ссылка на документацию по ошибки, about:blank, если ошибки нет,
  title: string; // – краткое описание результата запроса,
  events: Event[];
}

type sendedFileInfo = {
  id: string;
  name: string;
  size: number;
  mime: string;
  storage: string;
  created: string;
  updated: string;
  ttl: number;
  acl: {
    owner: string;
    access: {
      c648cc5f26824b9dbc69b9138ec01bc5: string;
    };
  };
};

export interface fileResponse {
  status: number;
  type: string;
  title: string;
  files: sendedFileInfo[];
}

interface postEventsResponse {
  status: number; // {100-600} статус обработки запроса,
  type: string; // ссылка на документацию по ошибки, about:blank, если ошибки нет,
  title: string; // краткое описание результата запроса,
  events: Event[];
}

type EventJson = {
  code: "epd.doc.send"; // код типа события
  parent: string; //  необязательный идентификатор родительского события
  consumer: ["epd"]; // список идентификаторов сервисов для обработки события (например: "reg", "edo", "report")
  data: any; // – параметры для обработки события в сервисе
};

interface postEventArgs {
  events: EventJson[];
}

interface postEventDto {
  docId: string;
  signaId: string;
  eventId: string;
  ИдПолучателя: string;
  ИдОтправителя: string;
  ТипОтправителя: string;
  Tn: "Т2" | "Т6";
}

export const astralApi = createApi({
  reducerPath: "AstralApi",
  baseQuery: baseAstralQuery,
  endpoints: (builder) => ({
    getEvents: builder.query<eventsResponse, void>({
      query: () => ({
        url: `/events`,
        method: "GET",
      }),
    }),
    getEventsUnconfirmed: builder.query<eventsResponse, void>({
      query: () => ({
        url: `/events/unconfirmed`,
        method: "GET",
      }),
    }),
    getEventsArch: builder.query<Blob, { event_uuid: string; ext: string }>({
      query: ({ event_uuid, ext }) => ({
        url: `/events/${event_uuid}${ext}`,
        method: "GET",
        responseHandler: async (response) => await response.blob(),
        cache: "no-cache",
      }),
    }),
    getDocument: builder.query<ArrayBuffer, { documentId: string }>({
      query: ({ documentId }) => ({
        url: `/files/${documentId}`,
        method: "GET",
        responseHandler: async (response) => (await response.blob()).arrayBuffer(),
        cache: "no-cache",
      }),
    }),
    sendDocument: builder.mutation<fileResponse, { file: Blob; signature: Blob; fileName: string }>({
      query: ({ file, signature, fileName }) => {
        const bodyFormData = new FormData();
        bodyFormData.append("signature", signature, `${fileName}.sig`);
        bodyFormData.append("title2", file, `${fileName}.xml`);
        return {
          url: "/files",
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
    }),
    postEvents: builder.mutation<postEventsResponse, postEventDto>({
      query: ({ docId, signaId, eventId, ИдПолучателя, ИдОтправителя, ТипОтправителя, Tn }) => {
        const postEventArgs: postEventArgs = {
          events: [
            {
              code: "epd.doc.send", // код типа события
              parent: eventId, //  необязательный идентификатор родительского события // ID ЕВЕНТА Т1
              consumer: ["epd"], // список идентификаторов сервисов для обработки события (например: "reg", "edo", "report")
              data: {
                ИдАбонента: ИдПолучателя, // должно быть в т1 ИдПолучателя Ивент Т1
                ТипАбонента: "Медработник",
                ТипДО: "ЭПЛ",
                Получатели: [
                  {
                    ИдПолучателя: ИдОтправителя, // ИдОтправителя Т1
                    ТипПолучателя: ТипОтправителя, // ТипПолучателя Т1
                  },
                ],
                Документ: {
                  Файл: docId,
                  ТипДокумента: `${Tn}ЭПЛ`,
                  Подписи: [signaId],
                },
              },
            },
          ],
        };
        return {
          url: "/events",
          method: "POST",
          body: postEventArgs,
        };
      },
    }),
    postEventsConfirm: builder.mutation<any, any>({
      query: ({ eventsId }) => {
        const postEventsConfirmArgs: any = {
          events_id: eventsId,
        };
        return {
          url: "/events/confirm",
          method: "POST",
          body: postEventsConfirmArgs,
        };
      },
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventsUnconfirmedQuery,
  useLazyGetEventsUnconfirmedQuery,
  useLazyGetEventsArchQuery,
  useLazyGetDocumentQuery,
  useSendDocumentMutation,
  usePostEventsMutation,
  usePostEventsConfirmMutation,
} = astralApi;
