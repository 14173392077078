import base64js from "base64-js";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import { DateFormats } from "@/shared/types/globals";

import { Title1, СвВодит } from "./types/title1-type";

export const generateTitleFilename = (fileName) => {
  const fileNameSplited = fileName?.split("_");

  const date = dayjs().locale("ru").format(DateFormats.ClumpDefault);

  const title2FileNameSplited = [
    "ON",
    "PTLSPRMO",
    fileNameSplited[5],
    fileNameSplited[4],
    fileNameSplited[2],
    date,
    uuidv4(),
  ];

  return title2FileNameSplited.join("_");
};

export const generateTitle6Filename = (fileName) => {
  const fileNameSplited = fileName.split("_");

  const title6FileNameSplited = [
    "ON",
    "PTLSPOSMO",
    fileNameSplited[2],
    fileNameSplited[3],
    dayjs().locale("ru").format(DateFormats.ClumpDefault),
    uuidv4(),
  ];

  return title6FileNameSplited.join("_");
};

export const titleMapper = (item) =>
  item.map((item: Title1<СвВодит>) => ({
    fileName: item.Файл._attributes.ИдФайл,
    firstName: item.Файл.Документ.СодИнфСоб.СвВодит.ФИО._attributes.Имя,
    secondName: item.Файл.Документ.СодИнфСоб.СвВодит.ФИО._attributes.Отчество,
    lastName: item.Файл.Документ.СодИнфСоб.СвВодит.ФИО._attributes.Фамилия,
    title1: item,
    УИД_ПЛ: item.Файл.Документ.СодИнфСоб._attributes.УИД_ПЛ.toLowerCase(),
    needT6: item.Файл.Документ.СодИнфСоб._attributes.ОбМедОсмПосле === "1" ? true : false,
  }));

export const title1SoloTitles = (docs) =>
  docs.reduce((acc: Title1<СвВодит>[], current: Title1<СвВодит | СвВодит[]>) => {
    if (Array.isArray(current.Файл.Документ.СодИнфСоб.СвВодит)) {
      const soloTitles: Title1<СвВодит>[] = [];
      current.Файл.Документ.СодИнфСоб.СвВодит.map((_, index) => {
        const severalTitle: Title1<СвВодит> = JSON.parse(JSON.stringify(current));
        severalTitle.Файл.Документ.СодИнфСоб.СвВодит = current.Файл.Документ.СодИнфСоб.СвВодит[index];
        soloTitles.push(severalTitle);
      });
      acc.push(...soloTitles);
    } else {
      // @ts-ignore
      acc.push(current);
    }
    return acc;
  }, []);

export const isMatchDriver = ({ firstName, secondName, lastName }, item: Title1<СвВодит>) =>
  firstName === item.Файл.Документ.СодИнфСоб.СвВодит.ФИО._attributes.Имя &&
  lastName === item.Файл.Документ.СодИнфСоб.СвВодит.ФИО._attributes.Фамилия &&
  secondName === item.Файл.Документ.СодИнфСоб.СвВодит.ФИО._attributes.Отчество;
// inn === item.Файл.Документ.СодИнфСоб.СвЛицПЛ.ИдСв.СвЮЛУч._attributes.ИННЮЛ &&
// kpp === item.Файл.Документ.СодИнфСоб.СвЛицПЛ.ИдСв.СвЮЛУч._attributes.КПП;

export function base64Decode(str, encoding = "windows-1251") {
  const bytes = base64js.toByteArray(str);
  return new TextDecoder(encoding).decode(bytes);
}

export function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
