import React, { FC, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useSubscription } from "mqtt-react-hooks";

import {
  useDeleteTitles1Mutation,
  useDeleteTitles5Mutation,
  useGetTitles1Query,
  useGetTitles5Query,
} from "@/shared/api/titles-astral";
import { DateFormats } from "@/shared/types/globals";
import { certStorage } from "@/shared/ui/certificate/ui/selector/utils";

import { usePostEventsConfirmMutation } from "../docs-module/astral/api/astral-api";
import { useGetTitle1Astral } from "../docs-module/astral/get-title1-hook";
import { useGetTitle5Astral } from "../docs-module/astral/get-title5-hook";
import { useLazySendTitle2Astral } from "../docs-module/astral/send-title2-hook";
import { useLazySendTitle6 } from "../docs-module/astral/send-title6-hook";
import { INewDocAstral } from "../docs-module/types/types";

import { getFullNameExam, getFullNameWorker } from "./helpers";

export const AstralEvents: FC = () => {
  const [examsT1, setExamsT1] = useState<any>(JSON.parse(localStorage.getItem("examsT1") || "[]"));
  const [examsT5, setExamsT5] = useState<any>(JSON.parse(localStorage.getItem("examsT5") || "[]"));
  const [cert, setCert] = useState("");
  const isSendingT2Ref = useRef<boolean>(false);
  const isSendingT6Ref = useRef<boolean>(false);

  const { data: titles1Astral } = useGetTitles1Query();

  const { data: titles5Astral } = useGetTitles5Query();
  const [deleteTitles1] = useDeleteTitles1Mutation();
  const [deleteTitles5] = useDeleteTitles5Mutation();

  const { getT1 } = useGetTitle1Astral();
  const { getT5 } = useGetTitle5Astral();
  const { sendTitle2, isLoading } = useLazySendTitle2Astral();
  const [postEventsConfirm] = usePostEventsConfirmMutation();
  const { sendTitle6 } = useLazySendTitle6();

  const { message } = useSubscription(String(process.env.REACT_APP_BROKER_TOPIC));

  useEffect(() => {
    getT1();
    getT5();
    const interval = setInterval(() => {
      getT1();
      getT5();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const thumbprint = certStorage.getCert()?.thumbprint;
    setCert(thumbprint);
  }, []);

  useEffect(
    function saveExamsToState() {
      if (message?.message) {
        const mess = JSON.parse(String(message?.message) || "[]");
        const exam = mess?.exam;

        if (exam.status !== "signed") return;

        if (exam.ExamType.id !== 4 && exam.ExamType.id !== 1) return;

        const double = examsT1.find(
          (lastExam) =>
            `${lastExam.createdAt}${getFullNameExam(lastExam)}` === `${exam.createdAt}${getFullNameExam(exam)}`
        );

        if (!double) {
          if (exam.ExamType.id === 1 && exam.allowance === true) {
            setExamsT1((prev) => [...prev, exam]);
          }
          if (exam.ExamType.id === 4 && exam.status === "signed") {
            setExamsT5((prev) => [...prev, exam]);
          }
        }
      }
    },
    [message]
  );

  useEffect(
    function saveT5ToStorage() {
      if (examsT5.length) {
        localStorage.setItem("examsT5", JSON.stringify(examsT5));
        getT5();
      }
    },
    [examsT5]
  );

  useEffect(
    function arriviedT1ExamT1() {
      if (examsT1?.length && titles1Astral) {
        for (const exam of examsT1) {
          const fullName = getFullNameExam(exam);

          const ExamUpdatedAt = dayjs(exam.updatedAt).locale("ru");

          const t1Worker = titles1Astral
            .filter((person) => {
              const ДатаИспПЛ = person.title1.Файл.Документ.СодИнфСоб.СрокПЛ._attributes.ДатаИспПЛ;
              const ДатаНачИспПЛ = person.title1.Файл.Документ.СодИнфСоб.СрокПЛ._attributes.ДатаНачИспПЛ;
              const ДатаКонИспПЛ = person.title1.Файл.Документ.СодИнфСоб.СрокПЛ._attributes.ДатаКонИспПЛ;

              const currentDate = dayjs();

              let isActualT1 = false;
              if (ДатаНачИспПЛ && ДатаКонИспПЛ) {
                const startDate = dayjs(ДатаНачИспПЛ, DateFormats.UserFriendly);
                const endDate = dayjs(ДатаКонИспПЛ, DateFormats.UserFriendly);
                isActualT1 = currentDate.isBetween(startDate, endDate, null, "[]");
              } else {
                const expires = dayjs(ДатаИспПЛ, DateFormats.UserFriendly);
                isActualT1 = expires.isAfter(currentDate) || expires.isSame(currentDate, "day");
              }
              const isActualDate =
                isActualT1 && (ExamUpdatedAt.isBefore(currentDate) || ExamUpdatedAt.isSame(currentDate, "day"));

              if (!isActualT1) {
                deleteTitles1([person.title1.eventId]);
                postEventsConfirm({ eventsId: [person.title1.eventId] });
              }

              const isSendedT2 = person.title1.sendedT2;

              return isActualDate && !isSendedT2;
            })
            .filter((person) => {
              const fullNameT1 = getFullNameWorker(person);
              const isSameName = fullNameT1 === fullName;
              return isSameName;
            })
            .reduce((oldestPerson: INewDocAstral | null, currentPerson: INewDocAstral) => {
              const oldestDate = oldestPerson ? dayjs(oldestPerson.title1.date, DateFormats.UserFriendly) : dayjs(0);
              const currentDate = dayjs(currentPerson.title1.date, DateFormats.UserFriendly);
              return oldestDate.isBefore(currentDate) ? oldestPerson : currentPerson;
            }, null);

          if (t1Worker && cert && !isLoading) {
            const formattedDate = dayjs(exam.updatedAt).locale("ru").format("DD.MM.YYYYTHH:mm:ssZ");

            if (!isSendingT2Ref.current) {
              isSendingT2Ref.current = true;
              sendTitle2(
                {
                  title1: t1Worker.title1,
                  fileName: t1Worker.fileName,
                  t1Signa: t1Worker.title1.t1Signa,
                  eventId: t1Worker.title1.eventId,
                  ИдПолучателя: t1Worker.title1.ИдПолучателя,
                  ИдОтправителя: t1Worker.title1.ИдОтправителя,
                  ТипОтправителя: t1Worker.title1.ТипОтправителя,
                  examId: exam.id,
                  needT6: t1Worker.needT6,
                  medData: {
                    ДатВрПрМО: formattedDate,
                    НаимМедОрг: exam.Contract.Contractor.legalName,
                    Фамилия: exam.Nurse.lastName,
                    Имя: exam.Nurse.firstName,
                    Отчество: exam.Nurse.patronymic,
                    Ном: "00368102",
                    Сер: "Л041-01137-77",
                    ДатВыд: "26.03.2020",
                    Срок: "18.06.2099",
                  },
                },
                cert
              ).then(() => {
                isSendingT2Ref.current = false;
                getT1();
              });
            }
          }

          if (!t1Worker) {
            const savedExams = JSON.parse(localStorage.getItem("examsT1") || `[]`);
            const newSavedExams = savedExams.filter((item) => item.id !== exam.id);
            localStorage.setItem("examsT1", JSON.stringify([...newSavedExams, exam]));
          }
        }
      }
    },
    [message, cert, examsT1, titles1Astral]
  );

  useEffect(
    function arriviedT5() {
      if (titles5Astral?.length && titles1Astral) {
        for (const person of titles5Astral) {
          const t1Worker = titles1Astral.find((item) => item.УИД_ПЛ.toLowerCase() === person.УИД_ПЛ.toLowerCase());
          if (!t1Worker) {
            deleteTitles5([person.title5.eventId]);
            postEventsConfirm({ eventsId: [person.title5.eventId] });
          }
        }

        for (const exam of examsT5) {
          const fullName = getFullNameExam(exam);

          const t5Worker = titles5Astral.find((person) => {
            const fullNameT1 = getFullNameWorker(person.title5);

            return fullNameT1 === fullName;
          });

          const t1Worker =
            t5Worker &&
            titles1Astral.find((person) => {
              return person.УИД_ПЛ.toLowerCase() === t5Worker.УИД_ПЛ.toLowerCase();
            });

          if (t5Worker) {
            if (t1Worker && t1Worker.needT6) {
              const date = new Date(exam.updatedAt);
              const timezoneOffset = date.getTimezoneOffset();
              const formattedDate = `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}.${date.getFullYear()}T${date.getHours().toString().padStart(2, "0")}:${date
                .getMinutes()
                .toString()
                .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}${
                timezoneOffset >= 0 ? "+" : "-"
              }${Math.floor(Math.abs(timezoneOffset / 60))
                .toString()
                .padStart(2, "0")}:00`;

              if (!isSendingT6Ref.current) {
                isSendingT6Ref.current = true;
                sendTitle6(
                  {
                    title5: t5Worker.title5,
                    fileName: t5Worker.fileName,
                    t1Signa: t5Worker.title5.t1Signa,
                    t5EventId: t5Worker.title5.eventId,
                    t1EventId: t1Worker.title1.eventId,
                    ИдПолучателя: t5Worker.title5.ИдПолучателя,
                    ИдОтправителя: t5Worker.title5.ИдОтправителя,
                    ТипОтправителя: t5Worker.title5.ТипОтправителя,
                    firstName: t1Worker.firstName,
                    secondName: t1Worker.secondName,
                    lastName: t1Worker.lastName,
                    medData: {
                      ДатВрПрМО: formattedDate,
                      НаимМедОрг: exam.Contract.Contractor.legalName,
                      Фамилия: exam.Nurse.lastName,
                      Имя: exam.Nurse.firstName,
                      Отчество: exam.Nurse.patronymic,
                      Ном: "00368102",
                      Сер: "Л041-01137-77",
                      ДатВыд: "26.03.2020",
                      Срок: "18.06.2099",
                    },
                  },
                  cert
                ).then(() => {
                  isSendingT6Ref.current = false;
                  getT5();

                  const savedExams = JSON.parse(localStorage.getItem("examsT5") || `[]`);
                  const newSavedExams = savedExams.filter((item) => item.id !== exam.id);
                  localStorage.setItem("examsT5", JSON.stringify(newSavedExams));
                });
              }
            }
          }
        }
      }
    },
    [message, cert, titles5Astral]
  );

  useEffect(
    function arriviedExamT5() {
      if (titles5Astral && titles1Astral) {
        for (const person of titles5Astral) {
          const t1Worker = titles1Astral.find((item) => item.УИД_ПЛ.toLowerCase() === person.УИД_ПЛ.toLowerCase());
          if (!t1Worker) {
            deleteTitles5([person.title5.eventId]);
            postEventsConfirm({ eventsId: [person.title5.eventId] });
          }
        }

        for (const exam of examsT5) {
          const fullName = getFullNameExam(exam);

          const t5Worker = titles5Astral.find((person) => {
            const fullNameT5 = `${person.title5.firstName.trim().toLowerCase()}${person.title5.lastName
              .trim()
              .toLowerCase()}${person.title5.secondName.trim().toLowerCase()}`;

            return fullNameT5 === fullName;
          });

          if (t5Worker && titles1Astral) {
            const t1Worker = titles1Astral.find((person) => {
              const fullNameT1 = getFullNameWorker(person);
              const fullNameT5 = `${t5Worker.title5.firstName.trim().toLowerCase()}${t5Worker.title5.lastName
                .trim()
                .toLowerCase()}${t5Worker.title5.secondName.trim().toLowerCase()}`;

              return fullNameT1 === fullNameT5 && person.УИД_ПЛ.toLowerCase() === t5Worker.УИД_ПЛ.toLowerCase();
            });

            if (!t1Worker) {
              const savedExams = JSON.parse(localStorage.getItem("examsT5") || `[]`);
              const newSavedExams = savedExams.filter((item) => item.id !== exam.id);
              localStorage.setItem("examsT5", JSON.stringify(newSavedExams));
            }

            if (t1Worker && t1Worker.needT6) {
              const examT5 = examsT5.find((exam) => {
                const fullNameExamT5 = getFullNameExam(exam);

                const fullNameT1 = `${t1Worker.firstName.trim().toLowerCase()}${t1Worker.lastName
                  .trim()
                  .toLowerCase()}${t1Worker.secondName.trim().toLowerCase()}`;

                return fullNameT1 === fullNameExamT5;
              });

              if (examT5) {
                const date = new Date(exam.updatedAt);
                const timezoneOffset = date.getTimezoneOffset();
                const formattedDate = `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}.${date.getFullYear()}T${date.getHours().toString().padStart(2, "0")}:${date
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}${
                  timezoneOffset >= 0 ? "+" : "-"
                }${Math.floor(Math.abs(timezoneOffset / 60))
                  .toString()
                  .padStart(2, "0")}:00`;

                if (!isSendingT6Ref.current) {
                  isSendingT6Ref.current = true;
                  sendTitle6(
                    {
                      title5: t5Worker.title5,
                      fileName: t5Worker.fileName,
                      t1Signa: t5Worker.title5.t1Signa,
                      t5EventId: t5Worker.title5.eventId,
                      t1EventId: t1Worker.title1.eventId,
                      ИдПолучателя: t5Worker.title5.ИдПолучателя,
                      ИдОтправителя: t5Worker.title5.ИдОтправителя,
                      ТипОтправителя: t5Worker.title5.ТипОтправителя,
                      firstName: t1Worker.firstName,
                      secondName: t1Worker.secondName,
                      lastName: t1Worker.lastName,
                      medData: {
                        ДатВрПрМО: formattedDate,
                        НаимМедОрг: exam.Contract.Contractor.legalName,
                        Фамилия: exam.Nurse.lastName,
                        Имя: exam.Nurse.firstName,
                        Отчество: exam.Nurse.patronymic,
                        Ном: "00368102",
                        Сер: "Л041-01137-77",
                        ДатВыд: "26.03.2020",
                        Срок: "18.06.2099",
                      },
                    },
                    cert
                  ).then(() => {
                    isSendingT6Ref.current = false;
                    getT5();

                    const savedExams = JSON.parse(localStorage.getItem("examsT5") || `[]`);
                    const newSavedExams = savedExams.filter((item) => item.id !== exam.id);
                    localStorage.setItem("examsT5", JSON.stringify(newSavedExams));
                  });
                }
              }
            }
          }
        }
      }
    },
    [message, cert, examsT5]
  );

  return <></>;
};
