import React, { FC, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Form, Input, message } from "antd";
const { Item } = Form;
import { CloseOutlined } from "@ant-design/icons";

import { IdmContext } from "@/app/providers/with-idm/idm-context";
import { IAddGroupDialogModalProps, Modals } from "@/shared/types/modals";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { closeModal } from "@/shared/ui/modals/storage";

import { FormButton, StylizedForm } from "./styles";

export const AddGroupDialog: FC<IAddGroupDialogModalProps> = ({ groupId }) => {
  const dispatch = useDispatch();
  const { useAddGroup, useGetGroups } = useContext(IdmContext);
  const [addGroup, { isLoading, isFinised, error }] = useAddGroup();
  const [fetchChildren] = useGetGroups();

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (isFinised) {
      message.success("Группа создана");
      fetchChildren({ parentId: groupId });
      dispatch(closeModal(Modals.AddGroupDialog));
    }
  }, [isFinised]);

  const onFinish = (values) => {
    addGroup({ groupId: groupId, group: values });
  };

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.AddGroupDialog))}>
      <Card
        title="Добавить пользователя в группу"
        style={{ width: "70vw", minWidth: "320px", height: "80vh" }}
        extra={<CloseOutlined onClick={() => dispatch(closeModal(Modals.AddUsersToGroupDialog))} />}
      >
        <StylizedForm name="createGroupForm" onFinish={onFinish}>
          <Item
            label="Название группы"
            name="name"
            style={{ width: 300 }}
            rules={[
              { required: true, message: "Введите имя группы!" },
              {
                max: 256,
                message: "Логин должен содержать не более 256 символов!",
              },
            ]}
          >
            <Input placeholder="Название" />
          </Item>
          <FormButton>
            <Button onClick={() => dispatch(closeModal(Modals.AddGroupDialog))}>Отмена</Button>
            <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit" loading={isLoading}>
              Добавить
            </Button>
          </FormButton>
        </StylizedForm>
      </Card>
    </BoxWithCloseOnClickOutside>
  );
};
