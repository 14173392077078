import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { Card, Collapse, Select, Switch } from "antd";

import { useTheme } from "@/shared/hooks/use-theme";
import { Modals } from "@/shared/types/modals";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { CertificateSelector } from "@/shared/ui/certificate/ui/selector";
import { closeModal } from "@/shared/ui/modals/storage";
import { modeSelector, setMode } from "@/shared/ui/settings/model";

import { options } from "./constants";
import { Item, ItemTitle } from "./styles";

const { Panel } = Collapse;

export const Settings: FC = () => {
  const dispatch = useDispatch();
  const [darkMode, setDarkMode] = useTheme();
  const mode = useSelector(modeSelector);

  const handleClose = () => {
    dispatch(closeModal(Modals.Settings));
  };

  return (
    <BoxWithCloseOnClickOutside close={handleClose}>
      <Card
        title="Настройки"
        style={{ width: "70vw", minWidth: "320px", height: "80vh" }}
        extra={<CloseOutlined onClick={handleClose} />}
      >
        <Collapse defaultActiveKey={[1, 2]}>
          <Panel header="Активность" key="1">
            <Item>
              <ItemTitle>Режим</ItemTitle>
              <Select
                style={{ width: 200 }}
                value={mode}
                onChange={(value) => dispatch(setMode(value))}
                options={options}
              />
            </Item>
            <Item>
              <ItemTitle>Сертификат</ItemTitle>
              <CertificateSelector />
            </Item>
          </Panel>
          <Panel header="Интерфейс" key="2">
            <Item>
              <ItemTitle>Темная тема</ItemTitle>
              <Switch checked={darkMode} onChange={setDarkMode} disabled />
            </Item>
          </Panel>
        </Collapse>
      </Card>
    </BoxWithCloseOnClickOutside>
  );
};
