import React, { FC, useContext, useEffect } from "react";
import { Table } from "antd";

import { TitleContext } from "@/app/providers/ui/with-title";
import { useGetTitles1Query, useGetTitles5Query } from "@/shared/api/titles-astral";
import { WithViewControl } from "@/shared/ui/error-control";

import { examColumns, titles1Columns, titles5Columns } from "./constants";

export const ExamsTable: FC = () => {
  const examsT1 = JSON.parse(localStorage.getItem("examsT1") || "[]");
  const examsT5 = JSON.parse(localStorage.getItem("examsT5") || "[]");

  const { data: titles1 } = useGetTitles1Query();
  const { data: titles5 } = useGetTitles5Query();

  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Мониторинг ЕПД");
  }, []);

  return (
    <>
      <h1 style={{ marginBottom: 20 }}>Предрейсовые осмотры</h1>
      <WithViewControl isEmpty={!examsT1.length} emptyText={() => <p>Осмотры не найдены</p>}>
        <Table
          columns={examColumns}
          dataSource={examsT1}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          scroll={{ x: "max-content" }}
        />
      </WithViewControl>

      <h1 style={{ margin: "40px 0 20px 0" }}>Титул 1</h1>
      <WithViewControl isEmpty={!titles1?.length} emptyText={() => <p>Титулы не найдены</p>}>
        <Table
          columns={titles1Columns}
          dataSource={titles1}
          rowKey="fileName"
          pagination={{ pageSize: 5 }}
          scroll={{ x: "max-content" }}
        />
      </WithViewControl>

      <h1 style={{ margin: "40px 0 20px 0" }}>Послерейсовые осмотры</h1>
      <WithViewControl isEmpty={!examsT5.length} emptyText={() => <p>Осмотры не найдены</p>}>
        <Table
          columns={examColumns}
          dataSource={examsT5}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          scroll={{ x: "max-content" }}
          // onRow={(record) => {
          //   return {
          //     onClick: () => navigate(`${routePaths.exams}/${record.id}`),
          //   };
          // }}
        />
      </WithViewControl>

      <h1 style={{ margin: "40px 0 20px 0" }}>Титул 5</h1>
      <WithViewControl isEmpty={!titles5?.length} emptyText={() => <p>Титулы не найдены</p>}>
        <Table
          columns={titles5Columns}
          dataSource={titles5}
          rowKey="fileName"
          pagination={{ pageSize: 5 }}
          scroll={{ x: "max-content" }}
        />
      </WithViewControl>
    </>
  );
};
