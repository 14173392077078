import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useWindowKeyboardListener } from "@/shared/hooks/dom";
import { GlobalKeyBoardListeners, KeyboardSpecialKeys } from "@/shared/types/globals";
import { is } from "@/shared/types/types-guards";

import { closeActiveModal, currentModalSelector } from "../../modals/storage";

export const useCloseOnEscape = () => {
  const dispatch = useDispatch();
  const { modals } = useSelector(currentModalSelector);

  const closeOnEscape = useCallback(async (event: KeyboardEvent) => {
    if (event.key === KeyboardSpecialKeys.Escape) {
      event.stopPropagation();

      if (!is.empty(modals)) {
        dispatch(closeActiveModal());
      }
    }
  }, []);

  useWindowKeyboardListener(GlobalKeyBoardListeners.ModalCloseOnEsc, closeOnEscape);
};
