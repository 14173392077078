import { createSlice } from "@reduxjs/toolkit";

import { INSPECTION_MODE } from "@/shared/constants/mode";

interface SettingsState {
  mode: INSPECTION_MODE;
  shift: boolean;
  isLoading: boolean;
}

const initialState: SettingsState = {
  mode: INSPECTION_MODE.MANUALLY,
  shift: false,
  isLoading: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setMode: (state, { payload }) => {
      state.mode = payload;
    },
    setShift: (state, { payload }) => {
      state.shift = payload;
    },
    setIsLoadingShift: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

export const { setMode, setShift, setIsLoadingShift } = settingsSlice.actions;

export const modeSelector = (state: RootState) => state.settings.mode;
export const shiftSelector = (state: RootState) => state.settings.shift;
export const isLoadingShiftSelector = (state: RootState) => state.settings.isLoading;
