const isDev = String(process.env.REACT_APP_NODE_ENV) === "DEVELOPMENT";

export const logger = {
  debug: (log) => {
    if (isDev) {
      console.log(log);
    }
  },
  error: (...err) => {
    if (isDev) {
      console.error(...err);
    }
  },
};
