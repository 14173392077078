import React, { ReactNode } from "react";
import { Connector } from "mqtt-react-hooks";

export const withMQTT = (component: () => ReactNode) => () => {
  if (!process.env.REACT_APP_BROKER_URL) {
    return component();
  }

  return (
    <Connector brokerUrl={String(process.env.REACT_APP_BROKER_URL)} parserMethod={(msg) => msg}>
      {component()}
    </Connector>
  );
};
