import { PersonEntity } from "@medregister/domain";
import { createSlice } from "@reduxjs/toolkit";

interface PatientsUploadState {
  patients: PersonEntity[];
}

const initialState: PatientsUploadState = {
  patients: [],
};

export const patientsUploadSlice = createSlice({
  name: "patientsUpload",
  initialState,
  reducers: {
    addPatients: (state, { payload }) => {
      state.patients = [...payload];
    },
    addPatient: (state, { payload }) => {
      state.patients = [...state.patients, payload];
    },
    removePatient: (state, { payload }) => {
      state.patients = state.patients.filter((item) => item.id !== payload);
    },
    editPatient: (state, { payload }) => {
      state.patients = state.patients.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    },
  },
});

export const { editPatient, removePatient, addPatient, addPatients } = patientsUploadSlice.actions;

export const patientsUploadSelector = (state: RootState) => state.patientsUpload.patients;
export const patientUploadSelector = (id: string) => (state: RootState) =>
  state.patientsUpload.patients.find((p) => p.id === id);
