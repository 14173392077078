import { ExamEntity } from "@medregister/domain";
import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryPlatform } from "@/shared/lib";

import { ExamRO } from "./interfaces";

export const examsApi = createApi({
  reducerPath: "examsApi",
  baseQuery: baseQueryPlatform,
  tagTypes: ["Exam"],
  endpoints: (builder) => ({
    createExam: builder.mutation<ExamEntity, Omit<ExamEntity, "id" | "conclusion">>({
      query: (body) => ({
        url: "/exams",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Exam"],
    }),
    conclusionExam: builder.mutation<ExamEntity, { examId: string; conclusion: string }>({
      query: ({ examId, ...body }) => ({
        url: `exams/${examId}/conclusion`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Exam"],
    }),
    getExam: builder.query<ExamRO, ExamEntity["id"]>({
      query: (id) => `exams/${id}`,
    }),
    getExams: builder.query<ExamRO[], void>({
      query: () => `exams`,
    }),
    updateExamData: builder.mutation<ExamEntity, ExamEntity>({
      query: ({ id, ...body }) => ({
        url: `exams/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Exam"],
    }),
  }),
});

export const {
  useGetExamQuery,
  useGetExamsQuery,
  useUpdateExamDataMutation,
  useCreateExamMutation,
  useConclusionExamMutation,
} = examsApi;
