import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Button, Switch } from "antd";

import { authNurseSelector, isLoadingNurseSelector } from "@/entities/session/api";
import { useAuth } from "@/shared/hooks/use-auth";
import { jwtStorage } from "@/shared/lib";
import { Modals } from "@/shared/types/modals";
import { openModalWithProps } from "@/shared/ui/modals/storage";
import { isLoadingShiftSelector, setIsLoadingShift, setShift, shiftSelector } from "@/shared/ui/settings/model";

import {
  HeaderItems,
  Logo,
  StyledSettingOutlined,
  StylizedHeader,
  StylizedWarningFilled,
  SwitchTitle,
  SwitchWrapper,
  WarningWrapper,
  Wrapper,
} from "./styles";

export const Header: FC = () => {
  const dispatch = useDispatch();
  const shift = useSelector(shiftSelector);
  const isLoadingShift = useSelector(isLoadingShiftSelector);
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoadingNurse = useSelector(isLoadingNurseSelector);

  const isMedic = useSelector(authNurseSelector);

  const handleLogout = () => {
    auth.signOutRedirect();
    jwtStorage.updateJwt("");
    localStorage.setItem("refresh_token", "");
  };

  useEffect(() => {
    if (location.pathname !== "/shift") {
      dispatch(setIsLoadingShift(false));
    }
  }, [location]);

  return (
    <StylizedHeader style={{ padding: 0 }}>
      <Wrapper>
        <HeaderItems>
          <SwitchWrapper>
            <Logo src="/icons/logo.png" width="50" />
            {!isLoadingNurse && isMedic && (
              <>
                <SwitchTitle>Открыть смену</SwitchTitle>
                <Switch
                  checked={shift}
                  onClick={() => {
                    if (!shift) {
                      dispatch(setIsLoadingShift(true));
                      navigate("/shift");
                    } else {
                      dispatch(setShift(false));
                    }
                  }}
                  loading={isLoadingShift}
                />
                )<WarningWrapper>{!shift && <StylizedWarningFilled />}</WarningWrapper>
              </>
            )}
          </SwitchWrapper>
        </HeaderItems>
        <HeaderItems>
          <SwitchWrapper>
            <StyledSettingOutlined onClick={() => dispatch(openModalWithProps({ modalName: Modals.Settings }))} />
          </SwitchWrapper>
          <p>{auth.userData?.profile.name}</p>
          <Button type="primary" onClick={handleLogout}>
            {!auth.userData?.profile.name ? "Войти" : "Выйти"}
          </Button>
        </HeaderItems>
      </Wrapper>
    </StylizedHeader>
  );
};
