import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { authNurseSelector, isLoadingNurseSelector } from "@/entities/session/api";

export const ProtectedRouteNurse = ({ children }: { children: JSX.Element }) => {
  const isAuthNurse = useSelector(authNurseSelector);
  const isLoadingNurse = useSelector(isLoadingNurseSelector);
  const navigate = useNavigate();

  useEffect(() => {
    !isLoadingNurse && !isAuthNurse && navigate("/forbidden");
  }, []);

  return children;
};
