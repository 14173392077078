import { toast } from "react-toastify";
import { isFulfilled, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

import { is } from "@/shared/types/types-guards";

enum entities {
  patientsApi = "пациентах",
  terminalsApi = "терминалах",
  subdivisionsApi = "подразделениях",
  companiesApi = "компаниях",
  venuesApi = "точках",
}

enum methods {
  PUT = "изменен",
  DELETE = "удален",
  POST = "создан",
  PATCH = "изменен",
}

export const toastMiddleware: Middleware = () => (next) => (action) => {
  const method = action.meta?.baseQueryMeta?.request?.method;
  const isSearch = !is.empty(action?.meta?.arg?.endpointName.match(/search/gi));
  const process = action.type.split("/")[0];

  if (method === "GET" || isSearch || !entities[process]) {
    return next(action);
  }

  const message = {
    success: `В ${entities[process]} был ${methods[method]} элемент`,
    failed: `Ошибка! В ${entities[process]} не был ${methods[method]}`,
  };

  if (entities[process] === entities.patientsApi) {
    const patientsUploadMessages = {
      multipleUploadPatientsSuccess: `Пациенты был подгружены`,
      multipleUploadPatientFailed: `При массовой загрузке пациентов произошла ошибка`,
    };
    const isUpload = !is.empty(action?.meta?.arg?.endpointName.match(/upload/gi));
    if (isFulfilled(action) && isUpload) {
      toast.success(patientsUploadMessages.multipleUploadPatientsSuccess);
      return next(action);
    }
    if (isRejectedWithValue(action) && isUpload) {
      toast.error(patientsUploadMessages.multipleUploadPatientFailed);
      return next(action);
    }
  }

  if (isRejectedWithValue(action)) {
    toast.error(message.failed);
  }
  if (isFulfilled(action)) {
    toast.success(message.success);
  }

  return next(action);
};
