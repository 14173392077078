import { Menu as AntdMenu } from "antd";
import AntdInput from "antd/es/input/Input";
import styled from "styled-components";

export const Input = styled(AntdInput)``;

export const Menu = styled(AntdMenu)`
  max-height: 69vh;
  overflow-x: scroll;
`;

export const Grid = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 30px;
`;

export const List = styled.div`
  flex-basis: 20%;
`;

export const Body = styled.div`
  display: flex;
  flex-basis: 80%;
  flex-direction: column;
`;

export const BodyHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  gap: 15px;

  p {
    &:nth-last-child(1) {
      font-weight: 700;
    }
  }
`;

export const Screen = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  padding-top: 20px;
`;
