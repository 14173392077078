import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { ButtonSpace, ModalUI } from "@/shared/styles/common";

export const Wrapper = styled.div`
  ${ModalUI}

  flex-direction: column;
  position: relative;
  text-align: center;
  width: 400px;

  ${ButtonSpace}
`;

export const Header = styled.div`
  text-align: left;
`;

export const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const Line = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 20px 0px;
`;

export const Text = styled.p`
  flex-basis: 35%;
`;
