import { Layout, Skeleton } from "antd";
import styled from "styled-components";
const { Sider } = Layout;
import { Button } from "antd";

export const StylizedSider = styled(Sider)`
  height: 100%;
  position: fixed !important;
  z-index: 2;
  /* .ant-layout-sider-children {
    height: auto;
    position: sticky;
    top: 0;
  } */
  @media screen and (max-width: 768px) {
    height: 100%;
    position: fixed !important;
  }
`;

export const StylizedButton = styled(Button)`
  margin: 10px auto 0;
  width: 88%;
`;

export const BurgerBtn = styled(Button)`
  margin: 0 20px;
  width: 40px;
`;

export const StylizedSkeleton = styled(Skeleton)`
  padding: 20px;
`;

export const BurgerWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 0;
`;
