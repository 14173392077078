interface IJwtStorage {
  getJwt: () => string;
  updateJwt: (value: string) => void;
}

export const jwtStorage: IJwtStorage = {
  getJwt: () => {
    if (localStorage.getItem("jwt") === "undefined") {
      return null;
    }
    return JSON.parse(localStorage.getItem("jwt") || "null");
  },
  updateJwt: (value) => localStorage.setItem("jwt", JSON.stringify(value)),
};
