import React, { FC } from "react";
import { Input } from "antd";
import { InputProps } from "antd/es/input";

import { SearchMargin } from "./styles";

const { Search: AntdSearch } = Input;

interface ISearchProps {
  onSearch: (val: string) => void;
  text: string;
  placeholder?: string;
}

export const Search: FC<ISearchProps & InputProps> = ({ onSearch, text, ...args }) => (
  <SearchMargin>
    <AntdSearch placeholder={text} allowClear enterButton="Поиск" onSearch={onSearch} {...args} />
  </SearchMargin>
);
