import AntdInput from "antd/es/input/Input";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Input = styled(AntdInput)`
  flex-basis: 33%;
`;
