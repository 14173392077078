import React, { FC, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, message } from "antd";

import { IdmContext } from "@/app/providers/with-idm/idm-context";
import { IExcludeUsersFromGroupDialogModalProps, Modals } from "@/shared/types/modals";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { ButtonsHorizontal } from "@/shared/ui/css/global";
import { closeModal } from "@/shared/ui/modals/storage";

import { Header, StyledCloseOutlined, Wrapper } from "./styles";

export const ExcludeUsersFromGroupDialog: FC<IExcludeUsersFromGroupDialogModalProps> = ({ groupId, users }) => {
  const dispatch = useDispatch();
  const { useExcludeUsersFromGroup, useGetGroupUsers } = useContext(IdmContext);
  const [excludeUsersFromGroup, { isLoading, error, isFinised }] = useExcludeUsersFromGroup();
  const [getGroupUsers] = useGetGroupUsers();

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (isFinised) {
      message.success("Пользователи исключены из группы");
      getGroupUsers({ groupId: groupId });
      dispatch(closeModal(Modals.ExcludeUsersFromGroupDialog));
    }
  }, [isFinised]);

  const handleAddUsersToGroup = async () => await excludeUsersFromGroup({ groupId: groupId, users: users });

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.ExcludeUsersFromGroupDialog))}>
      <Wrapper className="widget">
        <StyledCloseOutlined onClick={() => dispatch(closeModal(Modals.ExcludeUsersFromGroupDialog))} />
        <Header>Исключить пользователя/пользователей из группы?</Header>
        <ButtonsHorizontal>
          <Button type="primary" onClick={handleAddUsersToGroup} loading={isLoading}>
            Да
          </Button>
          <Button onClick={() => dispatch(closeModal(Modals.ExcludeUsersFromGroupDialog))}>Нет</Button>
        </ButtonsHorizontal>
      </Wrapper>
    </BoxWithCloseOnClickOutside>
  );
};
