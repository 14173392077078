import dayjs from "dayjs";

import { DateFormats } from "@/shared/types/globals";

import { Title2 } from "../types/title2-type";

export const generateTitle2 = (data) => {
  const { title1, ИдФайл, signature, ourMedData } = data;

  const title2: Title2 = {
    _declaration: { _attributes: { version: "1.0", encoding: "windows-1251" } },
    Файл: {
      _attributes: {
        ИдФайл: ИдФайл,
        ВерсПрог: "Medregister 1.0",
        ВерсФорм: "5.01",
      },
      Документ: {
        _attributes: {
          ВрИнфМО: dayjs().locale("ru").format(DateFormats.Time),
          ДатИнфМО: dayjs().locale("ru").format(DateFormats.UserFriendly),
          КНД: "1110381",
        },
        ИдИнфСоб: {
          _attributes: {
            ИдФайлИнфСоб: title1.Файл._attributes.ИдФайл,
            ДатФайлИнфСоб: title1.Файл.Документ._attributes.ДатИнфСоб,
            ВрФайлИнфСоб: title1.Файл.Документ._attributes.ВрИнфСоб,
            ЭП: signature,
          },
        },
        СодИнфМО: {
          _attributes: {
            ВидМО: "2",
            УИД_ПЛ: title1.Файл.Документ.СодИнфСоб._attributes.УИД_ПЛ,
          },
          СвМедОрг: {
            _attributes: {
              НаимМедОрг: ourMedData.НаимМедОрг,
            },
            ФИО: {
              _attributes: {
                Фамилия: ourMedData.Фамилия,
                Имя: ourMedData.Имя,
                Отчество: ourMedData.Отчество,
              },
            },
            ЛицензМО: {
              _attributes: {
                ДатВыд: ourMedData.ДатВыд,
                Ном: ourMedData.Ном,
                Сер: ourMedData.Сер,
                Срок: ourMedData.Срок,
              },
            },
          },
          СвМОПред: {
            _attributes: {
              ДатВрПрМО: ourMedData.ДатВрПрМО,
              НалКоорТочВрПрМО: "1",
              ОтметМОПред: "Прошел предсменный медицинский осмотр, к исполнению трудовых обязанностей допущен",
            },
            СвВодит: {
              _attributes: {
                ИННФЛ:
                  title1.Файл.Документ.СодИнфСоб.СвВодит._attributes &&
                  title1.Файл.Документ.СодИнфСоб.СвВодит._attributes.ИННФЛ,
              },
              ВодитУд: {
                _attributes: {
                  НомВУ: title1.Файл.Документ.СодИнфСоб.СвВодит.ВодитУд._attributes.НомВУ,
                  СерВУ: title1.Файл.Документ.СодИнфСоб.СвВодит.ВодитУд._attributes.СерВУ,
                  ДатаВыдВУ: title1.Файл.Документ.СодИнфСоб.СвВодит.ВодитУд._attributes.ДатаВыдВУ,
                },
              },
              ФИО: title1.Файл.Документ.СодИнфСоб.СвВодит.ФИО,
            },
          },
        },
        ПодпИнфМО: {
          _attributes: {
            СпосПодтПолном: "3",
            ТипПодпис: "1",
          },
          ФИО: {
            _attributes: {
              Фамилия: ourMedData.Фамилия,
              Имя: ourMedData.Имя,
              Отчество: ourMedData.Отчество,
            },
          },
          СвДоверЭл: {
            НомДовер: "441d9a57-7fed-4882-b6f2-104b9197ad4f",
            ДатаДовер: "2024-09-26",
            ИдСистХран: "https://m4d.nalog.gov.ru/emchd/check-status?guid=441d9a57-7fed-4882-b6f2-104b9197ad4f",
          },
        },
      },
    },
  };

  return title2;
};
