import React, { FC, useContext, useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";
import styled from "styled-components";

import { TitleContext } from "@/app/providers/ui/with-title";
import { ErrorBoundaryFallback } from "@/shared/ui/error-boundary";
import { WithViewControl } from "@/shared/ui/error-control";

const Main: FC = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Медрегистр");
  }, []);

  return (
    <WithViewControl>
      <Wrapper>
        <Title>Добро пожаловать</Title>
        <SubTitle>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste error porro ab placeat alias, repellendus
          aperiam! Ipsum doloribus dolore cum repudiandae non consectetur vero perferendis, odio architecto natus
          praesentium et.
        </SubTitle>
      </Wrapper>
    </WithViewControl>
  );
};

export const MainPage = withErrorBoundary(Main, {
  FallbackComponent: ErrorBoundaryFallback,
});

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
`;

export const SubTitle = styled.p`
  font-size: 20px;
  max-width: 600px;
`;
