import React, { FC, useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Table } from "antd";

import { IdmContext } from "@/app/providers/with-idm/idm-context";
import { IAddGroupsDialogModalProps, Modals } from "@/shared/types/modals";
import { Message, Search, WithViewControl } from "@/shared/ui";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { ButtonsHorizontal } from "@/shared/ui/css/global";
import { closeModal } from "@/shared/ui/modals/storage";

import { groupsColumns } from "./constants";
import { Wrapper } from "./styles";

export const AddGroupsDialog: FC<IAddGroupsDialogModalProps> = ({ setSelectedGroups }) => {
  const { useGetGroups } = useContext(IdmContext);
  const [, { groups, isLoading }] = useGetGroups();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [newSelectedGroups, setNewSelectedGroups] = useState<{ id: string; path: string }[]>([]);

  const handleSearch = (event) => setSearchText(event.target.value);

  const filteredGroups = useMemo(() => {
    return groups?.filter((group) => {
      return group.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [groups, searchText]);

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.AddGroupsDialog))}>
      <Card
        title="Добавить пользователя в группу"
        style={{ width: "70vw", minWidth: "320px", height: "80vh" }}
        extra={<CloseOutlined onClick={() => dispatch(closeModal(Modals.AddUsersToGroupDialog))} />}
      >
        <Wrapper className="widget">
          <WithViewControl emptyText={() => <Message text="Группы не найдены" />}>
            <Search text="Поиск группы..." onSearch={handleSearch} />
            <Table
              columns={groupsColumns({
                newSelectedGroups,
                setNewSelectedGroups,
              })}
              dataSource={filteredGroups}
              rowKey="id"
              pagination={{ pageSize: 10 }}
              scroll={{ x: "max-content" }}
              loading={isLoading}
            />
          </WithViewControl>
          <ButtonsHorizontal>
            <Button
              type="primary"
              onClick={() => {
                setSelectedGroups(newSelectedGroups);
                dispatch(closeModal(Modals.AddGroupsDialog));
              }}
            >
              Включить в группу
            </Button>
            <Button onClick={() => dispatch(closeModal(Modals.AddGroupsDialog))}>Отмена</Button>
          </ButtonsHorizontal>
        </Wrapper>
      </Card>
    </BoxWithCloseOnClickOutside>
  );
};
