import React, { FC, useEffect, useMemo, useState } from "react";
import { Table } from "antd";

import { useGetSubdivisionsQuery } from "@/entities/subdivisions/api";
import { SubdivisionRO } from "@/entities/subdivisions/interfaces";
import { is } from "@/shared/types/types-guards";
import { Message } from "@/shared/ui";
import { WithViewControl } from "@/shared/ui/error-control";

import { subdivisionColumns } from "./constants";

interface ISubdivisionsTableProps {
  filteredSubdivisions?: SubdivisionRO[];
}

export const SubdivisionsTable: FC<ISubdivisionsTableProps> = ({ filteredSubdivisions }) => {
  const { data: startedSubdivisions, isLoading, error } = useGetSubdivisionsQuery();
  const [filtered, setFiltered] = useState<SubdivisionRO[] | null>(null);

  const subdivisions = useMemo(() => filtered || startedSubdivisions, [filtered, startedSubdivisions]);

  useEffect(() => {
    setFiltered(null);
  }, [startedSubdivisions]);

  useEffect(() => {
    filteredSubdivisions && setFiltered(filteredSubdivisions);
  }, [filteredSubdivisions]);

  return (
    <WithViewControl
      isLoading={isLoading}
      isEmpty={is.empty(subdivisions)}
      error={error}
      emptyText={() => <Message text="Подразделения не найдены" />}
    >
      <Table
        columns={subdivisionColumns}
        dataSource={subdivisions}
        rowKey="id"
        pagination={{ pageSize: 5 }}
        scroll={{ x: "max-content" }}
      />
    </WithViewControl>
  );
};
