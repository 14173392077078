import { PersonEntity } from "@medregister/domain";

export const patientsStorage = {
  getPatients: () => JSON.parse(localStorage.getItem("patients") || "[]"),
  updatePatients: (value) => localStorage.setItem("patients", JSON.stringify(value)),
};

export const formaterXLSXObject = (obj) => {
  return Object.keys(obj).reduce((acc, cur) => {
    switch (cur) {
      case "Имя":
        acc.firstName = obj[cur];
        break;
      case "Фамилия":
        acc.lastName = obj[cur];
        break;
      case "Отчество":
        acc.middleName = obj[cur];
        break;
      case "Пол":
        acc.gender = obj[cur];
        break;
      case "КПП":
        acc.insuranceNumber = obj[cur];
        break;
      case "ИНН":
        acc.itn = obj[cur];
        break;
      case "Дата рождения":
        acc.dateOfBirth = obj[cur];
        break;
    }
    return acc;
  }, {} as PersonEntity);
};
