import React from "react";
import { Result } from "antd";

import { ServerStopped } from "@/pages/errors/server-stopped";
import { UnAuth } from "@/pages/errors/unauth";
import { Errors } from "@/shared/constants";

export interface IErrorFallbackProps {
  error: { message: string };
  resetErrorBoundary: () => void;
}

export const ErrorBoundaryFallback = ({ error }: IErrorFallbackProps) => {
  const errorData = JSON.parse(error.message || "{}");

  if (errorData?.status === Errors.Unauth) {
    return <UnAuth />;
  }

  if (errorData?.status === Errors.ServerNotAvailable) {
    return <ServerStopped />;
  }

  return <Result status={errorData?.status} title={errorData?.status} subTitle={errorData?.message} />;
};
