import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { Mutex } from "async-mutex";

import { diadocStorage } from "./diadoc-storage";

const bq = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_DIADOC_URL,
  prepareHeaders: (headers) => {
    const ddauth_token = diadocStorage.getToken();

    headers.set("Content-Type", "application/json; charset=utf-8");

    if (ddauth_token) {
      headers.set(
        "Authorization",
        `DiadocAuth ddauth_api_client_id=${process.env.REACT_APP_DIADOC_CLIENT_ID}, ddauth_token=${ddauth_token}`
      );
    }

    return headers;
  },
});

const mutex = new Mutex();

export const baseDiadocQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await bq(args, api, extraOptions);
  const ddauth_token = diadocStorage.getToken();

  // !FIX 401
  if (!ddauth_token || result.error || !mutex.isLocked()) {
    const release = await mutex.acquire();

    try {
      const refreshResult = await bq(
        {
          url: "/V3/Authenticate?type=password",
          method: "POST",
          headers: {
            Authorization: `DiadocAuth ddauth_api_client_id=${process.env.REACT_APP_DIADOC_CLIENT_ID}`,
          },
          body: {
            login: process.env.REACT_APP_DIADOC_LOGIN,
            password: process.env.REACT_APP_DIADOC_PASSWORD,
          },
          responseHandler: "text",
        },
        api,
        extraOptions
      );

      if (refreshResult.data) {
        diadocStorage.updateToken(refreshResult.data);
      }

      result = await bq(args, api, extraOptions);
    } finally {
      release();
    }
  } else {
    await mutex.waitForUnlock();
    result = await bq(args, api, extraOptions);
  }

  return result;
};
