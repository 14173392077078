type IdmErrorHanlder = ({
  data,
  setError,
}: {
  data: any;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
}) => any;

export const IdmErrorHanlder: IdmErrorHanlder = ({ data, setError }) => {
  if (data && data?.message === "Forbidden") {
    data.message = "Доступ запрещён";
  }
  if (data?.error) {
    const isDev = String(process.env.REACT_APP_NODE_ENV) === "DEVELOPMENT";

    if (isDev) {
      setError(Array.isArray(data.message) ? data.message[0] : data.message);
    } else {
      setError(Array.isArray(data.error) ? data.error[0] : data.error);
    }
  } else {
    if (data?.message) {
      setError(Array.isArray(data.message) ? data.message[0] : data.message);
    }
  }
};
