/* eslint-disable @typescript-eslint/no-empty-interface */
import { TerminalEntity } from "@medregister/domain";

import { ExamRO } from "@/entities/exams/interfaces";

import { IAnyObject } from "../types/globals";

export enum Modals {
  VenuesDialog = "VenuesDialog",
  PatientsDialog = "PatientsDialog",
  TerminalsDialog = "TerminalsDialog",
  SubdivisionsDialog = "SubdivisionsDialog",
  CompaniesDialog = "CompaniesDialog",
  Settings = "Settings",
  PatientsUploadDialog = "PatientsUploadDialog",
  ExamDialog = "ExamDialog",
  DeleteFilesDialog = "DeleteFilesDialog",
  UploadFilesDialog = "UploadFilesDialog",
  MoveFilesDialog = "MoveFilesDialog",
  AddGroupsDialog = "AddGroupsDialog",
  RenameGroupDialog = "RenameGroupDialog",
  DeleteGroupsDialog = "DeleteGroupsDialog",
  AddUsersToGroupDialog = "AddUsersToGroupDialog",
  AddGroupDialog = "AddGroupDialog",
  ExcludeUsersFromGroupDialog = "ExcludeUsersFromGroupDialog",
}
export interface ICustomModalProps extends Record<Modals, IAnyObject> {
  [Modals.VenuesDialog]: IVenuesDialogModalProps;
  [Modals.PatientsDialog]: IPatientsDialogModalProps;
  [Modals.SubdivisionsDialog]: ISubdivisionsDialogModalProps;
  [Modals.CompaniesDialog]: ICompaniesDialogModalProps;
  [Modals.Settings]: ISettingsModalProps;
  [Modals.PatientsUploadDialog]: IPatientsUploadDialogModalProps;
  [Modals.ExamDialog]: IExamDialogModalProps;
}

export interface IModalProps {
  modalMode: ModalMode;
  id: string;
}

export interface IVenuesDialogModalProps extends IModalProps {}
export interface IPatientsDialogModalProps extends IModalProps {}
export interface ISubdivisionsDialogModalProps extends IModalProps {}
export interface ICompaniesDialogModalProps extends IModalProps {}
export interface IDeleteFilesDialogModalProps extends IModalProps {
  bucket: string;
  fileNames: string[];
  refetchTrigger: () => void;
}
export interface IUploadFilesDialogModalProps extends IModalProps {
  bucket: string;
  refetchTrigger: () => void;
}
export interface IMoveFilesDialogModalProps extends IModalProps {
  sourceBucket: string;
  fileNames: string[];
  refetchTrigger: () => void;
}
export interface IAddGroupsDialogModalProps extends IModalProps {
  selectedGroups: { id: string; path: string }[];
  setSelectedGroups: React.Dispatch<React.SetStateAction<{ id: string; path: string }[]>>;
}

export interface IRenameGroupDialogModalProps extends IModalProps {
  groupId: string;
  name: string;
}

export interface IAddUsersToGroupDialogModalProps extends IModalProps {
  groupId: string;
}

export interface IExcludeUsersFromGroupDialogModalProps extends IModalProps {
  users: string[];
  groupId: string;
}

export interface IDeleteGroupsDialogModalProps extends IModalProps {
  groupsId: string[];
  parentId: string;
}

export interface IAddGroupDialogModalProps extends IModalProps {
  groupId: string;
}

export interface ITerminalsDialogModalProps {
  terminalCn: TerminalEntity["terminalCn"];
  modalMode: ModalMode;
}

export interface IExamDialogModalProps {
  exam: ExamRO;
}

export interface IPatientsUploadDialogModalProps {}
export interface ISettingsModalProps {}

export enum ModalMode {
  CREATE = "create",
  EDIT = "edit",
}
