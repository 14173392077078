import React, { FC } from "react";
import { useParams } from "react-router";
import { CONCLUSION, ExamEntity } from "@medregister/domain";
import { Button } from "antd";
import styled from "styled-components";

import { useConclusionExamMutation, useGetExamQuery } from "@/entities/exams/api";
import { ButtonsHorizontal } from "@/shared/ui/css";

export const Exam: FC = () => {
  const { id } = useParams<{ id: ExamEntity["id"] }>();
  const { data: exam } = useGetExamQuery(id!);

  const [updateConclusionExam] = useConclusionExamMutation();

  if (!exam) {
    return null;
  }

  const handleConclusion = (conclusion) => {
    updateConclusionExam({ conclusion, examId: exam.id });
  };

  return (
    <Root>
      <Title>Информация об осмотре № {id?.slice(-4)}</Title>
      <ButtonsHorizontal>
        <Button onClick={() => handleConclusion(CONCLUSION.PASSED)}>Допустить</Button>
        <Button onClick={() => handleConclusion(CONCLUSION.FAILED)}>Не допустить</Button>
      </ButtonsHorizontal>
    </Root>
  );
};

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 45px;
`;

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 100%;
`;
