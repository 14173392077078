import React, { FC } from "react";

import { CenteredBlock, Error } from "./styles";

export interface MessageProps {
  text: string;
}

export const Message: FC<MessageProps> = ({ text }) => (
  <CenteredBlock>
    <Error>{text}</Error>
  </CenteredBlock>
);
