import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, message } from "antd";

import { MinioContext } from "@/app/providers/with-minio/minio-context";
import { IDeleteFilesDialogModalProps, Modals } from "@/shared/types/modals";
import { Loader } from "@/shared/ui";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { ButtonsHorizontal } from "@/shared/ui/css/global";
import { closeModal } from "@/shared/ui/modals/storage";

import { Header, StyledCloseOutlined, Wrapper } from "./styles";

export const DeleteFilesDialog: FC<IDeleteFilesDialogModalProps> = ({ bucket, fileNames, refetchTrigger }) => {
  const { useMinio } = useContext(MinioContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, , , removeFiles] = useMinio();
  const dispatch = useDispatch();

  const removeHandler = async () => {
    setIsLoading(true);
    const result = await removeFiles({ bucket: bucket, files: fileNames });
    result ? message.success("Файлы удалены") : message.error("Ошибка при удалении файлов");
    refetchTrigger();
    dispatch(closeModal(Modals.DeleteFilesDialog));
  };

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.DeleteFilesDialog))}>
      <Wrapper className="widget">
        <StyledCloseOutlined onClick={() => dispatch(closeModal(Modals.DeleteFilesDialog))} />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Header>
              {fileNames.length === 1 ? (
                <h2>Вы уверены, что хотите удалить этот файл?</h2>
              ) : (
                <h2>Вы уверены, что хотите удалить эти файлы</h2>
              )}
            </Header>
            <ButtonsHorizontal>
              <Button onClick={removeHandler}>Да</Button>
              <Button onClick={() => dispatch(closeModal(Modals.DeleteFilesDialog))}>Нет</Button>
            </ButtonsHorizontal>
          </>
        )}
      </Wrapper>
    </BoxWithCloseOnClickOutside>
  );
};
