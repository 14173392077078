import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { WarningFilled } from "@ant-design/icons";
import { Button, Empty, Result, Table } from "antd";

import { TitleContext } from "@/app/providers/ui/with-title";
import { MinioContext } from "@/app/providers/with-minio/minio-context";
import { Modals } from "@/shared/types/modals";
import { Loader, Search } from "@/shared/ui";
import { CenteringWrapper } from "@/shared/ui/css";
import { openModalWithProps } from "@/shared/ui/modals/storage";

import { Filters, Left, Right, StyledSelect } from "./styles";
import { genFilesColumns } from "./utils";

export const FilesPage: FC = () => {
  const dispatch = useDispatch();
  const [selectFiles, setSelectFiles] = useState<string[]>([]);
  const { setTitle } = useContext(TitleContext);
  const { useMinio } = useContext(MinioContext);

  const [
    setSearchParams,
    getBuckets,
    loadMoreFiles,
    ,
    ,
    ,
    { files, buckets, bucket, isLoadingFiles, isLoadingMoreFiles, isLoadingBuckets, bucketsError },
  ] = useMinio();
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const options = buckets?.map((e) => ({ value: e.name, label: e.name })) || [];

  useEffect(() => {
    if (!loadMoreRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMoreFiles();
        }
      });
    });
    observer.observe(loadMoreRef.current);
    return () => {
      observer.disconnect();
    };
  }, [loadMoreFiles]);

  const tableControl = {
    loading: <Loader />,
    unselect: <Result title="Корзина не выбрана" />,
    success: (
      <>
        <Table
          locale={{ emptyText: <Empty description="В корзине нет файлов" /> }}
          rowKey="name"
          columns={genFilesColumns({
            selectFiles,
            setSelectFiles,
            fileNames: files.map((file) => file.name),
          })}
          dataSource={files}
          pagination={false}
        />
        <div ref={loadMoreRef}></div>
        <CenteringWrapper style={!isLoadingMoreFiles ? { display: "none" } : {}}>
          <Loader />
        </CenteringWrapper>
      </>
    ),
  };

  const refetch = () => {
    setSelectFiles([]);
    setSearchParams((prev) => ({ ...prev, bucket: bucket }));
  };

  const handleDelete = () => {
    dispatch(
      openModalWithProps({
        modalName: Modals.DeleteFilesDialog,
        props: {
          bucket: bucket,
          fileNames: selectFiles,
          refetchTrigger: refetch,
        },
      })
    );
  };

  const handleMove = () => {
    dispatch(
      openModalWithProps({
        modalName: Modals.MoveFilesDialog,
        props: {
          sourceBucket: bucket,
          fileNames: selectFiles,
          refetchTrigger: refetch,
        },
      })
    );
  };

  const handleUpload = () => {
    dispatch(
      openModalWithProps({
        modalName: Modals.UploadFilesDialog,
        props: { bucket: bucket, refetchTrigger: refetch },
      })
    );
  };

  useEffect(() => {
    setTitle("Файлы");
  }, []);

  return (
    <>
      <Search
        onSearch={(e) => {
          setSearchParams((prev) => ({ ...prev, prefix: e }));
          setSelectFiles([]);
        }}
        text="Введите название файла..."
        disabled={!bucket}
      />
      <Filters>
        <Left>
          <StyledSelect
            placeholder="Выбрать корзину"
            style={{ width: 400 }}
            options={options}
            dropdownRender={(value) =>
              !isLoadingBuckets ? (
                value
              ) : (
                <CenteringWrapper>
                  <Loader />
                </CenteringWrapper>
              )
            }
            onClick={() => !buckets.length && getBuckets()}
            onChange={(value) => {
              setSelectFiles([]);
              setSearchParams((prev) => ({ ...prev, bucket: value }));
            }}
          />
          {bucketsError && (
            <>
              <WarningFilled /> {bucketsError}
            </>
          )}
        </Left>
        <Right>
          <Button disabled={!bucket} onClick={handleUpload}>
            Загрузить
          </Button>
          <Button disabled={!selectFiles.length} onClick={handleMove}>
            Переместить
          </Button>
          <Button disabled={!selectFiles.length} onClick={handleDelete}>
            Удалить
          </Button>
        </Right>
      </Filters>
      {!bucket ? tableControl["unselect"] : isLoadingFiles ? tableControl["loading"] : tableControl["success"]}
    </>
  );
};
