import React from "react";
import { Checkbox } from "antd";
import { ColumnsType } from "antd/es/table";

import { UserData } from "@/app/providers/with-idm/types";

interface ColumnsProps {
  selectedUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  withGroups?: boolean;
}

export const usersColumns: (props: ColumnsProps) => ColumnsType<UserData> = ({
  selectedUsers,
  setSelectedUsers,
  withGroups,
}) => {
  const columns = [
    {
      dataIndex: "user",
      key: "name",
      render: (value) => {
        return (
          <Checkbox
            checked={selectedUsers.includes(value.id)}
            onChange={() =>
              selectedUsers.includes(value.id)
                ? setSelectedUsers((prev) => prev.filter((item) => item !== value.id))
                : setSelectedUsers((prev) => [...prev, value.id])
            }
          />
        );
      },
    },
    {
      title: "Логин",
      dataIndex: "user",
      key: "username",
      render: (value) => <p>{value.username}</p>,
    },
    {
      title: "Роль",
      dataIndex: "user",
      key: "role",
      render: (value) => value.attributes?.policy.map((role, index) => <p key={index}>{role}</p>),
    },
    {
      title: "Фамилия",
      dataIndex: "user",
      key: "lastname",
      render: (value) => <p>{value.lastName}</p>,
    },
    {
      title: "Имя",
      dataIndex: "user",
      key: "firstname",
      render: (value) => <p>{value.firstName}</p>,
    },
  ];

  if (withGroups) {
    columns.push({
      title: "Группы",
      dataIndex: "groups",
      key: "groups",
      render: (value) => value.map((group, index) => <span key={index}>{group.path} </span>),
    });
  }

  return columns;
};
