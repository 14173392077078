import { Button, Form } from "antd";
import styled from "styled-components";

export const StylizedForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const StylizedButton = styled(Button)`
  margin-left: 10px;
`;

export const FormButton = styled(Form.Item)`
  display: flex;
  justify-content: end;
  margin-bottom: 50px;
  margin-top: auto;
`;
