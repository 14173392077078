import React, { FC, useEffect, useMemo, useState } from "react";
import { PersonEntity } from "@medregister/domain";
import { Table } from "antd";
import { TablePaginationConfig } from "antd/es/table";

import { useLazyGetPatientsQuery } from "@/entities/patients/api";
import { TablePagination } from "@/entities/patients/constants";
import { is } from "@/shared/types/types-guards";
import { Message } from "@/shared/ui";
import { WithViewControl } from "@/shared/ui/error-control";

import { personColumns } from "./constants";

interface IPatientsTableProps {
  filteredPatients?: PersonEntity[];
  filteredPatientsCount?: number;
  setFilteredOffset: React.Dispatch<React.SetStateAction<number>>;
  isFetchingFiltered: boolean;
}

interface TableParams {
  pagination?: TablePaginationConfig;
}

export const PatientsTable: FC<IPatientsTableProps> = ({
  filteredPatients,
  filteredPatientsCount,
  setFilteredOffset,
  isFetchingFiltered,
}) => {
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: TablePagination.currentPage + 1,
      pageSize: TablePagination.pageSize,
    },
  });

  const [getPatients, { data: startedPatients, isLoading, error }] = useLazyGetPatientsQuery();

  useEffect(() => {
    if (!filteredPatients && !isFetchingFiltered)
      getPatients(tableParams.pagination?.current || TablePagination.currentPage);
  }, [tableParams.pagination]);

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: filteredPatients
          ? filteredPatientsCount
          : !isFetchingFiltered
          ? startedPatients?.length
          : // : ? startedPatients?.count
            tableParams.pagination?.total,
      },
    });
  }, [startedPatients, filteredPatients]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });
    if (pagination.current && filteredPatients) {
      setFilteredOffset(pagination.current);
    }
  };

  const patients = useMemo(
    () => (filteredPatients ? filteredPatients : !isFetchingFiltered ? startedPatients : undefined),
    [filteredPatients, startedPatients]
  );

  return (
    <WithViewControl
      isLoading={isLoading || isFetchingFiltered}
      error={error}
      isEmpty={is.empty(patients)}
      emptyText={() => <Message text="Пользователи не найдены" />}
    >
      <Table
        columns={personColumns}
        dataSource={patients}
        rowKey="id"
        pagination={tableParams.pagination}
        loading={isLoading || isFetchingFiltered}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
      />
    </WithViewControl>
  );
};
