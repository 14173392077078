import { useEffect, useState } from "react";
import { message } from "antd";
import { createSignature } from "crypto-pro";
import iconv from "iconv-lite";
import { js2xml } from "xml-js";

import { useDeleteTitles1Mutation, useGetTitles1Query, usePutTitles1Mutation } from "@/shared/api/titles-astral";

import { generateTitle2 } from "../generators/generate-title2";
import { arrayBufferToBase64, generateTitleFilename } from "../libs";
import { ISendT2AstralParams } from "../types/types";

import { usePostEventsConfirmMutation, usePostEventsMutation, useSendDocumentMutation } from "./api/astral-api";

export const useLazySendTitle2Astral = () => {
  const [sendDocument, { data: sendDoc, isLoading }] = useSendDocumentMutation();
  const [postEvents, { isSuccess: isSuccessPostEvents }] = usePostEventsMutation();
  const [postEventsConfirm] = usePostEventsConfirmMutation();

  const [deleteTitles1] = useDeleteTitles1Mutation();
  const [putTitles1] = usePutTitles1Mutation();

  const { data: titles1Astral } = useGetTitles1Query();

  const [error, setError] = useState<Error>();
  const [isSuccess, setIsSuccess] = useState(false);

  const [params, setParams] = useState<ISendT2AstralParams>();

  const sendTitle2 = async (params: ISendT2AstralParams, thumbprint) => {
    try {
      const title2FileName = generateTitleFilename(params.fileName);
      const title2Content = generateTitle2({
        title1: params.title1,
        ИдФайл: title2FileName,
        signature: params.t1Signa,
        ourMedData: params.medData,
      });

      setParams(params);

      const title2Xml = js2xml(title2Content, { compact: true });
      const byteArrayWin1251 = iconv.encode(title2Xml, "win1251");
      const blobT2 = new Blob([byteArrayWin1251], {
        type: "application/xml;charset=windows-1251",
      });
      const base64 = arrayBufferToBase64(byteArrayWin1251);

      const signature = await createSignature(thumbprint, base64);

      const data = await fetch(`data:application/octet-stream;base64,${signature}`);

      await sendDocument({
        file: blobT2,
        signature: await data.blob(),
        fileName: title2FileName,
      });
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      }
    }
  };

  useEffect(() => {
    if (sendDoc && params) {
      setIsSuccess(sendDoc?.status === 200);

      const docId = sendDoc.files.filter((file) => file.mime === "application/xml;charset=windows-1251")[0].id;
      const signaId = sendDoc.files.filter((file) => file.mime === "application/octet-stream")[0].id;

      postEvents({
        docId: docId,
        signaId: signaId,
        eventId: params.eventId,
        ИдПолучателя: params.ИдПолучателя,
        ИдОтправителя: params.ИдОтправителя,
        ТипОтправителя: params.ТипОтправителя,
        Tn: "Т2",
      });
    }
  }, [sendDoc]);

  useEffect(() => {
    if (isSuccessPostEvents && titles1Astral) {
      message.success("Отправлен Т2");
      if (params) {
        if (!params?.needT6) {
          deleteTitles1([params.eventId]);
          postEventsConfirm({ eventsId: [params?.eventId] });
        } else {
          putTitles1([params.eventId]);
          postEventsConfirm({ eventsId: [params?.eventId] });
        }
      }

      const savedExams = JSON.parse(localStorage.getItem("examsT1") || `[]`);
      const newSavedExams = savedExams.filter((exam) => exam.id !== params?.examId);
      localStorage.setItem("examsT1", JSON.stringify(newSavedExams));
    }
  }, [isSuccessPostEvents, titles1Astral]);

  return { sendTitle2, error, isLoading, isSuccess };
};
