import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { PersonEntity } from "@medregister/domain";
import { Button } from "antd";

import { useDeletePatientMutation } from "@/entities/patients/api";
import { removePatient } from "@/entities/patients/storage";
import { ModalMode, Modals } from "@/shared/types/modals";
import { ButtonsVertical } from "@/shared/ui/css";
import { openModalWithProps } from "@/shared/ui/modals/storage";

interface IPatientHandlerProps {
  patientId: PersonEntity["id"];
}

interface IPatientUploadHandlerProps {
  patientId: PersonEntity["id"];
}

export const PatientUploadHandler: FC<IPatientUploadHandlerProps> = ({ patientId }) => {
  const dispatch = useDispatch();
  const handleDelete = (patientId: string) => {
    dispatch(removePatient(patientId));
  };

  const handleEdit = (id: string) => {
    dispatch(
      openModalWithProps({
        modalName: Modals.PatientsDialog,
        props: { modalMode: ModalMode.EDIT, id },
      })
    );
  };

  return (
    <ButtonsVertical>
      <Button onClick={() => handleEdit(patientId)}>Изменить</Button>
      <Button onClick={() => handleDelete(patientId)} type="primary">
        Удалить
      </Button>
    </ButtonsVertical>
  );
};

export const PatientHandler: FC<IPatientHandlerProps> = ({ patientId }) => {
  const dispatch = useDispatch();
  const [deletePatient] = useDeletePatientMutation();

  const handleEdit = (id: string) => {
    dispatch(
      openModalWithProps({
        modalName: Modals.PatientsDialog,
        props: { modalMode: ModalMode.EDIT, id },
      })
    );
  };

  return (
    <ButtonsVertical>
      <Button onClick={() => handleEdit(patientId)}>Изменить</Button>
      <Button onClick={() => deletePatient(patientId)} type="primary">
        Удалить
      </Button>
    </ButtonsVertical>
  );
};
