import styled from "styled-components";

export const CenteredBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 100px;
`;

export const Error = styled.h1`
  font-size: 24px;
  margin: 0 0 0 10px;
`;
