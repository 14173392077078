import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { useKeyPress } from "@/shared/hooks/use-key-press";

import { TitleContext } from "./with-title";

type Tab = {
  label: string;
  key: string;
};

// type TabsContextProps = Tab[];

const TabsContext = createContext<any>(null);

const TabsProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const [tabs, setTabs] = useState<Tab[]>([]);
  const [activeKey, setActiveKey] = useState(window.location.pathname);

  const jPressed = useKeyPress("q");
  const ctrlPressed = useKeyPress("Control");
  const { title } = useContext(TitleContext);

  useEffect(() => {
    if (title != "Медрегистр") {
      const isHistoryInclude = tabs.find((item) => item.key === window.location.pathname);
      if (!isHistoryInclude) {
        title && setTabs([...tabs, { label: title, key: window.location.pathname }]);
      }
      setActiveKey(window.location.pathname);
    }
  }, [title]);

  useEffect(() => {
    if (jPressed && ctrlPressed) {
      setTabs([]);
      sessionStorage.setItem("tabs", "[]");
      navigate("/");
    }
  }, [jPressed, ctrlPressed]);

  useEffect(() => {
    if (tabs.length) {
      sessionStorage.setItem("tabs", JSON.stringify(tabs));
    }
  }, [tabs]);

  useEffect(() => {
    const tabs = sessionStorage.getItem("tabs");
    setTabs(JSON.parse(tabs || "[]"));
  }, []);

  const onEdit = (targetKey) => {
    setTabs((prev) =>
      prev.filter((tab, index) => {
        const isKeyToDelete = tab.key === targetKey;

        if (isKeyToDelete && targetKey === activeKey) {
          if (index === 0) {
            if (tabs[index + 1]) {
              setActiveKey(prev[index + 1].key);
              navigate(prev[index + 1].key);
            } else {
              navigate("/");
            }
          } else {
            setActiveKey(prev[index - 1].key);
            navigate(prev[index - 1].key);
          }
        }

        return !isKeyToDelete;
      })
    );
  };

  const onCancelTab = () => {
    setTabs((prev) => prev.filter((e) => e.key !== window.location.pathname));
  };

  const onChange = (key) => {
    setActiveKey(key);
    navigate(key);
  };

  return (
    <TabsContext.Provider value={{ title, onChange, onEdit, tabs, activeKey, onCancelTab }}>
      {children}
    </TabsContext.Provider>
  );
};

const withTabs = (Component: React.ComponentType) => {
  return () => (
    <TabsProvider>
      <Component />
    </TabsProvider>
  );
};

export { TabsContext, withTabs };
