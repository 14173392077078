import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { xml2js } from "xml-js";

import { useAddTitles1Mutation, useGetTitles1Query } from "@/shared/api/titles-astral";

import { arrayBufferToBase64, title1SoloTitles, titleMapper } from "../libs";
import { INewDocAstral } from "../types/types";

import {
  useGetEventsQuery,
  useLazyGetDocumentQuery,
  useLazyGetEventsUnconfirmedQuery,
  usePostEventsConfirmMutation,
} from "./api/astral-api";

export const useGetTitle1Astral = () => {
  const { data: titles1 } = useGetTitles1Query();

  const [title1, setTitle1] = useState<any[]>();
  const [latestDocs, setLatestDocs] = useState<INewDocAstral[]>([]);
  const [documentsT1WithContent, setDocumentsT1WithContent] = useState<INewDocAstral[]>([]);

  const [addTitles1] = useAddTitles1Mutation();

  const { data: oldSavedTitles1Astral } = useGetTitles1Query();

  const [error, setError] = useState<string>("");

  const [fetchEvents, { data: events, isLoading: isLoadingEvents }] = useLazyGetEventsUnconfirmedQuery();

  // Сначала получаем новые титулы в /events, только потом они попадают в events/unconfirmed
  useGetEventsQuery();

  const [fetchDocument] = useLazyGetDocumentQuery();
  const [postEventsConfirm] = usePostEventsConfirmMutation();

  const getT1 = () => {
    fetchEvents();
  };

  useEffect(() => {
    titles1 && setTitle1(titles1);
  }, [titles1]);

  const incomingT1Events = useMemo(
    () =>
      events?.events.filter((event) => event.code === "epd.incoming" && event.data.Документ.ТипДокумента === "Т1ЭПЛ") ||
      [],
    [events]
  );

  // Удаляем ненужные евенты из events/unconfirmed
  const trashEventsIds = useMemo(
    () =>
      events?.events
        .filter((event) => event.code !== "epd.incoming")
        .reduce((acc: string[], event) => [...acc, event.id], []) || [],
    [events]
  );

  useEffect(() => {
    if (trashEventsIds.length) {
      postEventsConfirm({ eventsId: trashEventsIds });
    }
  }, [trashEventsIds]);

  useEffect(() => {
    setDocumentsT1WithContent([]);

    if (incomingT1Events.length) {
      incomingT1Events.forEach(async (event) => {
        if (dayjs().diff(dayjs(event.created), "month") === 0) {
          const { data: document } = await fetchDocument({
            documentId: event.data.Документ.Файл,
          });
          const { data: signa } = await fetchDocument({
            documentId: event.data.Документ.Подписи,
          });

          if (document) {
            const newDoc = xml2js(new TextDecoder("windows-1251").decode(document), { compact: true });
            // TODO: Надо фильтровать по дате другой, не created
            newDoc.date = dayjs(event.created).locale("ru").valueOf();
            newDoc.t1Signa = arrayBufferToBase64(signa);
            newDoc.eventId = event.id;
            newDoc.ИдПолучателя = event.data.ИдПолучателя;
            newDoc.ИдОтправителя = event.data.ИдОтправителя;
            newDoc.ТипОтправителя = event.data.ТипОтправителя;
            newDoc.sendedT2 = false;

            setDocumentsT1WithContent((prev) => [...prev, newDoc]);
          }
        } else {
          postEventsConfirm({ eventsId: [event.id] });
        }
      });
    }
  }, [incomingT1Events]);

  useEffect(() => {
    if (documentsT1WithContent.length) {
      const soloTitles = title1SoloTitles(documentsT1WithContent);
      const formattedDocuments = titleMapper(soloTitles);
      setLatestDocs(formattedDocuments);
    }
  }, [documentsT1WithContent]);

  useEffect(() => {
    if (!latestDocs.length && events && documentsT1WithContent.length === incomingT1Events.length) {
      setError("Т1 по данному водителю не найдено");
    }
  }, [latestDocs, events]);

  useEffect(() => {
    setError("");
    if (latestDocs.length && oldSavedTitles1Astral) {
      latestDocs.map((doc) => {
        addTitles1([...oldSavedTitles1Astral, doc]).then((data) => {
          if (data) {
            postEventsConfirm({ eventsId: [doc.title1.eventId] });
          }
        });
      });
    }

    setTitle1(latestDocs);
  }, [latestDocs, oldSavedTitles1Astral]);

  return { getT1, setTitle1, title1, error, isLoading: isLoadingEvents };
};
