import React, { FC } from "react";
import { withErrorBoundary } from "react-error-boundary";

import { ErrorBoundaryFallback } from "@/shared/ui/error-boundary";
import { CompaniesTree } from "@/widgets/companies/tree";

export const Companies: FC = () => {
  return <CompaniesTree />;
};

export const CompaniesPage = withErrorBoundary(Companies, {
  FallbackComponent: ErrorBoundaryFallback,
});
