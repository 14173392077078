import { Group } from "./types";

export const removeTopLvlSubGroups = (groups: Group[]) => {
  return groups.filter((group) => {
    let res = true;
    groups.map((item) => {
      if (group.parentId === item.id) {
        res = false;
      }
    });
    return res;
  });
};
