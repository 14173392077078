import React from "react";
import { ColumnsType } from "antd/es/table";

import { TerminalRO } from "@/entities/terminals/interfaces";
import { TerminalHandler } from "@/features/terminals/item-handle";

export const terminalColumns: ColumnsType<TerminalRO> = [
  {
    title: "CN терминала",
    dataIndex: "terminalCn",
    key: "terminalCn",
  },
  {
    title: "Адрес точки",
    dataIndex: "venues",
    key: "venues",
    render: (value) => value?.map((e) => <p key={e.id}>{e.title}</p>),
  },
  {
    title: "Филиал",
    dataIndex: "venues",
    key: "venues",
    render: (value) => value?.map((e) => <div key={e.id}>{e.subdivisions.map((e) => `${e.company.legalName} `)}</div>),
  },
  {
    title: "Действия",
    key: "actions",
    width: 160,
    render: (_, value) => {
      return <TerminalHandler terminalCn={value.terminalCn} />;
    },
  },
];
