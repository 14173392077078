import React, { FC } from "react";
import { useNavigate } from "react-router";
import { Button } from "antd";

import { useDeleteSubdivisionMutation } from "@/entities/subdivisions/api";
import { SubdivisionRO } from "@/entities/subdivisions/interfaces";
import { ButtonsVertical } from "@/shared/ui/css";

interface ISubdivisionHandlerProps {
  subdivisionId: SubdivisionRO["id"];
}

export const SubdivisionHandler: FC<ISubdivisionHandlerProps> = ({ subdivisionId }) => {
  const navigate = useNavigate();
  const [deleteSubdivision] = useDeleteSubdivisionMutation();

  return (
    <ButtonsVertical>
      <Button onClick={() => navigate(`/edit-subdivision/${subdivisionId}`)}>Изменить</Button>
      <Button onClick={() => deleteSubdivision(subdivisionId)} type="primary">
        Удалить
      </Button>
    </ButtonsVertical>
  );
};
