import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import * as minio from "minio";
import parser from "xml-js";

import { logger } from "@/shared/lib/logger";

import { AuthContext } from "../with-auth/auth-context";

import { Bucket, MinioFile, MinioHookReturnType } from "./types";

const uploadFileToPresignedUrl = (file, presignedUrl): Promise<{ file: File; status: boolean }> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", presignedUrl, true);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve({ file: file, status: true });
      } else {
        reject({ file: file, status: false });
      }
    };
    xhr.onerror = () => {
      reject({ file: file, status: false });
    };
    xhr.send(file);
  });
};

export const MinioContext = React.createContext<{
  minioClient: minio.Client | null;
  useMinio: () => MinioHookReturnType;
  // @ts-ignore
}>(null);

export const MinioProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [minioClient, setMinioClient] = useState<minio.Client | null>(null);
  const authContext = useContext(AuthContext);

  const useMinio = (): MinioHookReturnType => {
    const mc = minioClient;

    const createPresignedUrl = (bucketName, objectName) => {
      return new Promise((resolve, reject) => {
        mc &&
          mc.presignedPutObject(bucketName, objectName, (err, presignedUrl) => {
            if (err) {
              reject(err);
            } else {
              resolve(presignedUrl);
            }
          });
      });
    };

    const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);
    const [isLoadingMoreFiles, setIsLoadingMoreFiles] = useState<boolean>(false);
    const [isLoadingBuckets, setIsLoadingBuckets] = useState<boolean>(false);
    const [bucketsError, setBucketsError] = useState<string | null>(null);
    const [files, setFiles] = useState<MinioFile[]>([]);
    const [buckets, setBuckets] = useState<Bucket[]>([]);
    const [stream, setStream] = useState<minio.BucketStream<minio.BucketItem>>();
    const [searchParams, setSearchParams] = useState<{
      bucket: string | null;
      prefix: string | null;
    }>({
      bucket: null,
      prefix: null,
    });

    useEffect(() => {
      setIsLoadingFiles(true);
      setFiles([]);
      if (searchParams.bucket) {
        mc && setStream(mc.listObjects(searchParams.bucket, searchParams.prefix || "", true));
      }
    }, [searchParams]);

    useEffect(() => {
      let acceptedObjects = 0;
      const maxObjects = 20;

      if (stream) {
        stream.on("data", (obj: MinioFile) => {
          acceptedObjects += 1;
          setFiles((prev) => [...prev, obj]);

          if (acceptedObjects === maxObjects) {
            stream.pause();
            setIsLoadingFiles(false);
            acceptedObjects = 0;
          }
        });
        stream.on("pause", () => {
          setIsLoadingFiles(false);
          setIsLoadingMoreFiles(false);
        });
        stream.on("end", () => {
          setIsLoadingFiles(false);
          setIsLoadingMoreFiles(false);
        });
        stream.on("error", (err) => {
          logger.debug(err);
        });
        return () => {
          stream.destroy();
        };
      }
    }, [stream]);

    const loadMoreFiles = () => {
      if (stream && stream.readable) {
        setIsLoadingMoreFiles(true);
        stream.resume();
      }
    };

    const getBuckets = async () => {
      try {
        setIsLoadingBuckets(true);
        mc && setBuckets(await mc.listBuckets());
        setIsLoadingBuckets(false);
      } catch (e) {
        if (typeof e === "string") {
          setBucketsError(e.toUpperCase());
        } else if (e instanceof Error) {
          setBucketsError(e.message);
        }
      }
    };

    const removeFiles = async ({ bucket, files }: { bucket: string; files: string[] }) => {
      let res = true;
      for (const file of files) {
        try {
          mc && (await mc.removeObject(bucket, file));
        } catch (err) {
          res = false;
        }
      }
      return res;
    };

    const uploadFiles = async ({ bucket, files }) => {
      let res = 1;
      for (const file of files) {
        const url = await createPresignedUrl(bucket, file.name);
        try {
          const uploadData = await uploadFileToPresignedUrl(file, url);
          res *= Number(uploadData.status);
        } catch (err) {
          res = 0;
        }
      }
      return Boolean(res);
    };

    const getETag = async (bucket, fileName) => {
      if (mc) {
        try {
          const { etag } = await mc.statObject(bucket, fileName);
          return etag;
        } catch (error) {
          return null;
        }
      }
    };

    const moveFiles = async ({
      sourceBucket,
      destinationBucket,
      fileNames,
    }: {
      sourceBucket: string;
      destinationBucket: string;
      fileNames: string[];
    }) => {
      try {
        for (const fileName of fileNames) {
          const conds = new minio.CopyConditions();
          const eTag = await getETag(sourceBucket, fileName);
          if (eTag) {
            conds.setMatchETag(eTag);
            mc && (await mc.copyObject(destinationBucket, fileName, `/${sourceBucket}/${fileName}`, conds));
          } else {
            return false;
          }
        }
        for (const fileName of fileNames) {
          mc && (await mc.removeObject(sourceBucket, fileName));
        }
      } catch (error) {
        logger.error("Ошибка при перемещении файлов: ", error);
        return false;
      }

      return true;
    };

    return [
      setSearchParams,
      getBuckets,
      loadMoreFiles,
      removeFiles,
      uploadFiles,
      moveFiles,
      {
        files,
        buckets,
        bucket: searchParams.bucket,
        isLoadingFiles,
        isLoadingMoreFiles,
        isLoadingBuckets,
        bucketsError,
      },
    ];
  };

  useEffect(() => {
    (async () => {
      if (authContext && authContext.userData) {
        const authDataMinio = await fetch(`https://${String(process.env.REACT_APP_MINIO_HOST)}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            Action: "AssumeRoleWithWebIdentity",
            Version: String(process.env.REACT_APP_MINIO_VERSION),
            DurationSeconds: String(process.env.REACT_APP_MINIO_DURATION_SECONDS),
            WebIdentityToken: authContext.userData?.access_token || "",
            Region: "vault",
          }),
        });
        const minioData = JSON.parse(parser.xml2json(await authDataMinio.text()) || "{}");
        const miniAccessData = minioData.elements[0].elements[0].elements[1].elements;
        const accessKeyId = miniAccessData[0]?.name === "AccessKeyId" ? miniAccessData[0].elements[0].text : "";
        const secretAccessKey = miniAccessData[1]?.name === "SecretAccessKey" ? miniAccessData[1].elements[0].text : "";
        const sessionToken = miniAccessData[2]?.name === "SessionToken" ? miniAccessData[2].elements[0].text : "";
        const mc = new minio.Client({
          endPoint: String(process.env.REACT_APP_MINIO_HOST),
          useSSL: true,
          accessKey: accessKeyId,
          secretKey: secretAccessKey,
          sessionToken,
        });
        setMinioClient(mc);
      }
    })();
  }, [authContext]);

  return <MinioContext.Provider value={{ minioClient, useMinio }}>{children}</MinioContext.Provider>;
};
