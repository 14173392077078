import React, { ReactNode } from "react";

import { AuthProvider } from "./auth-context";
import { AuthProviderProps } from "./interface";

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  clientSecret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET,
  redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
};

export const withAuth = (component: () => ReactNode) => () =>
  <AuthProvider {...oidcConfig}>{component()}</AuthProvider>;
