import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CompanyDialog } from "@/features/companies/handle";
import { ExamsDialog } from "@/features/exams/modal-handle";
import { DeleteFilesDialog } from "@/features/files/delete-files-dialog";
import { MoveFilesDialog } from "@/features/files/move-files-dialog";
import { UploadFilesDialog } from "@/features/files/upload-files-dialog";
import { AddGroupDialog } from "@/features/groups/add-group-dialog";
import { AddUsersToGroupDialog } from "@/features/groups/add-users-to-group-dialog";
import { DeleteGroupsDialog } from "@/features/groups/delete-group-dialog";
import { ExcludeUsersFromGroupDialog } from "@/features/groups/exclude-users-from-group-dialog";
import { RenameGroupDialog } from "@/features/groups/rename-group-dialog";
import { PatientDialog } from "@/features/patients/modal-handle";
import { TerminalDialog } from "@/features/terminals/modal-handle";
import { AddGroupsDialog } from "@/features/users/add-groups-dialog";
import { VenueDialog } from "@/features/venues/handle";
import { Settings } from "@/features/window/configure";
import { useForceSetState } from "@/shared/hooks";
import { Modals } from "@/shared/types/modals";
import { UploadPatients } from "@/widgets/patients/storage-table";

import { currentModalSelector, openModalWithProps } from "../storage";

export const ModalsContainer = memo(() => {
  const { modals, props } = useSelector(currentModalSelector);
  const { keyForRerender, reloadComponent } = useForceSetState();
  const { keyForRerender: upload, reloadComponent: reloadUploads } = useForceSetState();
  const dispatch = useDispatch();

  useEffect(() => {
    reloadComponent();
    reloadUploads();
  }, [modals, props]);

  useEffect(() => {
    const dialog = window.location.hash.substring(1);
    dispatch(openModalWithProps({ modalName: dialog }));
  }, []);

  return (
    <>
      {modals.map((modal) => {
        switch (modal) {
          case Modals.PatientsDialog:
            return <PatientDialog key={keyForRerender} {...props} />;
          case Modals.TerminalsDialog:
            return <TerminalDialog key={keyForRerender} {...props} />;
          case Modals.VenuesDialog:
            return <VenueDialog key={keyForRerender} {...props} />;
          case Modals.CompaniesDialog:
            return <CompanyDialog key={keyForRerender} {...props} />;
          case Modals.ExamDialog:
            return <ExamsDialog key={keyForRerender} {...props} />;
          case Modals.Settings:
            return <Settings />;
          case Modals.PatientsUploadDialog:
            return <UploadPatients key={upload} />;
          case Modals.DeleteFilesDialog:
            return <DeleteFilesDialog key={keyForRerender} {...props} />;
          case Modals.UploadFilesDialog:
            return <UploadFilesDialog key={keyForRerender} {...props} />;
          case Modals.MoveFilesDialog:
            return <MoveFilesDialog key={keyForRerender} {...props} />;
          case Modals.AddGroupsDialog:
            return <AddGroupsDialog key={keyForRerender} {...props} />;
          case Modals.RenameGroupDialog:
            return <RenameGroupDialog key={keyForRerender} {...props} />;
          case Modals.DeleteGroupsDialog:
            return <DeleteGroupsDialog key={keyForRerender} {...props} />;
          case Modals.AddGroupDialog:
            return <AddGroupDialog key={keyForRerender} {...props} />;
          case Modals.AddUsersToGroupDialog:
            return <AddUsersToGroupDialog key={keyForRerender} {...props} />;
          case Modals.ExcludeUsersFromGroupDialog:
            return <ExcludeUsersFromGroupDialog key={keyForRerender} {...props} />;
          default:
            return null;
        }
      })}
    </>
  );
});
