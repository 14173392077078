import dayjs, { Dayjs } from "dayjs";

import { IDatesRange } from "./interfaces";

export const timeInterval = ["сегодня", "неделя", "месяц"];

export const reviewsColumns = [
  {
    title: "Место осмотра",
    dataIndex: "",
    key: "",
  },
  {
    title: "Всего проведено",
    dataIndex: "",
    key: "",
  },
  {
    title: "Успешно прошли",
    dataIndex: "",
    key: "",
  },
  {
    title: "Отклонено",
    dataIndex: "",
    key: "",
  },
  {
    title: "Состав",
    dataIndex: "",
    key: "",
  },
];

export const personsColumns = [
  {
    title: "Ф.И.О",
    dataIndex: "",
    key: "",
  },
  {
    title: "Организация",
    dataIndex: "",
    key: "",
  },
  {
    title: "Подразделение",
    dataIndex: "",
    key: "",
  },
  {
    title: "Табельный номер",
    dataIndex: "",
    key: "",
  },
  {
    title: "Причина недопуска",
    dataIndex: "",
    key: "",
  },
  {
    title: "Точка",
    dataIndex: "",
    key: "",
  },
];

export const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];

export enum EXAM_TYPE_RU {
  PER_TRIP = "Предрейсовый",
  AFTER_TRIP = "Послерейсовый",
  PRE_SHIFT = "Предсменный",
  AFTER_SHIFT = "Послесменный",
}

export const defaultDatesRange: IDatesRange = {
  from: "",
  to: "",
  isDefined: false,
};
