import { useEffect, useState } from "react";

import { activityStorage } from "../lib";

const getDarkMode = () => activityStorage.getTheme();

export const useTheme = () => {
  const [darkMode, setDarkMode] = useState<boolean>(getDarkMode);

  useEffect(() => {
    const initialValue = getDarkMode();

    if (initialValue !== darkMode) {
      activityStorage.updateTheme(darkMode);
      window.location.reload();
    }
  }, [darkMode]);

  return [darkMode, setDarkMode] as const;
};
