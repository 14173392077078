import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Menu } from "antd";

import { useAuth } from "@/shared/hooks/use-auth";

import { items } from "./constants";
import { BurgerBtn, BurgerWrapper, StylizedSider, StylizedSkeleton } from "./styles";

export const Sidebar = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState("");

  useEffect(() => {
    setCurrent(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <StylizedSider width={open ? 200 : 60} className={!open ? "closed" : ""}>
      {auth && auth.userData ? (
        <Menu
          onClick={({ key, domEvent }) => {
            domEvent.stopPropagation();
            setCurrent(key);
          }}
          selectedKeys={[current]}
          items={items}
          mode="inline"
          onSelect={({ key }) => navigate(key)}
        />
      ) : (
        <StylizedSkeleton active />
      )}
      <BurgerWrapper>
        <BurgerBtn
          type="primary"
          size="small"
          onClick={() => setOpen(!open)}
          style={{
            marginBottom: 16,
          }}
        >
          {open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </BurgerBtn>
      </BurgerWrapper>
    </StylizedSider>
  );
};
