import styled from "styled-components";

import { ButtonSpace } from "@/shared/styles/common";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`;

export const Block = styled.div`
  ${ButtonSpace}
`;
