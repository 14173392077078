import React, { FC, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "antd";

import { TitleContext } from "@/app/providers/ui/with-title";
import { CompanySelector } from "@/entities/companies/ui/multiple-selector";
import { useCreateSubdivisionMutation } from "@/entities/subdivisions/api";
import { VenuesSelector } from "@/entities/venues/ui/selector";
import { FieldsRules } from "@/shared/constants";
import { StylizedItem } from "@/shared/ui/css/global";
import { StylizedButton, StylizedForm } from "@/shared/ui/dialog/styles";
import { closeActiveModal } from "@/shared/ui/modals/storage";

export const CreateSubdivisonPage: FC = () => {
  const dispatch = useDispatch();
  const { setTitle } = useContext(TitleContext);

  const [form] = Form.useForm();

  const [createSubdivision] = useCreateSubdivisionMutation();

  const handleClose = () => {
    dispatch(closeActiveModal());
  };

  useEffect(() => {
    setTitle("Создать новое подразделение");
  }, []);

  return (
    <StylizedForm form={form} name="create" autoComplete="off" onFinish={createSubdivision}>
      <StylizedItem label="Введите название подразделения: " name="title" rules={FieldsRules.default}>
        <Input placeholder="Название" style={{ width: 300 }} />
      </StylizedItem>
      <StylizedItem label="Выберить компанию подразделения: " name="companyId" rules={FieldsRules.default}>
        <CompanySelector update={(companyId) => form.setFieldsValue({ companyId })} />
      </StylizedItem>
      <StylizedItem label="Выберить адрес подразделения: " name="venueIds" rules={FieldsRules.default}>
        <VenuesSelector update={(venueId) => form.setFieldsValue({ venueIds: [venueId] })} />
      </StylizedItem>
      <StylizedItem>
        <Button onClick={handleClose}>Отмена</Button>
        <StylizedButton type="primary" htmlType="submit">
          Сохранить
        </StylizedButton>
      </StylizedItem>
    </StylizedForm>
  );
};
