import { PersonEntity } from "@medregister/domain";
import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryPlatform } from "@/shared/lib";

import { TablePagination } from "./constants";

export const patientsApi = createApi({
  reducerPath: "patientsApi",
  baseQuery: baseQueryPlatform,
  tagTypes: ["Person"],
  endpoints: (builder) => ({
    getPatients: builder.query<PersonEntity[], number>({
      query: (offset) => `/persons?limit=${TablePagination.pageSize}&offset=${TablePagination.pageSize * (offset - 1)}`,
      providesTags: (result) =>
        result ? [...result?.map(({ id }) => ({ type: "Person" as const, id }))] : [{ type: "Person", id: "PERSONS" }],
    }),
    getPatient: builder.query<PersonEntity, PersonEntity["id"]>({
      query: (id) => `/persons/${id}`,
    }),
    searchPatients: builder.mutation<{ data: PersonEntity[]; count: number }, any>({
      query: (body) => ({
        url: `/persons?limit=${TablePagination.pageSize}&offset=${TablePagination.pageSize * (body.offset - 1)}`,
        method: "POST",
        body,
      }),
    }),
    uploadPatients: builder.mutation<PersonEntity[], PersonEntity[]>({
      query: (body) => ({
        url: "/persons/upload",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Person"],
    }),
    createPatient: builder.mutation<PersonEntity[], PersonEntity>({
      query: (body) => ({
        url: "/persons/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Person"],
    }),
    deletePatient: builder.mutation<PersonEntity[], PersonEntity["id"]>({
      query: (id) => ({
        url: `/persons/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Person"],
    }),
    updatePatient: builder.mutation<PersonEntity[], PersonEntity>({
      query: ({ id, ...body }) => ({
        url: `/persons/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Person"],
    }),
  }),
});

export const {
  useLazyGetPatientsQuery,
  useGetPatientQuery,
  useSearchPatientsMutation,
  useDeletePatientMutation,
  useCreatePatientMutation,
  useUpdatePatientMutation,
  useUploadPatientsMutation,
} = patientsApi;
