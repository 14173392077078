import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";

import { Group } from "@/app/providers/with-idm/types";
import { MenuItem } from "@/shared/ui/tree/interface";

export const mapGroupsToMenuItems = (groups: Group[], lvl = 0): MenuItem[] => {
  return groups.map((group: Group) => {
    const menuItem: MenuItem = {
      label: group.name,
      key: group.id,
      haveSubGroups: group.subGroupCount ? group.subGroupCount > 0 : false,
      lvl: lvl,
    };

    if (group.subGroups && group.subGroups.length > 0) {
      menuItem.children = mapGroupsToMenuItems(group.subGroups, lvl + 1);
    }

    return menuItem;
  });
};

export const findElementById = (arr, id) => {
  for (const element of arr) {
    if (element.id === id) {
      return element;
    }
    if (element.subGroups && element.subGroups.length > 0) {
      const foundElement = findElementById(element.subGroups, id);
      if (foundElement) {
        return foundElement;
      }
    }
  }
  return null;
};

export const renderParents = (parentId, groups) => {
  const parent = findElementById(groups, parentId);
  if (parent) {
    const parentHierarchy: any[] = [];
    let currentParent = parent;
    let key = 0;
    while (currentParent) {
      const element = (
        <React.Fragment key={key}>
          <p>{currentParent.name}</p>
          <ArrowRightOutlined />
        </React.Fragment>
      );
      parentHierarchy.unshift(element);
      currentParent = findElementById(groups, currentParent.parentId);
      key += 1;
    }
    return parentHierarchy;
  }
  return null;
};

export const filterGroupsRecursive = (groups: MenuItem[], searchText: string): MenuItem[] => {
  return groups
    .map((group: MenuItem) => {
      const filteredGroup: MenuItem = { ...group };
      if (group.children && !group.label.includes(searchText)) {
        filteredGroup.children = filterGroupsRecursive(group.children, searchText);
      }
      return filteredGroup;
    })
    .filter((group) => {
      if (group.label.includes(searchText)) {
        return true;
      }
      if (group.children) {
        return filterGroupsRecursive(group.children, searchText).length > 0;
      }
      return false;
    });
};
