import { CloseOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import styled from "styled-components";

import { ButtonSpace, ModalUI } from "@/shared/styles/common";

export const Wrapper = styled.div`
  ${ModalUI}

  flex-direction: column;
  position: relative;
  text-align: center;
  width: 400px;

  ${ButtonSpace}
`;

export const StyledDragger = styled(Dragger)`
  margin: 20px 0 0;
  max-height: 400px;
  overflow: scroll;
`;

export const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const DraggerTitle = styled.h3`
  color: #fff;
  margin: 20px;
`;
