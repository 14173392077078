import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { message, Tabs } from "antd";

import { IdmContext } from "@/app/providers/with-idm/idm-context";
import { Group } from "@/app/providers/with-idm/types";
import { Loader, Message, Search, Tree } from "@/shared/ui";
import { WithViewControl } from "@/shared/ui/error-control";
import { MenuItem } from "@/shared/ui/tree/interface";
import { UsersTable } from "@/widgets/users/table";

import { GroupsTable } from "../table";

import { TabKeyEnum, tabs } from "./constants";
import { filterGroupsRecursive, findElementById, mapGroupsToMenuItems, renderParents } from "./helpers";
import { Body, BodyHeader, Flex, Grid, List, Screen } from "./styles";

export const GroupsDashboard: FC = () => {
  const { useGetGroups, useGetGroupUsers } = useContext(IdmContext);
  const [fetchChildren, { groups, isLoading, error }] = useGetGroups();
  const [getGroupUsers, { users, isLoading: isLoadingUsers }] = useGetGroupUsers();
  const [searchText, setSearchText] = useState("");
  const [subGroups, setSubGroups] = useState<Group[]>([]);
  const [selectedSubGroups, setSelectedSubGroups] = useState<Group[]>([]);
  const [tabKey, setTabKey] = useState<string>(TabKeyEnum.Groups);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>();

  useEffect(() => {
    setSelectedGroup(findElementById(groups, selectedMenuItem?.key));
  }, [selectedMenuItem, groups]);

  useEffect(() => {
    const subGroups = selectedGroup?.subGroups;
    if (subGroups) {
      setSubGroups(subGroups);
    }
  }, [groups]);

  useEffect(() => {
    error && message.error(error);
  }, [error]);

  useEffect(() => {
    const subGroups = selectedGroup?.subGroups;
    if (subGroups) {
      setSubGroups(subGroups);
    }

    if (!selectedGroup) {
      setSubGroups([]);
    }

    selectedGroup && getGroupUsers({ groupId: selectedGroup.id });
    setSelectedSubGroups([]);
  }, [selectedGroup]);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredGroupsNew = useMemo(
    () => filterGroupsRecursive(mapGroupsToMenuItems(groups), searchText),
    [groups, searchText]
  );

  return (
    <WithViewControl emptyText={() => <Message text="Группы не найдены" />}>
      <Grid>
        <List>
          <Search text="Поиск группы..." onSearch={handleSearch} />
          {isLoading ? (
            <Loader />
          ) : (
            <Tree
              items={filteredGroupsNew}
              fetchChildren={fetchChildren}
              selectedItem={selectedMenuItem}
              setSelectedItem={setSelectedMenuItem}
            />
          )}
        </List>
        <Body>
          <BodyHeader>
            <Flex>
              {selectedGroup && renderParents(selectedGroup?.parentId, groups)}
              <p>{selectedGroup?.name}</p>
            </Flex>
          </BodyHeader>
          <Tabs
            style={{ position: "relative", bottom: "-1px" }}
            hideAdd
            type="editable-card"
            items={tabs}
            onChange={(key) => setTabKey(key)}
          ></Tabs>
          <Screen>
            {tabKey === TabKeyEnum.Groups && (
              <GroupsTable
                group={selectedGroup}
                subGroups={subGroups}
                selectedSubGroups={selectedSubGroups}
                setSelectedSubGroups={setSelectedSubGroups}
              />
            )}
            {tabKey === TabKeyEnum.Users && (
              <UsersTable
                users={
                  selectedGroup
                    ? users.map((user) => {
                        return {
                          user: user,
                          groups: [],
                        };
                      })
                    : []
                }
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                loading={selectedGroup ? isLoadingUsers : false}
                withControl
                groupId={selectedGroup?.id}
              />
            )}
          </Screen>
        </Body>
      </Grid>
    </WithViewControl>
  );
};
