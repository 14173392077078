import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, message } from "antd";

import { IdmContext } from "@/app/providers/with-idm/idm-context";
import { IAddUsersToGroupDialogModalProps, Modals } from "@/shared/types/modals";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { closeModal } from "@/shared/ui/modals/storage";
import { filterUsers } from "@/widgets/users/dashboard/helpers";
import { UsersTable } from "@/widgets/users/table";

import { FormButton } from "../add-group-dialog/styles";

export const AddUsersToGroupDialog: FC<IAddUsersToGroupDialogModalProps> = ({ groupId }) => {
  const dispatch = useDispatch();
  const [inputText] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { useGetUsers, useAddUsersToGroup, useGetGroupUsers } = useContext(IdmContext);
  const [, { users, isLoading: isLoadingUsers }] = useGetUsers();
  const [getGroupUsers] = useGetGroupUsers();
  const [addUsersToGroup, { isLoading, error, isFinised }] = useAddUsersToGroup();

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (isFinised) {
      message.success("Пользователи добавлены в группу");
      getGroupUsers({ groupId: groupId });
      dispatch(closeModal(Modals.AddUsersToGroupDialog));
    }
  }, [isFinised]);

  const filteredUsers = useMemo(() => users?.filter((user) => filterUsers(user, inputText)), [users, inputText]);
  const handleAddUsersToGroup = async () => await addUsersToGroup({ users: selectedUsers, groupId: groupId });

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.AddUsersToGroupDialog))}>
      <Card
        title="Добавить пользователя в группу"
        style={{ width: "70vw", minWidth: "320px", height: "80vh" }}
        extra={<CloseOutlined onClick={() => dispatch(closeModal(Modals.AddUsersToGroupDialog))} />}
      >
        <div style={{ height: "87%" }}>
          <UsersTable
            users={filteredUsers}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            loading={isLoadingUsers}
          />
        </div>
        <FormButton>
          <Button onClick={() => dispatch(closeModal(Modals.AddUsersToGroupDialog))}>Отмена</Button>
          <Button
            style={{ marginLeft: 10 }}
            type="primary"
            onClick={handleAddUsersToGroup}
            loading={isLoading}
            disabled={!selectedUsers.length}
          >
            Добавить
          </Button>
        </FormButton>
      </Card>
    </BoxWithCloseOnClickOutside>
  );
};
