import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { PersonEntity } from "@medregister/domain";
import { Button, Upload, UploadFile, UploadProps } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import xlsxParser from "xlsx-parse-json";

import { useUploadPatientsMutation } from "@/entities/patients/api";
import { addPatients, patientsUploadSelector } from "@/entities/patients/storage";
import { DateFormats } from "@/shared/types/globals";
import { is } from "@/shared/types/types-guards";
import { closeActiveModal } from "@/shared/ui/modals/storage";

import { Block, Wrapper } from "./styles";
import { formaterXLSXObject, patientsStorage } from "./utils";

interface XlsxParserData {
  [key: string]: PersonEntity[];
}

export const UploadButtons = () => {
  const patients = useSelector(patientsUploadSelector);
  const dispatch = useDispatch();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadPatients, uploadData] = useUploadPatientsMutation();

  const handleChange: UploadProps["onChange"] = ({ file, fileList }: UploadChangeParam<UploadFile>) => {
    let newFileList = [...fileList];
    newFileList = newFileList.slice(-1);
    setFileList(newFileList);

    xlsxParser.onFileSelection(file).then((data: XlsxParserData) => {
      const parsedData = Object.entries(data)[0][1];
      const persons = parsedData
        .map((p) => ({ ...formaterXLSXObject(p), id: uuidv4() }))
        .map((p) => ({
          ...p,
          dateOfBirth: dayjs(p.dateOfBirth).locale("ru").format(DateFormats.Default),
        }));
      dispatch(addPatients(persons));
    });
  };

  const handleSave = async () => {
    uploadPatients(patients);
    await dispatch(addPatients([]));
    dispatch(closeActiveModal());
  };

  const handleRemove = () => {
    dispatch(addPatients([]));
  };

  useEffect(function uploadSavedPatients() {
    if (!patients.length) {
      dispatch(addPatients(patientsStorage.getPatients()));
    }
  }, []);

  useEffect(
    function updateChangedPatients() {
      patientsStorage.updatePatients(patients);
    },
    [patients]
  );

  return (
    <Wrapper>
      <Upload
        fileList={fileList}
        showUploadList={false}
        beforeUpload={() => false}
        onChange={handleChange}
        onRemove={handleRemove}
      >
        <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
      </Upload>
      {!is.empty(patients) && (
        <Block>
          <Button onClick={handleSave} loading={uploadData.isLoading} danger={uploadData.isError}>
            Сохранить
          </Button>
          <Button onClick={handleRemove} loading={uploadData.isLoading} danger={uploadData.isError}>
            Очистить
          </Button>
        </Block>
      )}
    </Wrapper>
  );
};
