import styled from "styled-components";

export const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px;
  width: 100%;
`;

export const Item = styled.div`
  h1 {
    margin: 0;
  }

  p {
    margin: 0;
  }
`;
