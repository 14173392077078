import React, { FC, useContext, useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router";

import { TitleContext } from "@/app/providers/ui/with-title";
import { AuthContext } from "@/app/providers/with-auth/auth-context";
import { CreateUserForm } from "@/features/users/create-user-form";
import { ErrorBoundaryFallback } from "@/shared/ui/error-boundary";
import { WithViewControl } from "@/shared/ui/error-control";

export const isAdminChecker = (userData) => userData?.profile.policy.includes("admin");

const CreateUser: FC = () => {
  const navigate = useNavigate();
  const { setTitle } = useContext(TitleContext);
  const { userData } = useContext(AuthContext);

  const isAdmin = isAdminChecker(userData);

  if (!isAdmin) {
    navigate(-1);
  }

  useEffect(() => {
    setTitle("Создать нового пользователя");
  }, []);

  return (
    <WithViewControl>
      <CreateUserForm />
    </WithViewControl>
  );
};

export const CreateUserPage = withErrorBoundary(CreateUser, {
  FallbackComponent: ErrorBoundaryFallback,
});
