import React from "react";
import { Route, Routes } from "react-router-dom";

import { MainLayout } from "../layouts/main-layout";

import { routes } from "./routes";

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </Route>
    </Routes>
  );
};
