import React, { FC, useMemo } from "react";
import { VenueEntity } from "@medregister/domain";

import { useGetVenuesQuery } from "@/entities/venues/api";

import { StylizedSelect } from "./styles";

interface IVenuesSelectorProps {
  update: (value: string) => void;
  value?: VenueEntity["id"];
}

export const VenuesSelector: FC<IVenuesSelectorProps> = ({ value, update }) => {
  const { data: venues } = useGetVenuesQuery();

  const venuesOptions = useMemo(
    () =>
      venues?.map((c) => ({
        value: c.id,
        label: `${c.title} ${c.addressStr}`,
      })),
    [venues]
  );

  if (!venuesOptions || !venuesOptions.length) {
    return null;
  }

  return (
    <StylizedSelect onChange={update} value={value} allowClear placeholder="Выберите точку" options={venuesOptions} />
  );
};
