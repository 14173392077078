import React from "react";
import { Checkbox } from "antd";
import { ColumnsType } from "antd/es/table";

import { Group } from "@/app/providers/with-idm/types";

interface ColumnsProps {
  newSelectedGroups: { id: string; path: string }[];
  setNewSelectedGroups: React.Dispatch<React.SetStateAction<{ id: string; path: string }[]>>;
}

export const groupsColumns: (props: ColumnsProps) => ColumnsType<Group> = ({
  newSelectedGroups,
  setNewSelectedGroups,
}) => [
  {
    dataIndex: "id",
    key: "id",
    render: (value, { path }) => {
      const selectedGroup = newSelectedGroups.find((group) => group.id === value);

      return (
        <Checkbox
          checked={!!selectedGroup}
          onChange={() => {
            if (selectedGroup) {
              setNewSelectedGroups((prev) => prev.filter((group) => group.id !== value));
            } else {
              setNewSelectedGroups((prev) => [...prev, { id: value, path: path }]);
            }
          }}
        />
      );
    },
  },
  {
    title: "Имя группы",
    dataIndex: "name",
    key: "name",
    render: (value) => <p>{value}</p>,
  },
];
