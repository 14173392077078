import React, { useMemo } from "react";
import { CompanyEntity } from "@medregister/domain";
import { Select } from "antd";

import { useGetCompaniesQuery } from "../api";

interface ICompaniesSelectorProps {
  companyId?: CompanyEntity["id"];
  updateCompanyId: (id: CompanyEntity["id"]) => void;
}

export const CompaniesSelector = ({ companyId, updateCompanyId }: ICompaniesSelectorProps) => {
  const { data: companies } = useGetCompaniesQuery();

  const companiesOptions = useMemo(
    () => companies?.filter((c) => c.id !== companyId).map((c) => ({ value: c.id, label: c.legalName })),
    [companies]
  );

  return <Select onChange={updateCompanyId} placeholder="Выбрать компанию" options={companiesOptions} aria-required />;
};
