import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import parser from "xml-js";

import { useAddTitles5Mutation, useGetTitles1Query, useGetTitles5Query } from "@/shared/api/titles-astral";

import { arrayBufferToBase64 } from "../libs";
import { INewDocAstral } from "../types/types";

import {
  useLazyGetDocumentQuery,
  useLazyGetEventsUnconfirmedQuery,
  usePostEventsConfirmMutation,
} from "./api/astral-api";

export const useGetTitle5Astral = () => {
  const [title5, setTitle5] = useState<any[]>([]);
  const [documentsT5WithContent, setDocumentsT5WithContent] = useState<INewDocAstral[]>([]);

  const { data: titles1Astral } = useGetTitles1Query();
  const { data: titles5Astral } = useGetTitles5Query();

  const [addTitles5] = useAddTitles5Mutation();

  const [error, setError] = useState("");

  const [postEventsConfirm] = usePostEventsConfirmMutation();
  const [fetchEvents, { data: events, isLoading: isLoadingEvents }] = useLazyGetEventsUnconfirmedQuery();
  const [fetchDocument] = useLazyGetDocumentQuery();

  const getT5 = () => {
    fetchEvents();
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const incomingEvents = useMemo(() => events?.events.filter((event) => event.code === "epd.incoming"), [events]);

  useEffect(() => {
    setDocumentsT5WithContent([]);

    const filteredEvents = incomingEvents?.filter((event) => event.data.Документ.ТипДокумента === "Т5ЭПЛ");

    if (filteredEvents) {
      filteredEvents.forEach(async (event) => {
        if (dayjs().diff(dayjs(event.created), "month") === 0) {
          const { data: document } = await fetchDocument({
            documentId: event.data.Документ.Файл,
          });
          const { data: signa } = await fetchDocument({
            documentId: event.data.Документ.Подписи,
          });
          if (document && signa) {
            const newDoc = parser.xml2js(new TextDecoder("windows-1251").decode(document), { compact: true });

            newDoc.t1Signa = arrayBufferToBase64(signa);
            newDoc.eventId = event.id;
            newDoc.ИдПолучателя = event.data.ИдПолучателя;
            newDoc.ИдОтправителя = event.data.ИдОтправителя;
            newDoc.ТипОтправителя = event.data.ТипОтправителя;
            newDoc.fileName = event.data.Документ.Наименование;

            setDocumentsT5WithContent((prev) => [...prev, newDoc]);
          }
        }
      });
    }
  }, [events]);

  useEffect(() => {
    if (documentsT5WithContent.length && titles1Astral && titles5Astral) {
      const formattingDocs = documentsT5WithContent
        .map((item) => {
          const T5_УИД_ПЛ = item.Файл.Документ.СодИнфЗаезд._attributes.УИД_ПЛ;
          const matchedTitle1 = titles1Astral?.find(
            (person) => person.УИД_ПЛ.toLowerCase() === T5_УИД_ПЛ.toLowerCase()
          );

          if (matchedTitle1) {
            return {
              content: item.content,
              title5: {
                firstName: matchedTitle1.firstName,
                lastName: matchedTitle1.lastName,
                secondName: matchedTitle1.secondName,
                ...item,
              },
              fileName: item.fileName,
              УИД_ПЛ: T5_УИД_ПЛ,
            };
          } else {
            postEventsConfirm({ eventsId: [item.eventId] });
            return null;
          }
        })
        .filter((item) => item !== null);

      if (!formattingDocs.length) {
        setError("Т5 по данному водителю не найдено");
      }

      formattingDocs.forEach((doc) => {
        if (doc) {
          addTitles5([...titles5Astral, doc]).then((data) => {
            if (data) {
              postEventsConfirm({ eventsId: [doc.title5.eventId] });
            }
          });
        }
      });

      setTitle5(formattingDocs);
    }
  }, [documentsT5WithContent, titles1Astral, titles5Astral]);

  return { getT5, setTitle5, title5, error, isLoading: isLoadingEvents };
};
