import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { InboxOutlined } from "@ant-design/icons";
import { Button, message } from "antd";

import { MinioContext } from "@/app/providers/with-minio/minio-context";
import { IUploadFilesDialogModalProps, Modals } from "@/shared/types/modals";
import { Loader } from "@/shared/ui";
import { BoxWithCloseOnClickOutside } from "@/shared/ui/box-with-close-on-click-outside";
import { ButtonsHorizontal } from "@/shared/ui/css/global";
import { closeModal } from "@/shared/ui/modals/storage";

import { DraggerTitle, StyledCloseOutlined, StyledDragger, Wrapper } from "./styles";

export const UploadFilesDialog: FC<IUploadFilesDialogModalProps> = ({ bucket, refetchTrigger }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { useMinio } = useContext(MinioContext);

  const [, , , , uploadFiles] = useMinio();

  const uploadHandler = async () => {
    if (files.length) {
      setIsLoading(true);
      const result = await uploadFiles({ bucket: bucket, files: files });
      result ? message.success("Файлы загружены") : message.error("Ошибка при загрузке файлов");
      refetchTrigger();
      dispatch(closeModal(Modals.UploadFilesDialog));
    }
  };

  const handleFileUpload = (file: File) => {
    if (file) {
      setFiles((prev) => [...prev, file]);
    }
  };

  return (
    <BoxWithCloseOnClickOutside close={() => dispatch(closeModal(Modals.UploadFilesDialog))}>
      <Wrapper className="widget">
        <StyledCloseOutlined onClick={() => dispatch(closeModal(Modals.UploadFilesDialog))} />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <StyledDragger beforeUpload={() => false} multiple onChange={(info) => handleFileUpload(info.file)}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <DraggerTitle>Нажмите или перетащите файл в эту область, чтобы загрузить</DraggerTitle>
            </StyledDragger>
            <ButtonsHorizontal>
              <Button disabled={!files.length} onClick={uploadHandler}>
                Загрузить
              </Button>
              <Button onClick={() => dispatch(closeModal(Modals.UploadFilesDialog))}>Отменить</Button>
            </ButtonsHorizontal>
          </>
        )}
      </Wrapper>
    </BoxWithCloseOnClickOutside>
  );
};
