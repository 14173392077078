import React, { FC } from "react";
import { Form, Input } from "antd";

import { useCreateTerminalMutation, useGetTerminalQuery, useUpdateTerminalMutation } from "@/entities/terminals/api";
import { VenuesSelector } from "@/entities/venues/ui/selector";
import { FieldsRules } from "@/shared/constants";
import { ITerminalsDialogModalProps, ModalMode } from "@/shared/types/modals";
import { EntityDialog } from "@/shared/ui";
import { StylizedItem } from "@/shared/ui/css/global";

export const TerminalDialog: FC<ITerminalsDialogModalProps> = ({ terminalCn, modalMode }) => {
  const { data: terminal, isLoading } = useGetTerminalQuery(terminalCn);

  const [form] = Form.useForm();

  const [createTerminal] = useCreateTerminalMutation();
  const [updateTerminal] = useUpdateTerminalMutation();

  const data = modalMode === ModalMode.EDIT ? { terminalCn: terminal?.terminalCn } : null;

  return (
    <EntityDialog
      create={createTerminal}
      data={data}
      update={updateTerminal}
      isLoading={isLoading}
      form={form}
      initialValues={{ venueId: terminal?.venues[0].id }}
      modalMode={modalMode}
      title="теминал"
    >
      <StylizedItem label="Введите CN терминала: " name="terminalCn" rules={FieldsRules.default}>
        <Input style={{ width: 300 }} placeholder="Название" />
      </StylizedItem>
      <StylizedItem label="Выберите точку терминала: " rules={FieldsRules.default}>
        <VenuesSelector update={(venueId) => form.setFieldsValue({ venueId })} />
      </StylizedItem>
    </EntityDialog>
  );
};
