import compose from "compose-function";

import { withTheme } from "./ui/with-theme";
import { withTitle } from "./ui/with-title";
import { withTabs } from "./ui/withTabs";
import { withAuth } from "./with-auth";
import { withIdm } from "./with-idm";
import { withMinio } from "./with-minio";
import { withMQTT } from "./with-mqtt";
import { withRouter } from "./with-router";

export const withProviders = compose(
  withRouter,
  withTitle,
  withTheme,
  withMinio,
  withIdm,
  withAuth,
  withMQTT,
  withTabs
);
