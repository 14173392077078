import { Spin as AntdSpin } from "antd";
import styled from "styled-components";

export const Body = styled.ul`
  background: #2b2b2b;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  list-style: none;
  overflow-y: scroll;
  padding: 6px;
  user-select: none;
`;

export const Item = styled.li`
  list-style: none;
`;

export const List = styled.ul`
  animation: ${(props) => (props.active ? "fadeIn 0.3s forwards" : "none")};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 30px;
  transition: max-height 0.3s ease;
`;

export const Title = styled.div`
  align-items: center;
  background: ${(props) => (props.active ? "#3c3c3c !important" : "#2b2b2b")};
  border: 1px solid ${(props) => (props.selected ? "#fff" : "transparent")};
  border-radius: 6px;
  ${(props) =>
    props.selected
      ? `
      position: relative;
      z-index: 1;`
      : ""}
  cursor: pointer;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  padding: 15px;
  transform: ${(props) => (props.active ? "scale(1.04)" : "scale(1)")};
  transition: all 0.3s;

  &:hover {
    background: #272727;
  }
`;

export const Spin = styled(AntdSpin)`
  padding-top: 14px;
`;
