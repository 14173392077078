import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";

import { AuthContext } from "@/app/providers/with-auth/auth-context";
import { IdmContext } from "@/app/providers/with-idm/idm-context";
import { Search } from "@/shared/ui";
import { WithViewControl } from "@/shared/ui/error-control";
import { UsersTable } from "@/widgets/users/table";

import { filterUsers } from "./helpers";
import { Header } from "./styles";

export const UsersDashboard: FC = () => {
  const authContext = useContext(AuthContext);
  const isAdmin = authContext?.userData?.profile?.policy?.includes("admin");
  const [searchText, setSearchText] = useState("");
  const { useGetUsers, useDeleteUsers } = useContext(IdmContext);
  const [getUsers, { users, isLoading, error }] = useGetUsers();
  const [deleteUsers, { isLoading: isLoadingDelete }] = useDeleteUsers();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    error && message.error(error);
  }, [error]);

  const handleSearch = (event) => setSearchText(event.target.value);

  const handleCreate = () => {
    isAdmin && navigate("/create-user");
  };

  const handleDelete = async () => {
    await deleteUsers(selectedUsers);
    setSelectedUsers([]);
    getUsers();
  };

  const filteredUsers = useMemo(() => users?.filter((user) => filterUsers(user, searchText)), [users, searchText]);

  return (
    <WithViewControl>
      <Search text="Введите имя пользователя" onSearch={handleSearch} />
      <Header>
        <Button onClick={handleCreate} disabled={!isAdmin}>
          Добавить пользователя
        </Button>
        <Button disabled={selectedUsers.length == 0 || !isAdmin} onClick={handleDelete}>
          Удалить пользователя
        </Button>
      </Header>
      <UsersTable
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        users={filteredUsers}
        loading={isLoading || isLoadingDelete}
        withGroups
      />
    </WithViewControl>
  );
};
