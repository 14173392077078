import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from "react";

import { IdmErrorHanlder } from "@/shared/lib/idm-error-handler";

import { AuthContext } from "../with-auth/auth-context";

import { removeTopLvlSubGroups } from "./helpers";
import {
  AddGroupArgs,
  AddUserArgs,
  Group,
  UseAddGroupReturnType,
  UseAddUserReturnType,
  UseAddUsersToGroupReturnType,
  UseDeleteGroupsReturnType,
  UseDeleteUsersReturnType,
  UseExcludeUsersFromGroupReturnType,
  UseGetGroupsReturnType,
  UseGetGroupUsersReturnType,
  UseGetUsersReturnType,
  User,
  UserData,
  UseRenameGroupReturnType,
} from "./types";

interface Store {
  useGetUsers: () => UseGetUsersReturnType;
  useGetGroups: () => UseGetGroupsReturnType;
  useAddUser: () => UseAddUserReturnType;
  useDeleteUsers: () => UseDeleteUsersReturnType;
  useGetGroupUsers: () => UseGetGroupUsersReturnType;
  useRenameGroup: () => UseRenameGroupReturnType;
  useDeleteGroups: () => UseDeleteGroupsReturnType;
  useAddUsersToGroup: () => UseAddUsersToGroupReturnType;
  useAddGroup: () => UseAddGroupReturnType;
  useExcludeUsersFromGroup: () => UseExcludeUsersFromGroupReturnType;
}

export const IdmContext = createContext<Store>({} as Store);

export const IdmProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const accessToken = authContext?.userData?.access_token;

  const [groups, setGroups] = useState<Group[]>([]);
  const [GroupUsers, setGroupUsers] = useState<User[]>([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(true);
  const [errorGroups, setErrorGroups] = useState<string | null>(null);

  const baseQuery: ({ url, method, body }: { url: string; method?: string; body?: any }) => Promise<any> = async ({
    url,
    method = "GET",
    body,
  }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_IDM_API_URL}${url}`, {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: body,
      });
      const data = await response.text();
      const jsonData = data ? JSON.parse(data) : data;
      return await jsonData;
    } catch (error) {
      console.error("Error with request:", error);
    }
  };

  const useGetUsers = (): UseGetUsersReturnType => {
    const [users, setUsers] = useState<UserData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const getUsers = async () => {
      const data = await baseQuery({ url: "/users" });
      IdmErrorHanlder({ data: data, setError: setError });
      if (Array.isArray(data)) {
        setUsers(data);
      }
      setIsLoading(false);
    };

    useEffect(() => {
      if (isLoading) {
        getUsers();
      }
    }, []);

    return [getUsers, { users, isLoading, error }];
  };

  const getSubGroups = async ({
    groupId,
    setError,
  }: {
    groupId: string;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
  }): Promise<{ subGroups: Group[]; forbidden: boolean }> => {
    const data = await baseQuery({ url: `/groups/${groupId}/children` });
    IdmErrorHanlder({ data: data, setError: setError });
    if (Array.isArray(data)) {
      return { subGroups: data, forbidden: false };
    } else {
      return { subGroups: [], forbidden: true };
    }
  };

  const useGetGroups = (): UseGetGroupsReturnType => {
    useEffect(() => {
      const fetchData = async () => {
        const data = await baseQuery({
          url: `/users/${authContext?.userData?.profile.sub}/groups`,
        });
        IdmErrorHanlder({ data: data, setError: setErrorGroups });
        if (Array.isArray(data)) {
          await Promise.all(
            data.map(async (group) => {
              const { subGroups: subGroups } = await getSubGroups({
                groupId: group.id,
                setError: setErrorGroups,
              });
              group.subGroups = subGroups;
            })
          );
          setGroups(removeTopLvlSubGroups(data));
        }
        setIsLoadingGroups(false);
      };

      if (isLoadingGroups) {
        fetchData();
      }
    }, []);

    const fetchChildren = async ({ parentId }: { parentId: string }) => {
      setErrorGroups(null);
      const updateSubGroups = (groups: Group[], forbidden: boolean): Group[] => {
        return groups.map((item) => {
          if (item.id === parentId) {
            if (forbidden) {
              return { ...item, subGroupCount: 0 };
            } else {
              return {
                ...item,
                subGroups: subGroups,
                subGroupCount: subGroups.length,
              };
            }
          } else if (item.subGroups) {
            return {
              ...item,
              subGroups: updateSubGroups(item.subGroups, forbidden),
            };
          }
          return item;
        });
      };

      const { subGroups, forbidden } = await getSubGroups({
        groupId: parentId,
        setError: setErrorGroups,
      });
      setGroups((prevGroups) => updateSubGroups(prevGroups, forbidden));

      if (forbidden) {
        return false;
      }

      return true;
    };

    return [fetchChildren, { groups, isLoading: isLoadingGroups, error: errorGroups }];
  };

  const useAddUser = (): UseAddUserReturnType => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isFinised, setIsFinished] = useState<boolean>(false);

    const addUser = async (user: AddUserArgs) => {
      setIsFinished(false);
      setIsLoading(true);
      setError(null);
      const data = await baseQuery({
        url: `/users`,
        method: "POST",
        body: JSON.stringify(user),
      });
      IdmErrorHanlder({ data: data, setError: setError });
      setIsLoading(false);
      setIsFinished(true);
    };

    return [addUser, { isLoading, error, isFinised }];
  };

  const useDeleteUsers = (): UseDeleteUsersReturnType => {
    const [deletedUsers, setDeletedUsers] = useState<UserData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const deleteUsers = async (users: string[]) => {
      setIsLoading(true);
      await Promise.all(
        users.map(async (user) => {
          const data = await baseQuery({
            url: `/users/${user}`,
            method: "DELETE",
          });
          IdmErrorHanlder({ data: data, setError: setError });
          setDeletedUsers((prev) => [...prev, data]);
        })
      );
      setIsLoading(false);
    };

    return [deleteUsers, { deletedUsers, isLoading, error }];
  };

  const useGetGroupUsers = (): UseGetGroupUsersReturnType => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const getGroupUsers = async ({ groupId }: { groupId: string }) => {
      setIsLoading(true);
      const data = await baseQuery({ url: `/groups/${groupId}/members` });
      if (Array.isArray(data)) {
        setGroupUsers(data);
      }
      IdmErrorHanlder({ data: data, setError: setError });
      setIsLoading(false);
    };

    return [getGroupUsers, { users: GroupUsers, isLoading, error }];
  };

  const useRenameGroup = (): UseRenameGroupReturnType => {
    const [group, setGroup] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isFinised, setIsFinished] = useState<boolean>(false);

    const renameGroup = async ({ groupId, name }: { groupId: string; name: string }) => {
      setIsFinished(false);
      setError(null);
      setIsLoading(true);
      const data = await baseQuery({
        url: `/groups/${groupId}`,
        method: "PUT",
        body: JSON.stringify({ name: name }),
      });
      if (Array.isArray(data)) {
        setGroup(data);
        // TODO: isFinished не сработает
        // TODO: https://www.notion.so/antaresofficial/278532866fe14d8f9d6f9f94f9343054?v=1c844200728c4f70b1be7bd3dff39e46&p=d4ee72c92a0f4c9eab5b63c7b0585558&pm=s
        setIsFinished(true);
      }
      IdmErrorHanlder({ data: data, setError: setError });
      setIsLoading(false);
    };

    return [renameGroup, { group, isLoading, error, isFinised }];
  };

  const useDeleteGroups = (): UseDeleteGroupsReturnType => {
    const [groups, setGroups] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isFinised, setIsFinished] = useState<boolean>(false);

    const deleteGroups = async ({ groupsId }: { groupsId: string[] }) => {
      setIsFinished(false);
      setError(null);
      setIsLoading(true);
      await Promise.all(
        groupsId.map(async (groupId) => {
          const data = await baseQuery({
            url: `/groups/${groupId}`,
            method: "DELETE",
          });
          IdmErrorHanlder({ data: data, setError: setError });
          if (Array.isArray(data)) {
            setGroups(data);
          }
          if (!data?.message) {
            setIsFinished(true);
          }
        })
      );
      setIsLoading(false);
    };

    return [deleteGroups, { groups, isLoading, error, isFinised }];
  };

  const useAddUsersToGroup = (): UseAddUsersToGroupReturnType => {
    const [groups, setGroups] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isFinised, setIsFinished] = useState<boolean>(false);

    const addUsersToGroup = async ({ users, groupId }: { users: string[]; groupId: string }) => {
      setIsFinished(false);
      setError(null);
      setIsLoading(true);
      await Promise.all(
        users.map(async (user) => {
          const data = await baseQuery({
            url: `/users/${user}/groups/${groupId}`,
            method: "POST",
          });
          IdmErrorHanlder({ data: data, setError: setError });
          if (Array.isArray(data)) {
            setGroups(data);
          }
          if (!data?.message) {
            setIsFinished(true);
          }
        })
      );
      setIsLoading(false);
    };

    return [addUsersToGroup, { groups, isLoading, error, isFinised }];
  };

  const useExcludeUsersFromGroup = (): UseExcludeUsersFromGroupReturnType => {
    const [users, setUsers] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isFinised, setIsFinished] = useState<boolean>(false);

    const excludeUsersFromGroup = async ({ users, groupId }: { users: string[]; groupId: string }) => {
      setIsFinished(false);
      setError(null);
      setIsLoading(true);
      await Promise.all(
        users.map(async (user) => {
          const data = await baseQuery({
            url: `/users/${user}/groups/${groupId}`,
            method: "DELETE",
          });
          IdmErrorHanlder({ data: data, setError: setError });
          if (Array.isArray(data)) {
            setUsers(data);
          }
          if (!data?.message) {
            setIsFinished(true);
          }
        })
      );
      setIsLoading(false);
    };

    return [excludeUsersFromGroup, { users, isLoading, error, isFinised }];
  };

  const useAddGroup = (): UseAddGroupReturnType => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isFinised, setIsFinished] = useState<boolean>(false);

    const addGroup = async ({ groupId, group }: AddGroupArgs) => {
      setIsFinished(false);
      setIsLoading(true);
      setError(null);
      const data = await baseQuery({
        url: `/groups/${groupId}/children`,
        method: "POST",
        body: JSON.stringify(group),
      });
      IdmErrorHanlder({ data: data, setError: setError });
      setIsLoading(false);
      if (data.statusCode !== 400) {
        setIsFinished(true);
      }
    };

    return [addGroup, { isLoading, error, isFinised }];
  };

  return (
    <IdmContext.Provider
      value={{
        useGetUsers,
        useGetGroups,
        useAddUser,
        useDeleteUsers,
        useGetGroupUsers,
        useRenameGroup,
        useDeleteGroups,
        useAddUsersToGroup,
        useAddGroup,
        useExcludeUsersFromGroup,
      }}
    >
      {children}
    </IdmContext.Provider>
  );
};
