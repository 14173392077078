export const findElementById = (arr, id) => {
  if (Array.isArray(arr)) {
    for (const element of arr) {
      if (element.key === id) {
        return element;
      }
      if (element.children && element.children.length > 0) {
        const foundElement = findElementById(element.children, id);
        if (foundElement) {
          return foundElement;
        }
      }
    }
  }
  return null;
};

export const calculateChildrenLength = (item) => {
  if (!item.children) {
    return 0;
  }
  let totalLength = item.children.length;
  item.children.forEach((child) => {
    totalLength += calculateChildrenLength(child);
  });
  return totalLength;
};
