import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";

import { ExamRO } from "@/entities/exams/interfaces";
import { DateFormats } from "@/shared/types/globals";

const copyTextToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Текст успешно скопирован в буфер обмена!");
  } catch (err) {
    console.error("Ошибка:", err);
  }
};

export const titles5Columns: ColumnsType<any> = [
  {
    title: "Имя файла",
    dataIndex: "fileName",
    key: "fileName",
    render: (value) => <p>{value.slice(-10)}</p>,
  },
  {
    title: "ФИО",
    dataIndex: "title5",
    key: "title5",
    render: (value) => <p>{`${value?.lastName} ${value?.firstName} ${value?.secondName}`}</p>,
  },
  {
    title: "Дата создания",
    dataIndex: "title5",
    key: "title5",
    render: (value) => (
      <>
        <p>
          {value?.Файл.Документ._attributes.ДатИнфЗаезд} в {value?.Файл.Документ._attributes.ВрИнфЗаезд}
        </p>
      </>
    ),
  },
  {
    title: "Дата выезда",
    dataIndex: "title5",
    key: "title5",
    render: (value) => <p>{value?.Файл.Документ.ИдИнфВыезд._attributes.ДатФайлИнфВыезд}</p>,
  },
  {
    title: "ИдФайл",
    dataIndex: "title5",
    key: "title5",
    render: (value) => (
      <p style={{ cursor: "pointer" }} onClick={() => copyTextToClipboard(value?.Файл._attributes.ИдФайл)}>
        <CopyOutlined />
        Cкопировать
      </p>
    ),
  },
  {
    title: "УИД ПЛ",
    dataIndex: "УИД_ПЛ",
    key: "УИД_ПЛ",
    render: (value) => (
      <p style={{ cursor: "pointer" }} onClick={() => copyTextToClipboard(value)}>
        <CopyOutlined />
        Cкопировать
      </p>
    ),
  },
];

export const titles1Columns: ColumnsType<any> = [
  {
    title: "Имя файла",
    dataIndex: "fileName",
    key: "fileName",
    render: (value) => <p>{value.slice(-10)}</p>,
  },
  {
    title: "ФИО",
    dataIndex: "needT6",
    key: "needT6",
    render: (_, value) => <p>{`${value?.lastName} ${value?.firstName} ${value?.secondName}`}</p>,
  },
  {
    title: "Нужен 6 титул",
    dataIndex: "needT6",
    key: "needT6",
    render: (value) => <p>{value ? "Да" : "Нет"}</p>,
  },
  {
    title: "Отправлен 2 титул",
    dataIndex: "title1",
    key: "title1",
    render: (value) => <p>{value.sendedT2 ? "Да" : "Нет"}</p>,
  },
  {
    title: "Дата создания",
    dataIndex: "title1",
    key: "title1",
    render: (value) => (
      <>
        <p>
          {value.Файл.Документ._attributes.ДатИнфСоб} в {value.Файл.Документ._attributes.ВрИнфСоб}
        </p>
      </>
    ),
  },
  {
    title: "Дата окончания",
    dataIndex: "title1",
    key: "title1",
    render: (value) => <p>{value.Файл.Документ.СодИнфСоб.СрокПЛ._attributes.ДатаИспПЛ}</p>,
  },
  {
    title: "ИдФайл",
    dataIndex: "title1",
    key: "title1",
    render: (value) => (
      <p style={{ cursor: "pointer" }} onClick={() => copyTextToClipboard(value.Файл._attributes.ИдФайл)}>
        <CopyOutlined />
        Cкопировать
      </p>
    ),
  },
  {
    title: "УИД ПЛ",
    dataIndex: "УИД_ПЛ",
    key: "УИД_ПЛ",
    render: (value) => (
      <p style={{ cursor: "pointer" }} onClick={() => copyTextToClipboard(value)}>
        <CopyOutlined />
        Cкопировать
      </p>
    ),
  },
];

export const examColumns: ColumnsType<ExamRO> = [
  {
    title: "№ осмотра",
    dataIndex: "id",
    key: "id",
    render: (value) => <p>{value}</p>,
  },
  {
    title: "Дата",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value) => <p>{dayjs(value).locale("ru").format(DateFormats.UserFriendly)}</p>,
  },
  {
    title: "Ф.И.О.",
    dataIndex: "Worker",
    key: "Worker",
    render: (value) => <p>{`${value?.lastName} ${value?.firstName} ${value?.patronymic || ""}`}</p>,
  },
  {
    title: "Дата рождения",
    dataIndex: "Worker",
    key: "Worker",
    render: (value) => <p>{dayjs(value.dateOfBirth).locale("ru").format(DateFormats.UserFriendly)}</p>,
  },
  {
    title: "Организация",
    dataIndex: "Worker",
    key: "Worker",
    render: (value) => <p>{`${value.Subdivisions.map((subd) => subd.Company.shortName)} `}</p>,
  },
  // {
  //   title: "АД (мм рт. ст.)",
  //   dataIndex: "Worker",
  //   key: "Worker",
  //   render: (_, value: any) => {
  //     return (
  //       <>
  //         <p>{`Min: ${value?.sysMin}`}</p>
  //         <p>{`Max: ${value?.sysMax}`}</p>
  //         <p>{`Value: ${value?.sysValue}`}</p>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: "Пульс (уд./мин)",
  //   dataIndex: "Worker",
  //   key: "Worker",
  //   render: (_, value: any) => {
  //     return (
  //       <>
  //         <p>{`Min: ${value?.pulseMin}`}</p>
  //         <p>{`Max: ${value?.pulseMax}`}</p>
  //         <p>{`Value: ${value?.pulseValue}`}</p>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: "Температура тела (°C)",
  //   dataIndex: "Worker",
  //   key: "Worker",
  //   render: (_, value: any) => {
  //     return (
  //       <>
  //         <p>{`Min: ${value?.temperatureMin}`}</p>
  //         <p>{`Max: ${value?.temperatureMax}`}</p>
  //         <p>{`Value: ${value?.temperatureValue}`}</p>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: "Проба на алкоголь (%)",
  //   dataIndex: "Worker",
  //   key: "Worker",
  //   render: (_, value: any) => {
  //     return (
  //       <>
  //         <p>{`Min: ${value?.alcoholMax}`}</p>
  //         <p>{`Max: ${value?.alcoholMin}`}</p>
  //         <p>{`Value: ${value?.alcoholValue}`}</p>
  //       </>
  //     );
  //   },
  // },
  {
    title: "Код водителя",
    dataIndex: "Worker",
    key: "Worker",
    render: (value) => <p>{value.id}</p>,
  },
  {
    title: "Допуск",
    dataIndex: "status",
    key: "status",
    render: (value) => {
      if (value === "signed") {
        return <Tag color="green">Допущен</Tag>;
      } else {
        return <Tag color="volcano">Не допущен</Tag>;
      }
    },
  },
];
