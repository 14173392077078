import { INSPECTION_MODE } from "@/shared/constants/mode";

export type activityType = {
  mode: INSPECTION_MODE;
  shift: false;
  darkMode: false;
};

export const activityStorage = {
  getMode: () =>
    (localStorage.getItem("mode") !== "undefined" && JSON.parse(localStorage.getItem("mode") || "null")) ||
    INSPECTION_MODE.MANUALLY,
  updateMode: (value) => localStorage.setItem("mode", JSON.stringify(value)),
  getShift: () => JSON.parse(localStorage.getItem("shift") || "false"),
  updateShift: (value) => localStorage.setItem("shift", JSON.stringify(value)),
  getTheme: () => JSON.parse(localStorage.getItem("theme") || "true"),
  updateTheme: (darkMode: boolean) => localStorage.setItem("theme", JSON.stringify(!!darkMode)),
};
