import styled from "styled-components";

import { ButtonSpace, ModalUI } from "@/shared/styles/common";

export const Wrapper = styled.div`
  ${ModalUI}

  flex-direction: column;
  text-align: center;
  width: 400px;

  ${ButtonSpace}
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  h3 {
    font-size: 20px;
  }
`;

export const Content = styled.div`
  text-align: left;
  width: 100%;
`;
