import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";

import { useCreatePatientMutation, useGetPatientQuery, useUpdatePatientMutation } from "@/entities/patients/api";
import { editPatient, patientUploadSelector } from "@/entities/patients/storage";
import { FieldsRules } from "@/shared/constants";
import { DateFormats } from "@/shared/types/globals";
import { IModalProps, ModalMode } from "@/shared/types/modals";
import { EntityDialog } from "@/shared/ui";
import { StylizedItem } from "@/shared/ui/css/global";

import { HorizontalBlock, VerticalBlock } from "./styles";

const { Option } = Select;

export const PatientDialog: FC<IModalProps> = ({ id, modalMode }) => {
  const dispatch = useDispatch();

  const { data: patient, isLoading } = useGetPatientQuery(id);
  const patientUpload = useSelector(patientUploadSelector(id));

  const [form] = Form.useForm();

  const [createPatient] = useCreatePatientMutation();
  const [updatePatient] = useUpdatePatientMutation();

  const updatePatientUpload = (p) => dispatch(editPatient(p));

  const data = modalMode === ModalMode.EDIT ? (patient ? patient : patientUpload) : null;

  return (
    <EntityDialog
      data={data}
      modalMode={modalMode}
      title="пациента"
      form={form}
      isLoading={isLoading}
      create={createPatient}
      update={patient ? updatePatient : updatePatientUpload}
      initialValues={{ dateOfBirth: dayjs("2024-01-01").locale("ru") }}
    >
      <HorizontalBlock>
        <VerticalBlock>
          <StylizedItem label="Введите имя пользователя: " name="firstName" rules={FieldsRules.default}>
            <Input style={{ width: 300 }} placeholder="Имя" />
          </StylizedItem>

          <StylizedItem label="Введите фамилию пользователя: " name="lastName" rules={FieldsRules.default}>
            <Input style={{ width: 300 }} placeholder="Фамилия" />
          </StylizedItem>

          <StylizedItem label="Введите отчество пользователя: " name="middleName">
            <Input style={{ width: 300 }} placeholder="Отчество" />
          </StylizedItem>

          <StylizedItem label="Выберете гендер: " name="gender" rules={FieldsRules.default}>
            <Select style={{ width: 300 }} placeholder="Выберите гендер">
              <Option value="FEMALE">Женский</Option>
              <Option value="MALE">Мужской</Option>
            </Select>
          </StylizedItem>
        </VerticalBlock>
        <VerticalBlock>
          <StylizedItem label="Введите дату рождения: " name="dateOfBirth" rules={FieldsRules.default}>
            <DatePicker
              placeholder="Дата рождения"
              onChange={(dateOfBirth) => form.setFieldsValue({ dateOfBirth })}
              style={{ width: 300 }}
              format={DateFormats.Default}
            />
          </StylizedItem>

          <StylizedItem label="Введите страховой номер: " name="insuranceNumber" rules={FieldsRules.default}>
            <Input style={{ width: 300 }} maxLength={11} minLength={11} placeholder="Страховой номер" />
          </StylizedItem>

          <StylizedItem label="Введите ИНН: " name="itn" rules={FieldsRules.default}>
            <Input style={{ width: 300 }} minLength={12} maxLength={16} placeholder="ИНН" />
          </StylizedItem>
        </VerticalBlock>
      </HorizontalBlock>
    </EntityDialog>
  );
};
